import _ from "lodash";
import { useEffect, useState } from "react";
import SettingsList from "shared/components/shared/core/SettingsList";
import { businessUserRoles } from "shared/constants/business.constant";
import useMe from "shared/hooks/user/useMe";

export default () => {
  const me = useMe();

  const [filteredSettings, setFilteredSettings] = useState([]);

  useEffect(() => {
    setFilteredSettings(
      settings?.filter((it) => it.roles.indexOf(me?.relatedBusiness?.role) > -1)
    );
  }, [me?.relatedBusiness?.role]);

  return <SettingsList settings={filteredSettings} />;
};

const settings = [
  {
    label: "Manager le profile business",
    leftIcon: "hugeicons:information-circle",
    link: "manage/profile",
    roles: [businessUserRoles.owner, businessUserRoles.admin],
  },
  {
    label: "Modifier le menu",
    leftIcon: "hugeicons:menu-restaurant",
    link: "manage/menu/items",
    roles: [businessUserRoles.owner, businessUserRoles.admin],
  },
  {
    label: "Choisir une template",
    leftIcon: "hugeicons:layout-01",
    link: "manage/menu/template",
    roles: [businessUserRoles.owner, businessUserRoles.admin],
  },
  {
    label: "Manager l'équipe",
    leftIcon: "hugeicons:user-settings-01",
    link: "manage/team",
    roles: [businessUserRoles.owner, businessUserRoles.admin],
  },
  {
    label: "Equipe",
    leftIcon: "hugeicons:user-group",
    link: "team",
    roles: [
      businessUserRoles.cashier,
      businessUserRoles.waiter,
      businessUserRoles.chef,
    ],
  },
];
