import api from "shared/utils/api.utils";

const routes = {
  list: "/business-module/order",
  get: (id) => "/business-module/order/" + id,
  update: (id) => "/business-module/order/" + id,
  create: "/business-module/order",
};

export const getOrdersService = async (params) => {
  return await api.get(routes.list, { params: params?.query });
};

export const getOrderService = async (id) => {
  return await api.get(routes.get(id));
};

export const deleteOrderService = async (id) => {
  console.log(id);
  return await api.delete(routes.get(id));
};

export const createOrderService = async (payload) => {
  return await api.post(routes.create, payload.body, {
    params: payload?.query,
  });
};
export const patchOrderService = async (payload) => {
  return await api.patch(routes.update(payload.id), payload.body, {
    params: payload?.query,
  });
};

export const updateOrderService = async (payload) => {
  return await api.put(routes.update(payload.id), payload.body, {
    params: payload?.query,
  });
};
