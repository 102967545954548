import _ from "lodash";
import { Order } from "./Order";
import { useSelector } from "react-redux";
import BottomSheetOrderOptionsPublic from "public/components/order/BottomSheetOrderOptionsPublic";
import { useState } from "react";
import BaseButton from "../shared/base/BaseButton";
import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";

export const Orders = ({ onLoadMoreClick }) => {
  const {
    hasMore,
    loading,
    data: { data: orders },
  } = useSelector((state) => state.order.list);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState();

  const onMoreClick = (order) => {
    setCurrentOrder(order);
    setIsModalOpen(true);
  };

  const onStatusClick = (order) => {};

  return (
    <div className="w-full h-auto ">
      {_.map(orders, (data, index) => (
        <div className="w-full mb-6" key={index}>
          <Order
            data={data}
            className="w-full"
            onMoreClick={() => onMoreClick(data)}
            onStatusClick={() => onStatusClick(data)}
          />
        </div>
      ))}
      {hasMore && (
        <BaseButton.Contained
          primary
          icon="hugeicons:add-02"
          onClick={onLoadMoreClick}
          className="w-full flex flex-row items-center justify-center rounded-full py-2"
        >
          Voir plus
        </BaseButton.Contained>
      )}
      <BottomSheetOrderOptionsPublic
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        order={currentOrder}
      />
    </div>
  );
};
