import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrdersByStatusAsync, setOrderInit } from "shared/redux/order.slice";
import { paginationLimit } from "shared/constants/shared.constant";
import PullToRefresh from "react-simple-pull-to-refresh";
import OrdersBusiness from "./OrdersBusiness";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

export default ({ status }) => {
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    load({
      reset: true,
    });
  }, []);

  const load = async (params) => {
    const reset = params?.reset === true;

    const fetchPage = reset ? 0 : page;

    let query = {
      offset: fetchPage * paginationLimit,
      limit: paginationLimit,
      status: [status],
      variant: "business",
    };

    dispatch(
      getOrdersByStatusAsync({
        payload: {
          query,
        },
        options: {
          onSuccess: () => setPage((prev) => (reset ? 1 : ++prev)),
          reducerOptions: {
            reset,
          },
        },
      })
    );
  };

  return (
    <PullToRefresh onRefresh={async () => await load({ reset: true })}>
      <OrdersBusiness onLoadMoreClick={load} reducerPath={`order.${status}`} />
    </PullToRefresh>
  );
};
