export default ({ data }) => {
  const unitXPrice = `${data?.units || 1}x${data?.businessFood?.price}`;
  const total = (data?.units || 1) * data?.businessFood?.price;

  return (
    <div className="w-full h-auto text-white text-md block">
      {unitXPrice} = {total}
      <span className="text-sm"> TND</span>
    </div>
  );
};
