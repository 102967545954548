import _ from "lodash";
import BaseSkeleton from "shared/components/shared/base/BaseSkeleton";
import { TitledContentSkeleton } from "shared/components/shared/core/Container";
import HorizontalScroll from "shared/components/shared/core/HorizontalScroll";

export default ({ className }) => (
  <div className={className}>
    {_.range(4).map((__, index) => (
      <TitledContentSkeleton key={index} className="mb-8">
        <HorizontalScroll items={_.range(3)} component={MenuItemSkeleton} />
      </TitledContentSkeleton>
    ))}
  </div>
);

export const MenuItemSkeleton = () => {
  return <BaseSkeleton className="w-28 h-28 rounded-full mr-2" />;
};
