import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  HeaderBackContainer,
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import useBaseSelector from "shared/hooks/useBaseSelector";
import {
  getBusinessUsersAsync,
  setBusinessInit,
  updateBusinessUserAsync,
} from "shared/redux/business.slice";
import BusinessUserBusiness from "./shared/BusinessUserBusiness";
import BottomSheetManageTeamOptions from "./shared/BottomSheetManageTeamOptions";
import BaseButton from "shared/components/shared/base/BaseButton";
import { useNavigate } from "react-router-dom";
import BottomSheetBusinessUserRolesOptions from "./shared/BottomSheetBusinessUserRolesOptions";
import { businessUserRoles } from "shared/constants/business.constant";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import BusinessAdminPortal from "./BusinessAdminPortal";

const BusinessManageTeamContent = () => {
  const { data: businessUsers } = useBaseSelector("business.users");

  const [isUserOptionsOpen, setIsUserOptionsOpen] = useState(false);
  const [isUserRoleOptionsOpen, setIsUserRoleOptionsOpen] = useState(false);

  const [currentBusinessUser, setCurrentBusinessUser] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessUsersAsync());
    return () => dispatch(setBusinessInit("users"));
  }, []);

  const onAddClick = () => {
    navigate("add");
  };

  const onRoleChange = (role) => {
    dispatch(
      updateBusinessUserAsync({
        payload: {
          id: currentBusinessUser.id,
          body: {
            userId: currentBusinessUser?.user?.id,
            role: role?.value,
          },
        },
        options: {
          onSuccess: () => {
            dispatch(getBusinessUsersAsync());
          },
        },
      })
    );
  };

  return (
    <>
      <TitledContent
        title="Liste des utilisateurs de votre restaurant/café"
        titleClassName="text-center mb-8"
      >
        {_.map(businessUsers, (businessUser, index) => (
          <BusinessUserBusiness
            data={businessUser}
            key={index}
            edit={businessUser?.role != businessUserRoles.owner}
            onMoreClick={(item) => {
              setCurrentBusinessUser(item);
              setIsUserOptionsOpen(true);
            }}
            onRoleClick={(item) => {
              setCurrentBusinessUser(item);
              setIsUserRoleOptionsOpen(true);
            }}
          />
        ))}
        <BaseButton.Contained
          primary
          icon="hugeicons:add-circle"
          onClick={onAddClick}
          className="w-full h-auto flex flex-row items-center justify-center rounded-lg mt-4"
        >
          Ajouter
        </BaseButton.Contained>
      </TitledContent>
      <BottomSheetManageTeamOptions
        data={currentBusinessUser}
        isOpen={isUserOptionsOpen}
        onClose={() => setIsUserOptionsOpen(false)}
        title="Selectionner une action à faire"
      />
      <BottomSheetBusinessUserRolesOptions
        isOpen={isUserRoleOptionsOpen}
        onClose={() => setIsUserRoleOptionsOpen(false)}
        onItemClick={onRoleChange}
        title="Changer le role de l'utilisateur"
      />
    </>
  );
};

const BusinessManageTeamContentMobile = () => (
  <HeaderBackContainer>
    <BusinessManageTeamContent />
  </HeaderBackContainer>
);

const BusinessManageTeamContentTabet = () => (
  <LayoutContentContainer>
    <BusinessManageTeamContent />
  </LayoutContentContainer>
);

export default WithResponsive(
  () => (
    <BusinessAdminPortal>
      <BusinessManageTeamContentMobile />
    </BusinessAdminPortal>
  ),
  () => (
    <BusinessAdminPortal>
      <RightContentTabletContainer
        breadCrumbItems={[
          tabsBreadcrumbs.business,
          tabsBreadcrumbs.businessManageTeam,
        ]}
      >
        <BusinessManageTeamContentTabet />
      </RightContentTabletContainer>
    </BusinessAdminPortal>
  )
);
