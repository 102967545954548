import { tailwindTheme } from "shared/constants/theme.constant";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import classNames from "classnames";

export default ({
  title,
  backLink,
  content,
  marginBottom = false,
  sticky,
  className,
}) => {
  const navigate = useNavigate();

  return (
    <div
      id="_headerBack"
      className={classNames(
        "w-full h-[60px] flex flex-row items-center justify-center  border-b-[1px] border-custom-border-main relative _glass z-20 px-2",
        {
          "mb-4": marginBottom && !sticky,
          "sticky top-0": sticky,
        },
        className
      )}
    >
      <div className="w-auto h-full flex flex-col items-center justify-center absolute left-4">
        <Icon
          icon="hugeicons:link-backward"
          fontSize={24}
          color={tailwindTheme.colors.custom.icon.main}
          onClick={() => navigate(backLink ?? -1)}
        />
      </div>
      <div className="w-full font-semibold text-white text-lg text-center">
        {title ? title : content ?? <></>}
      </div>
    </div>
  );
};
