import { useEffect, useState } from "react";
import BaseSkeleton from "./BaseSkeleton";
import classNames from "classnames";
import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({
  className,
  src: imageSrc,
  onClick,
  defaultIcon = "hugeicons:image-01",
  ...props
}) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(false);
  const [src, setSrc] = useState();

  useEffect(() => {
    load();
  }, [imageSrc]);

  const load = async () => {
    try {
      setLoading(true);
      const response = await fetch(imageSrc, {
        cache: "force-cache",
      });
      if (!response.ok) {
        throw Error();
      }
      const data = await response.blob();
      setSrc(URL.createObjectURL(data));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading === null || loading) {
    return (
      <BaseSkeleton
        className={className}
        style={{ pointerEvents: "all" }}
        onClick={onClick}
      />
    );
  }

  if (error) {
    return (
      <div
        className={classNames(
          "flex flex-col items-center justify-center _glassResponsivePerf",
          className
        )}
        onClick={onClick}
      >
        <Icon
          icon={defaultIcon}
          fontSize={38}
          color={tailwindTheme.colors.custom.icon.main}
        />
      </div>
    );
  }

  return (
    <img
      className={className}
      style={{ pointerEvents: "all" }}
      src={src}
      onClick={onClick}
      {...props}
    />
  );
};
