import _ from "lodash";
import UserSetting from "./shared/UserSetting";
import UserSettingDisconnect from "./shared/UserSettingDisconnect";
import SettingsList from "../shared/core/SettingsList";

export default () => {
  return (
    <div>
      <SettingsList settings={settings} />
      <UserSettingDisconnect />
    </div>
  );
};

const settings = [
  // {
  //   label: "Modifier mon profil",
  //   link: "/me/edit",
  // },
];
