import { tailwindTheme } from "shared/constants/theme.constant";
import MenuPrice from "./MenuPrice";
import _ from "lodash";
import { Icon } from "@iconify/react";
import BaseImage from "shared/components/shared/base/BaseImage";

export default ({ data, onAddClick, onMinusClick, units }) => {
  return (
    <div className="w-36 h-[180px] relative cursor-pointer">
      <div className="flex flex-row justify-center w-full absolute top-0 h-28 z-10">
        <div className="w-28 h-28 overflow-hidden relative drop-shadow-2xl rounded-full">
          <BaseImage
            src={data?.image}
            alt={data?.food?.name}
            className="w-28 h-28 rounded-full mb-3"
          />
        </div>
        {units > 0 && (
          <div className="flex flex-col items-center justify-center bg-primary-main rounded-full w-8 h-8 -top-4 left-2 absolute">
            <div className="text-white text-sm font-medium flex flex row items-end justify-start">
              <div className="text-md">X</div>
              <div className="text-md">{units}</div>
            </div>
          </div>
        )}
        <MenuPrice data={data?.price} className="-top-4 right-2" />
      </div>
      <div className="w-full h-[140px] absolute top-[55px]">
        <div className="w-full h-full flex flex-col justify-start items-center rounded-2xl _glassResponsivePerf px-2 pb-4 pt-16 relative">
          <div
            className="w-auto h-auto p-2 absolute bottom-0 right-0 rounded-br-lg rounded-tl-lg bg-primary-main"
            onClick={() => onAddClick(data)}
          >
            <Icon icon="hugeicons:add-02" fontSize={12} color="white" />
          </div>
          {units > 0 && (
            <div
              className="w-auto h-auto p-2 absolute bottom-0 left-0 rounded-bl-lg rounded-tr-lg bg-secondary-main"
              onClick={() => onMinusClick(data)}
            >
              <Icon icon="hugeicons:minus-sign" fontSize={12} color="black" />
            </div>
          )}
          <div className="text-white text-center">{data?.food?.name}</div>
        </div>
      </div>
    </div>
  );
};
