import {
  HeaderBackContainer,
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import BusinessUserBusiness from "./shared/BusinessUserBusiness";
import { useEffect } from "react";
import useBaseSelector from "shared/hooks/useBaseSelector";
import {
  getBusinessUsersAsync,
  setBusinessInit,
} from "shared/redux/business.slice";
import { useDispatch } from "react-redux";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import _ from "lodash";

const BusinessTeam = () => {
  const { data: businessUsers } = useBaseSelector("business.users");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessUsersAsync());
    return () => dispatch(setBusinessInit("users"));
  }, []);

  return (
    <TitledContent
      title="Liste des utilisateurs de votre restaurant/café"
      titleClassName="text-center mb-8"
    >
      {_.map(businessUsers, (businessUser, index) => (
        <BusinessUserBusiness data={businessUser} key={index} edit={false} />
      ))}
    </TitledContent>
  );
};

export default WithResponsive(
  () => (
    <HeaderBackContainer>
      <BusinessTeam />
    </HeaderBackContainer>
  ),
  () => (
    <RightContentTabletContainer
      breadCrumbItems={[tabsBreadcrumbs.business, tabsBreadcrumbs.businessTeam]}
    >
      <BusinessTeam />
    </RightContentTabletContainer>
  )
);
