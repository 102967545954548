import { useContext, useEffect } from "react";
import useBaseSelector from "shared/hooks/useBaseSelector";
import { AuthModalContext } from "context";

export default ({ children }) => {
  const { data: user } = useBaseSelector("user.me");

  if (user) {
    return <>{children}</>;
  }

  return <Empty />;
};

const Empty = () => {
  const [__, setIsOpen] = useContext(AuthModalContext);
  useEffect(() => {
    setIsOpen(true);
  }, []);

  return <></>;
};
