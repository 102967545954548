import classnames from "classnames";
import _ from "lodash";
import { forwardRef, useRef } from "react";

export default forwardRef(({ items, component: Component, className }, ref) => {
  return (
    <div
      ref={ref}
      className={classnames(
        "w-full flex flex-row items-center justify-start overflow-x-scroll _noScrollBar",
        className
      )}
    >
      {_.map(items, (item, index) => (
        <div className="w-auto h-auto mr-2" key={index}>
          <Component data={item} />
        </div>
      ))}
    </div>
  );
});

export const HorizontalScrollWithChildren = forwardRef(
  ({ children, className }, ref) => {
    return (
      <div
        ref={ref}
        className={classnames(
          "w-full flex flex-row items-center justify-start overflow-x-scroll _noScrollBar",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
