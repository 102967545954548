import _ from "lodash";

export const deleteOrderSuccessHandler = (state, action) => {
  let list = state.list.data.data;
  const id = action.meta.arg;

  const index = list?.findIndex((it) => it.id === id);
  if (index > -1) {
    list?.splice(index, 1);
  }
};

export const syncOrdersStatusChangeHandler = (state, action) => {
  const data = action.payload?.data;

  syncOrderOnGrid(state, data);

  syncOrderOnList(state, data);
};

const syncOrderOnGrid = (state, data) => {
  const nodes = ["waitingAcceptance", "waiting", "inProgress", "ready", "done"];

  _.chain(nodes)
    .forEach((node) =>
      _.chain(state).get(node).get("data.data").remove({ id: data?.id }).value()
    )
    .value();

  const newNode = _.find(nodes, (node) => data.status === node);

  if (newNode) {
    state[newNode].data.data?.push(data);
    _.sortBy(state[newNode].data.data, (it) => new Date(it.createdAt), "asc");
  }
};

const syncOrderOnList = (state, data) => {
  _.chain(state.list.data.data).find({ id: data?.id }).assign(data).value();
};
