import classnames from "classnames";
import BaseImage from "shared/components/shared/base/BaseImage";

export default ({ data, className}) => {
  return (
    <div className={classnames("w-[80px] h-[80px] relative", className)}>
      <BaseImage
        src={data.image}
        className="min-w-[80px] min-h-[80px] rounded-full"
        alt={data.name}
      />
      {data?.units > 0 && (
        <div className="flex flex-col items-center justify-center bg-primary-main rounded-full w-8 h-8 top-0 -right-1 absolute">
          <div className="text-white font-medium flex flex row items-end justify-start">
            <div className="text-md">X</div>
            <div className="text-md">{data?.units}</div>
          </div>
        </div>
      )}
    </div>
  );
};
