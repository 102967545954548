import { createSlice } from "@reduxjs/toolkit";
import reduxUtils from "./utils/redux.utils";
import { createNodeThunkMiddleware } from "./utils/thunk.utils";

const managementActions = reduxUtils.createManagementActions("/shared");

const nodeThunkMiddleware = createNodeThunkMiddleware(managementActions);

const initialState = {
  drawer: {
    isOpen: false,
  },
  tabs: {
    showSearch: false
  },
  form: {
    loading: false,
    error: false,
    data: undefined,
  },
};

const slice = createSlice({
  name: "shared",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reduxUtils.tookit.buildManagementCases(
      builder,
      managementActions,
      initialState
    );
  },
});

export const setSharedNodeData = managementActions.setNodeData;
export const setSharedNodeFieldData = managementActions.setNodeFieldData;
export const mergeSharedNodeData = managementActions.mergeNodeData;
export const setSharedInit = managementActions.setInit;

export const sharedReducer = slice.reducer;
