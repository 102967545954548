import {
  HeaderBackContainer,
  LayoutContentContainer,
} from "shared/components/shared/core/Container";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAsync } from "shared/redux/order.slice";
import BottomSheetOrderOptionsPublic from "public/components/order/BottomSheetOrderOptionsPublic";
import OrderPageHeader from "shared/components/order/shared/OrderPageHeader";
import { PageNotFound } from "shared/components/shared/core/Error";
import OrderPageDetails from "shared/components/order/OrderPageDetails";

export default () => {
  const { data, loading, error } = useSelector((state) => state.order.item);
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getOrderAsync(id));
    }
  }, [id]);

  if (loading) {
    return <></>;
  }

  if (error) {
    return <PageNotFound />;
  }

  return (
    <HeaderBackContainer
      headerBackProps={{
        marginBottom: false,
      }}
      layoutContainerProps={{
        horizontalPadding: false,
      }}
    >
      <OrderPageHeader
        data={data?.business}
        onMoreClick={() => setIsOpen(true)}
      />
      <OrderPageDetails data={data} />
      <BottomSheetOrderOptionsPublic
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        order={data}
        options={{
          delete: {
            onSuccess: () => {
              navigate("/order");
            },
          },
        }}
      />
    </HeaderBackContainer>
  );
};
