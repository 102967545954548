import {
  HeaderBackContainer,
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import _ from "lodash";
import { HorizontalScrollWithChildren } from "shared/components/shared/core/HorizontalScroll";
import BusinessMenuItemEdit from "./BusinessMenuItemEdit";
import { BaseConfirmBottomSheet } from "shared/components/shared/base/BaseBottomSheet";
import { useContext, useEffect, useState } from "react";
import BaseInput from "shared/components/shared/base/BaseInput";
import { useDispatch } from "react-redux";
import {
  createBusinessFoodAsync,
  deleteBusinessFoodAsync,
  getBusinessAsync,
  updateBusinessFoodAsync,
} from "shared/redux/business.slice";
import { tailwindTheme } from "shared/constants/theme.constant";
import BusinessMenuItemAdd from "./BusinessMenuItemAdd";
import { getFoodListAsync, setFoodInit } from "shared/redux/food.slice";
import useBaseSelector from "shared/hooks/useBaseSelector";
import MenuLoader from "shared/components/order/menu/MenuLoader";
import BusinessRolePortal from "business/components/shared/guards/BusinessRolePortal";
import {
  RightContentTabletContainer,
  WithResponsiveRightContentContainer,
} from "business/components/shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import { businessUserRoles } from "shared/constants/business.constant";
import { Icon } from "@iconify/react";
import LoaderPortal from "shared/components/shared/utils/LoaderPortal";
import ImageInput from "shared/components/shared/core/ImageInput";
import BaseTextArea from "shared/components/shared/base/BaseTextArea";
import BaseImage from "shared/components/shared/base/BaseImage";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import ImagesInput from "shared/components/shared/core/ImagesInput";

const BusinessManageMenu = () => {
  const { data: business } = useBaseSelector("business.item");

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);

  const [menuItem, setMenuItem] = useState();

  const dispatch = useDispatch();

  const onEditClick = (data) => {
    setMenuItem(data);
    setIsEditOpen(true);
  };

  const onEditModalClose = () => {
    setMenuItem();
    setIsEditOpen(false);
  };

  const onEditConfirm = (data) => {
    dispatch(
      updateBusinessFoodAsync({
        payload: {
          id: menuItem.id,
          body: {
            businessId: business?.id,
            image: data?.image ?? null,
            imagesList: data?.imagesList ?? null,
            info: data?.info ?? null,
            price: parseFloat(data?.price),
          },
        },
        options: {
          finally: () => {
            setIsEditOpen(false);
            setMenuItem();
          },
        },
      })
    );
  };

  const onDeleteClick = (data) => {
    dispatch(deleteBusinessFoodAsync(data?.id));
  };

  const onAddFoodConfirm = (data) => {
    dispatch(
      createBusinessFoodAsync({
        payload: {
          bulk: _.map(data, (it) => ({
            foodId: it.id,
            businessId: business.id,
          })),
        },
        options: {
          onSuccess: () => {
            dispatch(getBusinessAsync(business?.id));
          },
        },
      })
    );
  };

  return (
    <LoaderPortal
      loader={
        <LayoutContentContainer contentClassName="pt-8">
          <MenuLoader />
        </LayoutContentContainer>
      }
      reducerPath="business.item"
    >
      <div className="relative">
        <MenuEdit
          data={business.menu}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
        <div
          className="fixed bottom-[20px] right-[20px] cursor-pointer bg-primary-main p-4 rounded-full z-10"
          onClick={() => setIsAddOpen(true)}
        >
          <Icon
            icon="hugeicons:add-02"
            color={tailwindTheme.colors.custom.icon.main}
            fontSize={24}
          />
        </div>
        <BaseConfirmBottomSheet
          formInit={menuItem}
          isOpen={isEditOpen}
          onClose={onEditModalClose}
          component={BusinessFoodEditionModalContent}
          onConfirm={onEditConfirm}
        />
        <BaseConfirmBottomSheet
          formInit={[]}
          isOpen={isAddOpen}
          onClose={() => setIsAddOpen(false)}
          component={AddMenuItemModalContent}
          onConfirm={onAddFoodConfirm}
          containerClassName="lg:max-w-[calc(100%-150px)]"
        />
      </div>
    </LoaderPortal>
  );
};

const MenuEdit = ({ data, onEditClick, onDeleteClick }) => {
  return (
    <div className="w-full h-auto">
      {_.map(data, (menuItem, index) => (
        <TitledContent title={menuItem?.name} key={index}>
          <HorizontalScrollWithChildren className="h-[240px]">
            {_.map(menuItem.children, (item, index) => (
              <div className="w-auto h-auto mr-2" key={index}>
                <BusinessMenuItemEdit
                  data={item}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                />
              </div>
            ))}
          </HorizontalScrollWithChildren>
        </TitledContent>
      ))}
    </div>
  );
};

const BusinessFoodEditionModalContent = ({ form, setForm }) => {
  const onChange = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="w-full pb-[100px]">
      <TitledContent title="Image" className="w-full mb-4">
        <ImageInput
          className="w-[180px] h-[180px] rounded-full self-center"
          imageClassName="rounded-full"
          onFileChange={(file) => onChange("image", file)}
          value={form?.image}
        />
      </TitledContent>
      <TitledContent title="Info" className="mb-4">
        <BaseTextArea
          value={form?.info}
          onChange={(e) => onChange("info", e.target.value)}
          type="text"
          className="rounded-xl"
        />
      </TitledContent>
      <TitledContent title="Prix" className="mb-4">
        <BaseInput
          value={form?.price}
          onChange={(e) => onChange("price", e.target.value)}
          type="number"
          className="rounded-xl"
        />
      </TitledContent>
      <TitledContent title="Images" className="w-full">
        <ImagesInput
          imageClassName="min-w-[200px] h-[300px]"
          onChange={(value) => onChange("imagesList", value)}
          value={form?.imagesList}
        />
      </TitledContent>
    </div>
  );
};

const AddMenuItemModalContent = ({ form, setForm }) => {
  const { data, loading } = useBaseSelector("food.list");

  const [suggestions, setSuggestions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFoodListAsync());
    return () => dispatch(setFoodInit("list"));
  }, []);

  useEffect(() => {
    if (data) {
      setSuggestions(data);
    }
  }, [data]);

  useEffect(() => {
    setSuggestions(
      _.filter(suggestions, (it) => !_.find(form, (el) => el.id == it.id))
    );
  }, [form]);

  const findIndex = (item) => _.findIndex(form, (el) => el.id === item.id);

  const onAddClick = (item) => {
    if (findIndex(item) === -1) {
      setForm((prev) => {
        const update = _.clone(prev);
        update.push(item);
        return update;
      });
    }
  };

  const onDeleteClick = (item) => {
    const index = findIndex(item);

    if (index > -1) {
      setForm((prev) => {
        const update = _.clone(prev);
        update.splice(index, 1);
        return update;
      });
    }
  };

  return (
    <div className="w-full h-auto pb-[100px]">
      {form?.length > 0 && (
        <TitledContent title="Sélectionnées">
          <HorizontalScrollWithChildren className="h-[240px]">
            {_.map(form, (foodItem, index) => (
              <div className="w-auto h-auto mr-2" key={index}>
                <BusinessMenuItemAdd
                  key={index}
                  data={foodItem}
                  isAddable={false}
                  onDeleteClick={onDeleteClick}
                />
              </div>
            ))}
          </HorizontalScrollWithChildren>
        </TitledContent>
      )}
      <TitledContent title="Suggestions">
        <div className="grid grid-cols-2 md:grid-cols-7 gap-x-4 gap-y-8">
          {_.map(suggestions, (item, index) => (
            <BusinessMenuItemAdd
              key={index}
              data={item}
              isAddable={findIndex(item) === -1}
              onAddClick={onAddClick}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </div>
      </TitledContent>
    </div>
  );
};

export default WithResponsive(
  () => (
    <BusinessRolePortal
      roles={[businessUserRoles.owner, businessUserRoles.admin]}
    >
      <HeaderBackContainer>
        <BusinessManageMenu />
      </HeaderBackContainer>
    </BusinessRolePortal>
  ),
  () => (
    <BusinessRolePortal
      roles={[businessUserRoles.owner, businessUserRoles.admin]}
    >
      <RightContentTabletContainer
        firstContentMaxWidth={false}
        breadCrumbItems={[
          tabsBreadcrumbs.business,
          tabsBreadcrumbs.businessManageMenu,
        ]}
      >
        <LayoutContentContainer>
          <BusinessManageMenu />
        </LayoutContentContainer>
      </RightContentTabletContainer>
    </BusinessRolePortal>
  )
);
