import classnames from "classnames";
import { BusinessCartSelection } from "shared/components/order/cart/BusinessCartSelection";
import _ from "lodash";
import { useState } from "react";
import BusinessCartSelectionModalContent from "shared/components/order/cart/BusinessCartSelectionModalContent";
import BaseButton from "shared/components/shared/base/BaseButton";
import BusinessTableSelectionModalContent from "shared/components/order/cart/BusinessTableSelectionModalContent";
import BusinessCartCategoryMenuEdit from "shared/components/order/cart/BusinessCartCategoryMenuEdit";
import BaseBottomSheet from "shared/components/shared/base/BaseBottomSheet";
import BaseDivider from "shared/components/shared/base/BaseDivider";
import { tailwindTheme } from "shared/constants/theme.constant";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import useBaseSelector from "shared/hooks/useBaseSelector";

const BusinessCartMobile = ({
  data,
  onItemAddClick,
  onItemMinusClick,
  onItemExpandClick,
  onItemDeleteClick,
  onConfirmIngredients,
  onConfirmSupplements,
  onConfirmTable,
  onSubmit,
  variant,
  isAddButtonVisible = false,
  className,
}) => {
  const loading = useBaseSelector("order.form.loading");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  const onItemChangeIngredientsClick = (index) => {
    const item = data?.selections[index];
    setModalContent(
      <BusinessCartSelectionModalContent
        title={`Selectionner les ingrédients a exclure de ${item?.food?.name}`}
        data={item?.food?.ingredients}
        defaultSelected={item?.food?.excludeIngredients ?? []}
        selectedRightIcon="hugeicons:remove-circle"
        selectedRightIconSize={24}
        onConfirm={(selected) => {
          setIsModalOpen(false);
          onConfirmIngredients(index, selected);
        }}
      />
    );
    setIsModalOpen(true);
  };

  const onItemChangeSupplementsClick = (index) => {
    const item = data?.selections[index];
    setModalContent(
      <BusinessCartSelectionModalContent
        title={`Selectionner les suppléments a ajouter à ${item?.food?.name}`}
        data={item?.food?.supplements}
        defaultSelected={item?.food?.addSupplements ?? []}
        selectedRightIcon="hugeicons:add-circle"
        selectedRightIconSize={24}
        onConfirm={(selected) => {
          setIsModalOpen(false);
          onConfirmSupplements(index, selected);
        }}
      />
    );
    setIsModalOpen(true);
  };

  const onTableSelectClick = () => {
    setModalContent(
      <BusinessTableSelectionModalContent
        title={`Selectionner votre table`}
        business={data?.business}
        defaultSelected={data?.businessTable}
        onConfirm={(selected) => {
          setIsModalOpen(false);
          onConfirmTable(selected);
        }}
      />
    );
    setIsModalOpen(true);
  };

  const onAddSelectionClick = () => {
    setModalContent(
      <BusinessCartCategoryMenuEdit
        business={data?.business}
        onDone={() => setIsModalOpen(false)}
        variant={variant}
      />
    );
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setModalContent();
  };

  return (
    <div className={classnames("w-full h-auto pb-12 z-0")}>
      <div
        className={classnames(
          "w-full h-auto _glassResponsivePerf py-4 px-3 rounded-xl mb-3",
          className
        )}
      >
        {_.map(data?.selections, (it, index) => (
          <BusinessCartSelection
            data={it}
            key={index}
            onAddClick={() => onItemAddClick(index)}
            onMinusClick={() => onItemMinusClick(index)}
            onExpandClick={() => onItemExpandClick(index)}
            onDeleteClick={() => onItemDeleteClick(index)}
            onChangeIngredientsClick={() => onItemChangeIngredientsClick(index)}
            onChangeSupplementsClick={() => onItemChangeSupplementsClick(index)}
          />
        ))}

        {isAddButtonVisible && (
          <BaseButton.Contained
            secondary
            icon="hugeicons:pencil-edit-02"
            iconSize={24}
            onClick={onAddSelectionClick}
            className="w-full h-auto flex flex-row items-center justify-center rounded-full py-2 mb-2"
          >
            Ajouter/Modifier
          </BaseButton.Contained>
        )}

        <BaseButton.Contained
          secondary
          icon="hugeicons:table-round"
          iconSize={24}
          onClick={onTableSelectClick}
          className="w-full h-auto flex flex-row items-center justify-center rounded-full py-2 mb-4"
        >
          Selectionner la table
        </BaseButton.Contained>

        <BaseDivider className="mb-4" />
        {data?.businessTable && (
          <div className="w-full flex flex-row items-center justify-between  mb-2">
            <div className="text-lg">Table</div>
            <div className="text-lg">{data?.businessTable?.table}</div>
          </div>
        )}
        <div className="w-full flex flex-row items-center justify-between">
          <div className="text-lg">Total</div>
          <div className="text-lg block">
            {_.chain(data?.selections)
              .map((it) => it.price * it.units)
              .sum()
              .value()}
            <span className="text-md ml-1">TND</span>
          </div>
        </div>
      </div>
      <div className="w-full h-auto bg-background-main px-2 py-2">
        <BaseButton.Contained
          primary
          icon="hugeicons:sent"
          iconSize={24}
          onClick={onSubmit}
          loading={loading}
          className="w-full h-auto flex flex-row items-center justify-center rounded-full py-2 mb-4"
        >
          Envoyer
        </BaseButton.Contained>
      </div>
      <BaseBottomSheet
        isOpen={isModalOpen}
        onClose={onModalClose}
        content={modalContent}
      />
    </div>
  );
};

const BusinessCartTablet = ({
  data,
  onItemAddClick,
  onItemMinusClick,
  onItemExpandClick,
  onItemDeleteClick,
  onConfirmIngredients,
  onConfirmSupplements,
  onConfirmTable,
  onSubmit,
  variant,
  isAddButtonVisible = false,
  className,
}) => {
  const loading = useBaseSelector("order.form.loading");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  const [bottomSheetContainerClassName, setBottomSheetContainerClassName] =
    useState();

  const onItemChangeIngredientsClick = (index) => {
    const item = data?.selections[index];
    setModalContent(
      <BusinessCartSelectionModalContent
        title={`Selectionner les ingrédients a exclure de ${item?.food?.name}`}
        data={item?.food?.ingredients}
        defaultSelected={item?.food?.excludeIngredients ?? []}
        selectedRightIcon="hugeicons:remove-circle"
        selectedRightIconSize={24}
        onConfirm={(selected) => {
          onModalClose();
          onConfirmIngredients(index, selected);
        }}
      />
    );
    setIsModalOpen(true);
  };

  const onItemChangeSupplementsClick = (index) => {
    const item = data?.selections[index];
    setModalContent(
      <BusinessCartSelectionModalContent
        title={`Selectionner les suppléments a ajouter à ${item?.food?.name}`}
        data={item?.food?.supplements}
        defaultSelected={item?.food?.addSupplements ?? []}
        selectedRightIcon="hugeicons:add-circle"
        selectedRightIconSize={24}
        onConfirm={(selected) => {
          onModalClose();
          onConfirmSupplements(index, selected);
        }}
      />
    );
    setIsModalOpen(true);
  };

  const onAddSelectionClick = () => {
    setBottomSheetContainerClassName(
      "xl:max-w-[calc(100%-480px)] lg:max-w-[calc(100%-440px)]"
    );
    setModalContent(
      <BusinessCartCategoryMenuEdit
        business={data?.business}
        onDone={() => setIsModalOpen(false)}
        variant={variant}
      />
    );
    setIsModalOpen(true);
  };

  const onTableSelectClick = () => {
    setBottomSheetContainerClassName(
      "xl:max-w-[calc(100%-480px)] lg:max-w-[calc(100%-440px)]"
    );
    setModalContent(
      <BusinessTableSelectionModalContent
        title={`Selectionner votre table`}
        business={data?.business}
        defaultSelected={data?.businessTable}
        onConfirm={(selected) => {
          onModalClose();
          onConfirmTable(selected);
        }}
      />
    );
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setModalContent();
    setBottomSheetContainerClassName();
  };

  if (data?.selections?.length === 0) {
    return (
      <div className={classnames("w-full h-full pb-[60px]", className)}>
        <div className="w-full h-full flex flex-col items-center justify-center rounded-xl">
          <span className="text-2xl">Panier vide</span>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames("w-full h-auto pb-12 z-0")}>
      <div
        className={classnames(
          "w-full h-auto _glassResponsivePerf py-4 px-3 rounded-xl mb-3",
          className
        )}
      >
        {_.map(data?.selections, (it, index) => (
          <BusinessCartSelection
            data={it}
            key={index}
            onAddClick={() => onItemAddClick(index)}
            onMinusClick={() => onItemMinusClick(index)}
            onExpandClick={() => onItemExpandClick(index)}
            onDeleteClick={() => onItemDeleteClick(index)}
            onChangeIngredientsClick={() => onItemChangeIngredientsClick(index)}
            onChangeSupplementsClick={() => onItemChangeSupplementsClick(index)}
          />
        ))}

        {isAddButtonVisible && (
          <BaseButton.Contained
            secondary
            icon="hugeicons:pencil-edit-02"
            iconSize={24}
            onClick={onAddSelectionClick}
            className="w-full h-auto flex flex-row items-center justify-center rounded-full py-2 mb-2"
          >
            Ajouter/Modifier
          </BaseButton.Contained>
        )}

        <BaseButton.Contained
          secondary
          icon="hugeicons:table-round"
          iconSize={24}
          onClick={onTableSelectClick}
          className="w-full h-auto flex flex-row items-center justify-center rounded-full py-2 mb-4"
        >
          Selectionner la table
        </BaseButton.Contained>

        <BaseDivider className="mb-4" />
        {data?.businessTable && (
          <div className="w-full flex flex-row items-center justify-between  mb-2">
            <div className="text-lg">Table</div>
            <div className="text-lg">{data?.businessTable?.table}</div>
          </div>
        )}
        <div className="w-full flex flex-row items-center justify-between">
          <div className="text-lg">Total</div>
          <div className="text-lg block">
            {_.chain(data?.selections)
              .map((it) => it.price * it.units)
              .sum()
              .value()}
            <span className="text-md ml-1">TND</span>
          </div>
        </div>
      </div>
      <div className="w-full h-auto bg-transparent sticky bottom-6 px-2 py-2">
        <BaseButton.Contained
          primary
          icon="hugeicons:sent"
          iconSize={24}
          onClick={onSubmit}
          loading={loading}
          className="w-full h-auto flex flex-row items-center justify-center rounded-full py-2 mb-4"
        >
          Envoyer
        </BaseButton.Contained>
      </div>
      <BaseBottomSheet
        isOpen={isModalOpen}
        onClose={onModalClose}
        content={modalContent}
        containerClassName={bottomSheetContainerClassName}
      />
    </div>
  );
};

export default WithResponsive(BusinessCartMobile, BusinessCartTablet);
