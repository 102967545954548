import { useEffect } from "react";
import { sendMessageToNative } from "shared/utils/app.utils";

export default (handler) => {
  useEffect(() => {
    document.addEventListener("message", handleEvent);
    return () => document.removeEventListener("message", handleEvent);
  }, []);

  const handleEvent = (nativeEvent) => {
    try {
      const message = nativeEvent.data;

      sendMessageToNative({
        event: "received.message",
        data: message,
      });

      const messageData = JSON.parse(message);

      handler(messageData);
    } catch (error) {
      sendMessageToNative({
        event: "ERROR",
        data: error.toString(),
      });
    }
  };
};
