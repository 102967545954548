export const orderStatusList = [
  {
    icon: "hugeicons:more-or-less-circle",
    label: "En attente d'accéptation",
    value: "waitingAcceptance",
    toNextLabel: "Accepter",
  },
  {
    icon: "hugeicons:circle",
    label: "Accepté et en attente",
    value: "waiting",
    toNextLabel: "Commencer",
  },
  {
    icon: "hugeicons:circle-arrow-up-down",
    label: "En cours",
    value: "inProgress",
    toNextLabel: "Prét",
  },
  {
    icon: "hugeicons:checkmark-circle-03",
    label: "Prét",
    value: "ready",
    toNextLabel: "Livrer",
  },
  {
    icon: "hugeicons:checkmark-badge-03",
    label: "Livré | Sur table",
    value: "delivered",
    toNextLabel: "Términer",
  },
  {
    icon: "hugeicons:shopping-basket-done-01",
    label: "Términé",
    value: "done",
  },
];

export const orderStatusValueMap = {
  waitingAcceptance: "waitingAcceptance",
  waiting: "waiting",
  inProgress: "inProgress",
  ready: "ready",
  delivered: "delivered",
  done: "done",
};
