import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDeafaultMiddleware) =>
    getDeafaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
