import { combineReducers } from "redux";
import { businessReducer } from "./business.slice";
import { userReducer } from "./user.slice";
import { orderReducer } from "./order.slice";
import { sharedReducer } from "./shared.slice";
import { notificationReducer } from "./notification.slice";
import { foodReducer } from "./food.slice";

export default combineReducers({
  user: userReducer,
  business: businessReducer,
  order: orderReducer,
  food: foodReducer,
  notification: notificationReducer,
  shared: sharedReducer,
});
