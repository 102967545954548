import { useState } from "react";
import BaseInput from "../base/BaseInput";

export default ({ ...props }) => {
  const [view, setView] = useState(false);

  return (
    <BaseInput
      {...props}
      rightIcon={!view ? "hugeicons:view" : "hugeicons:view-off"}
      type={!view ? "password" : "text"}
      onRightIconClick={() => setView((prev) => !prev)}
    />
  );
};
