import classnames from "classnames";

export default ({ data, className }) => {
  return (
    <div>
      {data?.ticketNumber && (
        <div className={classnames("text-md", className)}>
          Ticket #{data?.ticketNumber}
        </div>
      )}
    </div>
  );
};
