import {
  HeaderBackContainer,
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import BottomSheetBusinessUserRolesOptions from "./shared/BottomSheetBusinessUserRolesOptions";
import { useEffect, useState } from "react";
import {
  businessUserRoles,
  businessUserRolesOptions,
} from "shared/constants/business.constant";
import { tailwindTheme } from "shared/constants/theme.constant";
import { useDispatch } from "react-redux";
import { createBusinessUserAsync } from "shared/redux/business.slice";
import { useNavigate } from "react-router-dom";
import { isReactNativeWebView, sendMessageToNative } from "shared/utils/app.utils";
import BaseCard from "shared/components/shared/base/BaseCard";
import BaseButton from "shared/components/shared/base/BaseButton";
import {
  RightContentTabletContainer,
  WithResponsiveRightContentContainer,
} from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import BusinessRolePortal from "../shared/guards/BusinessRolePortal";
import { Icon } from "@iconify/react";
import useNativeMessage from "shared/hooks/useNativeMessage";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import BusinessAdminPortal from "./BusinessAdminPortal";
import BaseSearchSelect from "shared/components/shared/base/BaseSearchSelect";
import { getBusinessUsersSuggestionsService } from "shared/services/business.service";
import _ from "lodash";

const BusinessManageUserAdd = () => {
  const [isSheetRolesOpen, setIsSheetRolesOpen] = useState(false);
  const [role, setRole] = useState(businessUserRolesOptions[0]);

  const [usersSuggestions, setUsersSuggestions] = useState([]);
  const [user, setUser] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    loadUsersSuggestions();
  }, []);

  const loadUsersSuggestions = async () => {
    try {
      const { data } = await getBusinessUsersSuggestionsService();
      setUsersSuggestions(
        _.map(data, (it) => ({
          id: it.id,
          label: it.email,
        }))
      );
    } catch (error) {}
  };

  const onScanSuccess = (message) => {
    const { event, data } = message;

    if (event === "QrScanner") {
      setUser(data);
    }
  };

  useNativeMessage(onScanSuccess);

  const onScanClick = () => {
    sendMessageToNative({
      event: "QrScanner",
    });
  };

  const onSubmit = () => {
    dispatch(
      createBusinessUserAsync({
        payload: {
          userId: user?.id,
          role: role.value,
        },
        options: {
          onSuccess: () => {
            navigate(-1);
          },
        },
      })
    );
  };

  return (
    <>
      <TitledContent
        className="mb-4"
        title="Utilisateur"
        titleClassName="w-full mb-4"
      >
        <BaseSearchSelect
          list={usersSuggestions}
          onChange={setUser}
          value={user}
          placeholder="Rechercher..."
          searchKeys={["email", "fullName"]}
          leftIcon="hugeicons:user"
          className="rounded-md border-[1px] border-custom-border-main py-2"
        />
      </TitledContent>

      <TitledContent
        className="mb-[50px]"
        title="Selectionner son role"
        titleClassName="w-full mb-4"
      >
        <BaseCard
          className="w-full h-auto flex flex-row items-center justify-between rounded-md border-[1px] border-custom-border-main py-2"
          onClick={() => setIsSheetRolesOpen(true)}
        >
          <div className="flex flex-row items-center justify-start">
            <Icon
              icon={role?.icon}
              fontSize={20}
              color={tailwindTheme.colors.custom.icon.main}
              className="mr-2"
            />
            <div>{role.label}</div>
          </div>
          <Icon
            icon="hugeicons:arrow-down-01"
            fontSize={22}
            color={tailwindTheme.colors.custom.icon.main}
          />
        </BaseCard>
      </TitledContent>

      {isReactNativeWebView && (
        <BaseButton.Text
          secondary
          icon="hugeicons:qr-code-01"
          iconSize={22}
          onClick={onScanClick}
          className="w-full h-auto flex flex-row items-center justify-center rounded-full bg-transparent mb-4"
          labelClassName="text-white"
        >
          Scanner le QR d'un utilisateur
        </BaseButton.Text>
      )}

      <BaseButton.Contained
        primary
        icon="hugeicons:checkmark-circle-03"
        iconSize={24}
        onClick={onSubmit}
        className="w-full h-auto flex flex-row items-center justify-center rounded-full"
        disabled={user === undefined}
      >
        Confirmer
      </BaseButton.Contained>

      <BottomSheetBusinessUserRolesOptions
        onItemClick={setRole}
        isOpen={isSheetRolesOpen}
        onClose={() => setIsSheetRolesOpen(false)}
      />
    </>
  );
};

export default WithResponsive(
  () => (
    <BusinessAdminPortal>
      <HeaderBackContainer>
        <BusinessManageUserAdd />
      </HeaderBackContainer>
    </BusinessAdminPortal>
  ),
  () => (
    <BusinessAdminPortal>
      <RightContentTabletContainer
        breadCrumbItems={[
          tabsBreadcrumbs.business,
          tabsBreadcrumbs.businessManageTeam,
          tabsBreadcrumbs.businessManageTeamAdd,
        ]}
      >
        <LayoutContentContainer>
          <BusinessManageUserAdd />
        </LayoutContentContainer>
      </RightContentTabletContainer>
    </BusinessAdminPortal>
  )
);
