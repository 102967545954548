import OrderExludeIngredientsText from "./OrderExludeIngredientsText";
import OrderFoodImage from "./OrderFoodImage";
import OrderFoodItemPriceText from "./OrderFoodItemPriceText";
import OrderSupplementsText from "./OrderSupplementsText";

export default ({ data }) => {
  return (
    <div className="w-full h-auto flex flex-row items-start justify-start mb-8">
      <OrderFoodImage
        data={{
          name: data.businessFood.food.name,
          image: data.businessFood.image,
          units: data.units,
        }}
      />
      <div className="flex-1 flex flex-col items-start justify-start ml-4">
        <div className="text-lg mb-3">{data?.businessFood.food?.name}</div>
        <OrderExludeIngredientsText ingredients={data?.excludeIngredients} />
        <OrderSupplementsText supplements={data?.addSupplements} />
        <OrderFoodItemPriceText data={data} />
      </div>
    </div>
  );
};
