import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  HeaderBackContainer,
  LayoutContentContainer,
} from "shared/components/shared/core/Container";
import useBaseSelector from "shared/hooks/useBaseSelector";
import BusinessCart from "../cart/BusinessCart";
import {
  getOrderAsync,
  mergeOrderNodeData,
  setOrderInit,
  updateOrderAsync,
} from "shared/redux/order.slice";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { RightContentTabletContainer } from "business/components/shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import { ApplicationContext } from "context";

const OrderEditPage = ({ className }) => {
  const { business, businessTable, selections } = useBaseSelector("order.form");

  const variantContext = useContext(ApplicationContext);

  const { id: orderId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrderAsync({
        payload: orderId,
        options: {
          onSuccess: (data) => {
            dispatch(
              mergeOrderNodeData({
                node: "form",
                data: {
                  business: data?.business,
                  businessTable: data?.reservation?.businessTable,
                  selections: data?.selections.map((it) => ({
                    id: it.businessFood.id,
                    units: it.units,
                    price: it.businessFood?.price,
                    image: it.businessFood.image,
                    food: {
                      ...it.businessFood.food,
                      addSupplements: it.addSupplements,
                      excludeIngredients: it.excludeIngredients,
                    },
                  })),
                },
              })
            );
          },
        },
      })
    );
    return () => {
      //dispatch(setOrderInit("item"));
      dispatch(setOrderInit("form"));
    };
  }, []);

  const onSubmit = () => {
    const payload = {
      businessId: business?.id,
      businessTableId: businessTable?.id,
      selections: selections.map((it) => ({
        units: it.units,
        businessFoodId: it.id,
        excludeIngredientsIds: _.map(it?.food?.excludeIngredients, "id"),
        addSupplementsIds: _.map(it?.food?.addSupplements, "id"),
      })),
    };

    dispatch(
      updateOrderAsync({
        payload: {
          body: payload,
          id: orderId,
          query: {
            variant: variantContext,
          },
        },
        options: {
          onSuccess: (data) => {
            navigate(`/order/${data?.id}`);
            dispatch(setOrderInit("form"));
          },
        },
      })
    );
  };

  return (
    <BusinessCart
      onSubmit={onSubmit}
      variant="form"
      isAddButtonVisible={true}
      data={{
        business,
        businessTable,
        selections,
      }}
      className={className}
    />
  );
};

const MobileOrderEditPage = () => {
  return (
    <HeaderBackContainer
      headerBackProps={{
        marginBottom: false,
      }}
      layoutContainerProps={{
        horizontalPadding: false,
      }}
    >
      <OrderEditPage className="rounded-none" />
    </HeaderBackContainer>
  );
};

export default WithResponsive(MobileOrderEditPage, () => (
  <RightContentTabletContainer
    breadCrumbItems={[tabsBreadcrumbs.order, tabsBreadcrumbs.orderEdit]}
  >
    <OrderEditPage />
  </RightContentTabletContainer>
));
