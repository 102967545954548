import { businessUserRoles } from "shared/constants/business.constant";
import BusinessRolePortal from "../shared/guards/BusinessRolePortal";

export default ({ children }) => (
  <BusinessRolePortal
    roles={[businessUserRoles.owner, businessUserRoles.admin]}
  >
    {children}
  </BusinessRolePortal>
);
