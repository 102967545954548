import _ from "lodash";
import { TitledHorizontalScrolWithChildren } from "shared/components/shared/core/Container";
import { useDispatch } from "react-redux";
import MenuFoodItem from "./MenuFoodItem";
import {
  handleOnSelectionItemAdd,
  handleOnSelectionItemMinus,
} from "shared/utils/cart.utils";
import useBaseSelector from "shared/hooks/useBaseSelector";
import classnames from "classnames";
import { useState } from "react";
import { tailwindTheme } from "shared/constants/theme.constant";
import { Icon } from "@iconify/react";
import CategoryMenuItem from "./CategoryMenuItem";

export default ({ business, className, variant }) => {
  const currentBusiness = useBaseSelector(`order.${variant}.business`);
  const selections = useBaseSelector(`order.${variant}.selections`);

  const [view, setView] = useState("category");

  const dispatch = useDispatch();

  const onAddClick = (item) => {
    const reset = variant === "cart" && business?.id !== currentBusiness?.id;

    console.log(reset, business?.name);

    handleOnSelectionItemAdd(
      variant,
      selections,
      item,
      dispatch,
      reset
        ? {
            business,
            item,
          }
        : false
    );
  };

  const onMinusClick = (item) => {
    handleOnSelectionItemMinus(variant, selections, item, dispatch);
  };

  const getItemUnits = (item) => {
    return _.chain(selections)
      .filter((it) => it.id === item.id)
      .sumBy("units")
      .value();
  };

  return (
    <CategoryViewContainer view={view} setView={setView} className={className}>
      {view === "category" ? (
        <CategoryViewMenu
          menu={business?.menu}
          onAddClick={onAddClick}
          onMinusClick={onMinusClick}
          getItemUnits={getItemUnits}
        />
      ) : (
        <GridViewMenu
          menu={business?.menu}
          onAddClick={onAddClick}
          onMinusClick={onMinusClick}
          getItemUnits={getItemUnits}
        />
      )}
    </CategoryViewContainer>
  );
};

const CategoryViewMenu = ({ menu, onAddClick, onMinusClick, getItemUnits }) => {
  return (
    <div className="w-full h-auto gap-x-2">
      {_.map(menu, (menuItem, index) => (
        <TitledHorizontalScrolWithChildren
          title={menuItem?.name}
          key={index}
          horizontalScrollClassName="h-[240px]"
          items={menuItem?.children}
        >
          {_.map(menuItem?.children, (data, index) => (
            <span className="mr-2" key={index}>
              <MenuFoodItem
                data={data}
                onAddClick={onAddClick}
                onMinusClick={onMinusClick}
                units={getItemUnits(data)}
              />
            </span>
          ))}
        </TitledHorizontalScrolWithChildren>
      ))}
    </div>
  );
};

const GridViewMenu = ({ menu, onAddClick, onMinusClick, getItemUnits }) => {
  const [category, setCategory] = useState();
  const [gridViewType, setGridViewType] = useState("root");

  const onCategoryClick = (value) => {
    setCategory(value);
    setGridViewType("items");
  };

  const onBackClick = () => {
    setCategory();
    setGridViewType("root");
  };

  if (gridViewType === "root") {
    return (
      <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-5 gap-x-2 gap-y-6">
        {_.map(menu, (item, index) => (
          <CategoryMenuItem data={item} key={index} onClick={onCategoryClick} />
        ))}
      </div>
    );
  }

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex flex-row items-center justify-start mb-12">
        <Icon
          icon="hugeicons:link-backward"
          fontSize={28}
          color={tailwindTheme.colors.custom.icon.main}
          onClick={onBackClick}
          className="mr-2"
        />
        <div className="flex-1 text-lg lg:text-2xl font-semibold">
          {category?.name}
        </div>
      </div>
      <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-5 gap-x-2 gap-y-12">
        {_.map(category?.children, (item, index) => (
          <MenuFoodItem
            data={item}
            onAddClick={onAddClick}
            onMinusClick={onMinusClick}
            units={getItemUnits(item)}
          />
        ))}
      </div>
    </div>
  );
};

const CategoryViewContainer = ({ children, view, setView, className }) => {
  return (
    <div className={classnames("w-full h-auto pb-[120px]", className)}>
      <div className="w-full h-auto flex flex-row items-center justify-end mb-8">
        <div className="w-auto h-auto flex flex-row items-center justify-start">
          <div
            className={classnames("h-[40px] mr-2", {
              "border-b-[2px] border-custom-icon-main": view === "category",
            })}
          >
            <Icon
              icon="hugeicons:left-to-right-list-triangle"
              fontSize={24}
              color={
                view === "category"
                  ? tailwindTheme.colors.custom.icon.main
                  : tailwindTheme.colors.custom.icon.sub
              }
              onClick={() => view !== "category" && setView("category")}
            />
          </div>
          <div
            className={classnames("h-[40px] ml-2", {
              "border-b-[2px] border-custom-icon-main": view === "grid",
            })}
          >
            <Icon
              icon="hugeicons:grid-view"
              fontSize={24}
              color={
                view === "grid"
                  ? tailwindTheme.colors.custom.icon.main
                  : tailwindTheme.colors.custom.icon.sub
              }
              onClick={() => view !== "grid" && setView("grid")}
            />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
