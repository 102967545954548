import { useEffect, useRef, useState } from "react";
import { TitledContent } from "./Container";
import { HorizontalScrollWithChildren } from "./HorizontalScroll";
import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";
import { uploadFileService } from "shared/services/file.service";
import _ from "lodash";
import classNames from "classnames";

export default ({ value, onChange, imageClassName }) => {
  const [images, setImages] = useState([]);

  const ref = useRef();

  useEffect(() => {
    setImages(value ?? []);
  }, [value]);

  useEffect(() => {
    onChange(images);
  }, [images]);

  const onUploadClick = () => {
    ref?.current?.click();
  };

  const uploadAsync = async (file) => {
    const fileUrl = await uploadFileService(file);

    if (fileUrl) {
      setImages((prev) => {
        const update = [...prev];
        update.push(fileUrl);
        onChange(update);

        return update;
      });
    }
  };

  const onDeleteClick = (index) => {
    setImages((prev) => {
      const update = [...prev];
      update.splice(index, 1);

      onChange(update);
      return update;
    });
  };

  const onInputchange = (e) => {
    const files = e.target.files;

    if (!files || files?.length === 0) {
      return;
    }

    const file = files[0];

    uploadAsync(file);
  };

  return (
    <>
      <HorizontalScrollWithChildren>
        <ImageInputPlaceholder
          className={imageClassName}
          onClick={onUploadClick}
        />
        {_.map(images, (image, index) => (
          <ImageView
            key={index}
            value={image}
            onDeleteClick={() => onDeleteClick(index)}
            className={imageClassName}
          />
        ))}
      </HorizontalScrollWithChildren>
      <input
        ref={ref}
        id="files-input"
        type="file"
        className="hidden"
        onChange={onInputchange}
      />
    </>
  );
};

const ImageInputPlaceholder = ({ onClick, className }) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center rounded-lg _glassResponsivePerf cursor-pointer mr-2",
        className
      )}
      onClick={onClick}
    >
      <Icon
        icon="hugeicons:add-02"
        color={tailwindTheme.colors.custom.icon.main}
        fontSize={28}
      />
    </div>
  );
};

const ImageView = ({ value, onDeleteClick, className }) => {
  return (
    <div
      className={classNames(
        "rounded-lg relative overflow-hodden mr-2",
        className
      )}
    >
      <img
        className="w-full h-full rounded-lg absolute top-0 left-0"
        src={value}
      />
      <span
        className="w-[40px] h-[40px] flex flex-col items-center justify-center bg-palette-warning rounded-lg cursor-pointer absolute right-0 top-0 "
        onClick={onDeleteClick}
      >
        <Icon
          icon="hugeicons:delete-02"
          fontSize={24}
          color={tailwindTheme.colors.custom.icon.main}
        />
      </span>
    </div>
  );
};
