import UserProfileTop from "./shared/UserProfileTop";

import UserProfileSettings from "./UserProfileSettings";

export default () => {
  return (
    <>
      <UserProfileTop />
      <div className="px-4 lg:px-0">
        <UserProfileSettings />
      </div>
    </>
  );
};
