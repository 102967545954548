import _ from "lodash";
import { useEffect, useState } from "react";
import classnames from "classnames";
import { findBusinessPageTabByValue } from "shared/utils/business.utils";
import useScrollWatch from "shared/hooks/useScrollWatch";
import useBusinessPageTabs from "shared/hooks/business/useBusinessPageTabs";

export default () => {
  const [currentTab, setCurrentTab] = useState();

  const tabs = useBusinessPageTabs();

  useEffect(() => {
    setCurrentTab(findBusinessPageTabByValue(tabs, "menu"));
  }, []);

  const onTabClick = (tab) => {
    setCurrentTab(findBusinessPageTabByValue(tabs, tab.value));
  };

  return (
    <div className="w-full h-auto">
      <Tabs tabs={tabs} currentTab={currentTab} onTabClick={onTabClick} />
      <div>{currentTab?.component}</div>
    </div>
  );
};

const Tabs = ({ tabs, currentTab, onTabClick }) => {
  const [isFixed, setIsFixed] = useState(false);

  const { y } = useScrollWatch();

  useEffect(() => {
    if (y > 206) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, [y]);

  return (
    <div
      className={classnames(
        "w-full h-[50px] flex flex-items-center justify-between border-y-[1px] border-custom-border-main z-40 mb-4",
        {
          "_glass sticky top-[60px]": isFixed,
        }
      )}
    >
      {_.map(tabs, (tab, index) => (
        <TabButton
          data={tab}
          key={index}
          selected={currentTab?.value === tab.value}
          onClick={onTabClick}
        />
      ))}
    </div>
  );
};

const TabButton = ({ data, containerClassName, onClick, selected = false }) => {
  return (
    <div
      onClick={() => onClick?.(data)}
      className={classnames(
        containerClassName,
        "w-full h-full flex flex-row justify-center items-center relative",
        {
          "border-b-[1px] border-secondary-main": selected,
        }
      )}
    >
      <div
        className={classnames(
          "w-[40px] h-[40px] flex flex-col items-center justify-center relative"
        )}
      >
        {selected ? data?.selectedIcon : data?.defaultIcon}

        {data?.badgeNumber > 0 && (
          <div className="w-[25px] h-[25px] rounded-full flex flex-col items-center justify-center absolute -top-3 -left-1 bg-primary-main">
            <div className="text-white text-sm font-semibold">
              {data?.badgeNumber}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// const scrollContent = (
//   <HorizontalScrollWithChildren className={classnames("w-full pr-[40px]")}>
//     {_.map(tabs, (tab, index) => (
//       <div className="w-auto h-auto mr-2" key={index}>
//         <Tag
//           data={tab}
//           onClick={onTabClick}
//           selected={currentTab?.value === tab.value}
//           className="rounded-full"
//           defaultIcon={tab?.defaultIcon}
//           selectedIcon={tab?.selectedIcon}
//           changeOnClick={false}
//         />
//       </div>
//     ))}
//   </HorizontalScrollWithChildren>
// );
