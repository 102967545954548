import BaseRadio from "shared/components/shared/base/BaseRadio";
import classnames from "classnames";
import OrderFoodImage from "shared/components/order/shared/OrderFoodImage";
import OrderExludeIngredientsText from "shared/components/order/shared/OrderExludeIngredientsText";
import OrderSupplementsText from "../shared/OrderSupplementsText";
import { tailwindTheme } from "shared/constants/theme.constant";
import BaseButton from "shared/components/shared/base/BaseButton";
import BaseDivider from "shared/components/shared/base/BaseDivider";
import { Icon } from "@iconify/react";

export const BusinessCartSelection = ({
  data,
  onDeleteClick,
  onAddClick,
  onMinusClick,
  onExpandClick,
  onChangeIngredientsClick,
  onChangeSupplementsClick,
  className,
}) => {
  return (
    <div className={classnames("w-full h-auto mb-4", className)}>
      <div className="flex flex-row items-start justify-start mb-4">
        <OrderFoodImage
          data={{
            name: data?.food?.name,
            image: data?.image,
            units: data?.units,
          }}
          className="mr-4"
        />
        <div className="w-full h-auto flex flex-col items-start justify-start">
          <div className="w-full text-white font-medium text-lg mb-3">
            {data?.food?.name} {`( X${data?.units} )`}
          </div>
          <OrderExludeIngredientsText
            ingredients={data?.food?.excludeIngredients}
          />
          <OrderSupplementsText supplements={data?.food?.addSupplements} />

          {data?.food?.ingredients?.length > 0 && (
            <BaseButton.Text
              secondary
              underline
              icon="hugeicons:pencil-edit-02"
              onClick={() => onChangeIngredientsClick(data)}
              className="py-2 mb-2"
            >
              Modifier les ingédients
            </BaseButton.Text>
          )}
          {data?.food?.supplements?.length > 0 && (
            <BaseButton.Text
              secondary
              underline
              icon="hugeicons:pencil-edit-02"
              onClick={() => onChangeSupplementsClick(data)}
              className="py-2 mb-4"
            >
              Modifier les suppléments
            </BaseButton.Text>
          )}
          <BusinessCartQuantitySelection
            onAddClick={() => onAddClick(data)}
            onMinusClick={() => onMinusClick(data)}
            onExpandClick={() => onExpandClick(data)}
            onDelete={() => onDeleteClick(data)}
            count={data?.units}
            className="w-2/3"
          />
        </div>
      </div>
      <BaseDivider />
    </div>
  );
};

const consumeTypeOptions = [
  {
    label: "Sur place",
    value: "inRestaurant",
  },
  {
    label: "Emporté",
    value: "carried",
  },
];

export const BusinessCartConsumeTypeSelection = ({
  onChange,
  data,
  className,
}) => {
  const onRadioChange = (value) => {
    onChange(value);
  };

  return (
    <BaseRadio
      onChange={onRadioChange}
      data={data}
      options={consumeTypeOptions}
      className={classnames("mb-3", className)}
    />
  );
};

export const BusinessCartQuantitySelection = ({
  count,
  onAddClick,
  onMinusClick,
  onExpandClick,
  onDelete,
  className,
}) => {
  return (
    <div
      className={classnames(
        "flex flex-row items-center justify-between h-auto mb-4",
        className
      )}
    >
      <Icon
        icon="hugeicons:add-circle"
        fontSize={28}
        color={tailwindTheme.colors.custom.icon.main}
        onClick={onAddClick}
        className="cursor-pointer"
      />
      {count > 1 && (
        <Icon
          icon="hugeicons:minus-sign-circle"
          fontSize={28}
          color={tailwindTheme.colors.custom.icon.main}
          onClick={onMinusClick}
          className="cursor-pointer"
        />
      )}
      <Icon
        icon="hugeicons:delete-01"
        fontSize={28}
        color={tailwindTheme.colors.palette.warning}
        onClick={onDelete}
        className="cursor-pointer"
      />
    </div>
  );
};
