import { createSlice } from "@reduxjs/toolkit";
import reduxUtils from "./utils/redux.utils";
import { createNodeThunkMiddleware } from "./utils/thunk.utils";
import {
  getNotificationsService,
  setNotificationsViewsService,
} from "shared/services/notification.service";
import { pushNewNotificationHandler } from "./reducerHandlers/notificationHandler";

const managementActions = reduxUtils.createManagementActions("/notification");

const nodeThunkMiddleware = createNodeThunkMiddleware(managementActions);

export const getNotificationsAsync = nodeThunkMiddleware(
  "/notification/main",
  getNotificationsService,
  "main"
);

export const setNotificationsViewsAsync = nodeThunkMiddleware(
  "/notification/set-views",
  setNotificationsViewsService,
  "api"
);

const initialState = {
  overview: {
    notViewedCount: 0,
  },
  main: {
    loading: false,
    error: undefined,
    data: {
      total: 0,
      count: 0,
      data: [],
    },
  },
  system: {
    loading: false,
    error: undefined,
    data: {
      total: 0,
      count: 0,
      data: [],
    },
  },
  api: {
    loading: false,
    error: undefined,
  },
};

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    pushNewNotification: pushNewNotificationHandler,
  },
  extraReducers: (builder) => {
    reduxUtils.tookit
      .buildManagementCases(builder, managementActions, initialState)
      .addCase(
        getNotificationsAsync.fulfilled,
        reduxUtils.hof.onRootState.list.wrappedListHandler("main")
      );
  },
});

export const setNotificationNodeData = managementActions.setNodeData;
export const setNotificationNodeFieldData = managementActions.setNodeFieldData;
export const mergeNotificationNodeData = managementActions.mergeNodeData;
export const setNotificationInit = managementActions.setInit;

export const { pushNewNotification } = slice.actions;

export const notificationReducer = slice.reducer;
