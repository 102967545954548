import classnames from "classnames";
import { HeartTag } from "shared/components/shared/core/Tags";
import { tailwindTheme } from "shared/constants/theme.constant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteFavoriteAsync, setFavoriteAsync } from "shared/redux/user.slice";
import BaseSkeleton from "shared/components/shared/base/BaseSkeleton";
import BaseImage from "shared/components/shared/base/BaseImage";

export const BusinessCard = ({ data, className }) => {
  const { data: user } = useSelector((state) => state.user.me);

  const [isFavorite, setIsFavorite] = useState(data?.isFavorite ?? false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    setIsFavorite(data?.isFavorite);
  }, [data]);

  const onHeartClick = () => {
    if (!user) {
      navigate("/me/auth");
    } else {
      if (isFavorite) {
        dispatch(
          deleteFavoriteAsync({
            payload: {
              business: data?.id,
            },
            options: {
              onError: () => setIsFavorite((prev) => !prev),
            },
          })
        );
      } else {
        dispatch(
          setFavoriteAsync({
            payload: {
              business: data?.id,
            },
            options: {
              onError: () => setIsFavorite((prev) => !prev),
            },
          })
        );
      }
      setIsFavorite((prev) => !prev);
    }
  };

  return (
    <div
      className={classnames(
        "min-w-[300px] lg:w-[340px] h-[255px] flex flex-col items-start justify-start",
        className
      )}
    >
      <div className="w-full h-[200px] rounded-2xl relative border-[1px] border-primary-main overflow-hidden">
        <div
          className="w-full h-full rounded-2xl absolute top-0 bg-black opacity-35 z-10"
          onClick={() => navigate(`/${data?.slug}`)}
        />
        <BaseImage
          className="w-full h-full rounded-2xl"
          src={data?.thumbnail}
        />
        <HeartTag
          onClick={() => onHeartClick(data)}
          changeOnClick={false}
          selected={isFavorite}
          className="absolute top-[20px] right-[20px] z-20"
        />
      </div>
      <div className="w-auto h-[50px] pt-2 pl-2">
        <div className="w-full text-lg font-semibold">{data?.name}</div>
        <div className="text-sm font-bold text-custom-text-sub">
          {data?.address}
        </div>
      </div>
    </div>
  );
};

export const BusinessCardSkeleton = () => {
  return <BaseSkeleton className="w-[280px] h-[220px] rounded-2xl" />;
};
