import { Icon } from "@iconify/react";
import BaseButton from "../base/BaseButton";
import { tailwindTheme } from "shared/constants/theme.constant";
import classNames from "classnames";

export const ConfirmButton = ({ onClick, className, ...props }) => {
  return (
    <BaseButton.Contained
      primary
      icon="hugeicons:checkmark-circle-03"
      iconSize={24}
      onClick={onClick}
      className={classNames(
        "w-full h-auto flex flex-row items-center justify-center rounded-full",
        className
      )}
      {...props}
    >
      Confirmer
    </BaseButton.Contained>
  );
};
