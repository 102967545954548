import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { tailwindTheme } from "shared/constants/theme.constant";
import { verifyUserEmailService } from "shared/services/user.service";

export default () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setError("Lien invalide");
      return;
    }

    verify();
  }, []);

  const verify = async () => {
    try {
      setLoading(true);
      await verifyUserEmailService({ token });
    } catch (error) {
      setError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="w-full h-auto flex flex-col items-center justify-start mb-12">
        <div className="w-[100px] h-[100px] flex flex-col items-center justify-center bg-primary-main rounded-full mb-3">
          <Icon
            icon="hugeicons:mail-02"
            fontSize={60}
            color={tailwindTheme.colors.custom.icon.main}
          />
        </div>
        <div className="text-2xl font-semibold text-center">
          Nous vérifions votre email
        </div>
      </div>
      <PulseLoader
        color="white"
        loading={loading}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {error && (
        <div className="w-full text-center font-semibold text-lg">{error}</div>
      )}
    </div>
  );
};
