import { createSlice } from "@reduxjs/toolkit";
import reduxUtils from "./utils/redux.utils";
import { createNodeThunkMiddleware } from "./utils/thunk.utils";
import {
  createOrderService,
  deleteOrderService,
  getOrderService,
  getOrdersService,
  patchOrderService,
  updateOrderService,
} from "shared/services/order.service";
import {
  deleteOrderSuccessHandler,
  syncOrdersStatusChangeHandler,
} from "./reducerHandlers/orderReducerHandler";

const managementActions = reduxUtils.createManagementActions("/order");

const nodeThunkMiddleware = createNodeThunkMiddleware(managementActions);

export const getOrdersAsync = nodeThunkMiddleware(
  "/order/list",
  getOrdersService,
  "list"
);

export const getOrdersByStatusAsync = nodeThunkMiddleware(
  "/order/list/byStatus",
  getOrdersService,
  (payload) => payload?.payload?.query?.status
);

export const getOrderAsync = nodeThunkMiddleware(
  "/order/item/get",
  getOrderService,
  "item"
);

export const deleteOrderAsync = nodeThunkMiddleware(
  "/order/item/delete",
  deleteOrderService,
  "api"
);

export const createOrderAsync = nodeThunkMiddleware(
  "/order/form/create",
  createOrderService,
  "form"
);

export const patchOrderAsync = nodeThunkMiddleware(
  "/order/form/patch",
  patchOrderService,
  "form"
);

export const updateOrderAsync = nodeThunkMiddleware(
  "/order/form/update",
  updateOrderService,
  "form"
);

const initialState = {
  list: {
    hasMore: true,
    loading: false,
    data: {
      total: 0,
      data: [],
    },
  },
  waitingAcceptance: {
    hasMore: true,
    loading: false,
    data: {
      total: 0,
      data: [],
    },
  },
  waiting: {
    hasMore: true,
    loading: false,
    data: {
      total: 0,
      data: [],
    },
  },
  inProgress: {
    hasMore: true,
    loading: false,
    data: {
      total: 0,
      data: [],
    },
  },
  ready: {
    hasMore: true,
    loading: false,
    data: {
      total: 0,
      data: [],
    },
  },
  done: {
    hasMore: true,
    loading: false,
    data: {
      total: 0,
      data: [],
    },
  },
  item: {
    loading: false,
    error: null,
    data: null,
  },
  cart: {
    loading: false,
    error: null,
    data: null,
    business: null,
    businessTable: null,
    selections: [],
  },
  form: {
    loading: false,
    error: null,
    data: null,
    business: null,
    businessTable: null,
    selections: [],
  },
  api: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: "order",
  initialState,
  reducers: {
    syncOrdersStatusChange: syncOrdersStatusChangeHandler,
  },
  extraReducers: (builder) => {
    reduxUtils.tookit
      .buildManagementCases(builder, managementActions, initialState)
      .addCase(
        getOrdersAsync.fulfilled,
        reduxUtils.hof.onRootState.list.wrappedListHandler("list")
      )
      .addCase(getOrdersByStatusAsync.fulfilled, (state, action) => {
        reduxUtils.hof.onRootState.list.wrappedListHandler(
          action?.meta?.arg?.payload?.query.status
        )(state, action);
      })
      .addCase(
        getOrderAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("item.data")
      )
      .addCase(
        createOrderAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("form.data")
      )
      .addCase(patchOrderAsync.fulfilled, syncOrdersStatusChangeHandler)
      .addCase(
        updateOrderAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("form.data")
      )
      .addCase(deleteOrderAsync.fulfilled, deleteOrderSuccessHandler);
  },
});

export const { syncOrdersStatusChange } = slice.actions;

export const setOrderNodeFieldData = managementActions.setNodeFieldData;
export const mergeOrderNodeData = managementActions.mergeNodeData;

export const setOrderInit = managementActions.setInit;

export const orderReducer = slice.reducer;
