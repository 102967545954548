import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import BaseImage from "shared/components/shared/base/BaseImage";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({ data }) => {
  return (
    <div className="w-full min-h-[180px] flex flex-col items-start justify-start px-4 py-4 mb-2">
      <div className="w-full h-auto flex flex-row items-start justify-start mb-6">
        <BaseImage
          src={data?.avatar}
          defaultIcon="hugeicons:cayan-tower"
          className="w-[100px] h-[100px] rounded-full border-[4px] border-background-light-0 mr-8"
        />
        <div className="grow h-auto flex flex-col items-start justify-start">
          <div className="font-semibold text-lg">{data?.name}</div>
          <div className="w-full text-md text-custom-text-sub mb-1">
            {data?.address}
          </div>
          <div className="w-full text-md text-custom-text-sub">
            {data?.info}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BusinessPageHeader = ({ data }) => {
  return (
    <div className="w-full h-[200px] flex flex-col items-start justify-start relative px-4 py-4 mb-2">
    
      <div className="w-full h-auto flex flex-col items-start justify-start">
        <BaseImage
          src={data?.avatar}
          defaultIcon="hugeicons:cayan-tower"
          className="w-[100px] h-[100px] rounded-full border-[4px] border-background-light-0 mb-3"
        />
        <div className="grow h-auto flex flex-col items-start justify-start">
          <div className="font-semibold text-xl">{data?.name}</div>
          <div className="w-full text-md text-custom-text-sub">
            {data?.address}
          </div>
          <div className="w-full text-md text-custom-text-sub">
            {data?.info}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BusinessPageHeaderTablet = ({ data }) => {
  return (
    <div className="w-full h-[400px] flex flex-col items-start justify-center relative px-4 py-4 relative mb-2">
       <BaseImage
          src={data?.thumbnail}
          defaultIcon="hugeicons:cayan-tower"
          className="w-full h-[220px] rounded-b-xl absolute top-0 left-0 -z-10"
        />
      <div className="w-auto h-auto flex flex-row items-start justify-start absolute top-[230px] left-4">
        <BaseImage
          src={data?.avatar}
          defaultIcon="hugeicons:cayan-tower"
          className="w-[150px] h-[150px] rounded-full border-[4px] border-primary-main mr-8"
        />
        <div className="w-auto h-auto flex flex-col items-start justify-start">
          <div className="font-semibold text-xl">{data?.name}</div>
          <div className="w-full text-custom-text-sub">{data?.address}</div>
          <div className="w-full max-w-[200px] text-custom-text-sub">{data?.info}</div>
        </div>
      </div>
    </div>
  );
};

export const BusinessOrderHeader = ({
  data,
  className,
  noBusinessClick = false,
}) => {
  const navigate = useNavigate();

  const onBusinessClick = () => {
    if (noBusinessClick) {
      return;
    }
    navigate(`/${data?.slug}`);
  };

  return (
    <div
      className={classNames(
        "w-full h-auto flex flex-col items-center justify-center px-4 py-4",
        className
      )}
      onClick={onBusinessClick}
    >
      <div className="w-full flex flex-row items-center justify-center">
        <BaseImage
          src={data?.avatar}
          defaultIcon="hugeicons:cayan-tower"
          className="w-[80px] h-[80px] rounded-full border-[4px] border-background-light-0 mr-4"
        />
        <div className="flex flex-col items-start justify-start">
          <div className="font-semibold text-lg mb-1">{data?.name}</div>
          <div className="w-full text-md text-custom-text-sub">
            {data?.address}
          </div>
        </div>
      </div>
    </div>
  );
};

const Rating = ({ value, className }) => {
  return (
    <div
      className={classNames(
        "w-auto h-auto flex flex-row items-center justify-start",
        className
      )}
    >
      <Icon
        icon="hugeicons:star"
        fontSize={18}
        color={tailwindTheme.colors.primary.main}
        className="mr-1"
      />
      <div className="font-semibold text-lg">{value}</div>
    </div>
  );
};
