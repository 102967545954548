import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import WithResponsive from "../utils/WithResponsive";

const BaseSkeletonMobile = (props) => {
  return <Skeleton {...props} />;
};

const BaseSkeletonTablet = ({ className, ...props }) => {
  return (
    <div
      className={classNames(
        "-skeleton",
        {
          //enable rounded override, daisy skeleton is rounded by default
          "rounded-none": className && className?.indexOf("rounded") === -1,
        },
        className
      )}
      {...props}
    ></div>
  );
};

export default WithResponsive(BaseSkeletonMobile, BaseSkeletonTablet);
