import { Icon } from "@iconify/react";
import classNames from "classnames";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({ type, title, message }) => {
  return (
    <div
      className={classNames(
        "w-full flex flex-row items-start justify-start rounded-lg px-4 py-2",
        {
          "bg-green-600": type === "success",
          "bg-red-600": type === "error",
          "bg-yellow-600": type === "warning",
          "bg-background-light0": type === "info",
        }
      )}
    >
      <Icon
        icon={
          {
            success: "hugeicons:checkmark-circle-03",
            error: "hugeicons:alert-circle",
            warning: "hugeicons:alert-01",
            info: "hugeicons:information-circle",
          }[type]
        }
        fontSize={32}
        color={tailwindTheme.colors.custom.icon.main}
        className="min-w-[32px] mr-2"
      />
      <div className="flex flex-col items-start justify-start">
        <div className="text-xl text-white mb-1">{title}</div>
        <div className="text-sm text-white">{message}</div>
      </div>
    </div>
  );
};
