import classNames from "classnames";
import BaseSkeleton from "../base/BaseSkeleton";

export const HeaderLoader = () => {
  return (
    <BaseSkeleton className="w-full h-[200px] border-b-[1px] border-custom-border-main mb-2" />
  );
};

export const RowLoader = ({ className }) => {
  return (
    <BaseSkeleton
      className={classNames(
        "w-full border-b-[1px] border-custom-border-main",
        className
      )}
    />
  );
};
