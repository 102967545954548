import _ from "lodash";
import { LayoutContentContainer } from "shared/components/shared/core/Container";
import { ErrorMessagePage } from "shared/components/shared/core/Error";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { validateBusinessRouteSlug } from "shared/utils/business.utils";
import BusinessPageTabs from "public/components/business/BusinessPageTabs";
import { useDispatch } from "react-redux";
import {
  getBusinessBySlugAsync,
  setBusinessInit,
} from "shared/redux/business.slice";
import { mergeOrderNodeData, setOrderInit } from "shared/redux/order.slice";
import BusinessHeader, {
  BusinessPageHeader,
  BusinessPageHeaderTablet,
} from "./shared/BusinessHeader";
import useBaseSelector from "shared/hooks/useBaseSelector";
import { RowLoader } from "shared/components/shared/core/Loader";
import MenuLoader from "shared/components/order/menu/MenuLoader";
import HeaderBack from "shared/components/shared/core/HeaderBack";
import LoaderPortal from "shared/components/shared/utils/LoaderPortal";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import { RightContentTabletContainer } from "business/components/shared/SideContainer";
import BaseSkeleton from "shared/components/shared/base/BaseSkeleton";

const BusinessPageMobile = () => {
  const { data, loading } = useBaseSelector("business.item");

  const [validSlug, setValidSlug] = useState();

  const { businessSlug: slug } = useParams();

  const [searchParams, __] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!validateBusinessRouteSlug(slug)) {
      setValidSlug(false);
      return;
    }

    dispatch(getBusinessBySlugAsync(slug));
  }, [slug]);

  useEffect(() => {
    if (!data) {
      return;
    }

    let nodeData = {
      business: data,
    };

    const table = searchParams.get("t") ?? -1;

    if (table) {
      nodeData.businessTable = _.find(
        data.businessTables,
        (businessTable) => businessTable.table === parseInt(table)
      );
    }

    dispatch(
      mergeOrderNodeData({
        node: "cart",
        data: nodeData,
      })
    );
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setBusinessInit("item"));
      dispatch(setOrderInit("cart"));
    };
  }, []);

  return (
    <LoaderPortal
      loader={
        <LayoutContentContainer
          horizontalPadding={false}
          headerPadding={false}
          className="pb-[60px] min-h-[1000px] pt-0 -mt-[4px]"
        >
          <RowLoader className="h-[60px] mb-2" />
          <div className="w-full h-auto flex flex-col items-start justify-start mb-8 px-4 ">
            <BaseSkeleton className="w-[100px] h-[100px] rounded-full mb-2" />
            <BaseSkeleton className="w-[150px] h-[20px]" />
          </div>
          <div className="w-full px-4 mb-8">
            <RowLoader className="h-[40px]" />
          </div>
          <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-2 px-4">
            {_.range(4).map((__, index) => (
              <BaseSkeleton
                key={index}
                className="w-full xl:max-w-[200px]  h-[120px] lg:h-[170px] rounded-xl"
              />
            ))}
          </div>
        </LayoutContentContainer>
      }
      reducerPath="business.item"
      errorMessage="Invalid Shop URL"
    >
      <LayoutContentContainer
        horizontalPadding={false}
        headerPadding={false}
        className="pb-[60px] min-h-[1000px]"
      >
        <HeaderBack title={data?.name} sticky />
        <BusinessPageHeader data={data} />
        <BusinessPageTabs />
      </LayoutContentContainer>
    </LoaderPortal>
  );
};

const BusinessPageTablet = () => {
  const { data, loading } = useBaseSelector("business.item");

  const [validSlug, setValidSlug] = useState();

  const { businessSlug: slug } = useParams();

  const [searchParams, __] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!validateBusinessRouteSlug(slug)) {
      setValidSlug(false);
      return;
    }

    dispatch(getBusinessBySlugAsync(slug));
  }, [slug]);

  useEffect(() => {
    const table = searchParams.get("t") ?? -1;

    if (data && table) {
      const businessTable = _.find(
        data.businessTables,
        (businessTable) => businessTable.table === parseInt(table)
      );

      dispatch(
        mergeOrderNodeData({
          node: "cart",
          data: {
            businessTable,
          },
        })
      );
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setBusinessInit("item"));
      dispatch(setOrderInit("cart"));
    };
  }, []);

  return (
    <LoaderPortal
      loader={
        <LayoutContentContainer
          horizontalPadding={false}
          headerPadding={false}
          className="pb-[60px] min-h-[1000px] pt-0 -mt-[4px]"
        >
          <RowLoader className="h-[60px]" />
          <RowLoader className="h-[200px] mb-2" />
          <MenuLoader className="mt-8 px-4" />
        </LayoutContentContainer>
      }
      reducerPath="business.item"
      errorMessage="Invalid Shop URL"
    >
      <LayoutContentContainer
        horizontalPadding={false}
        headerPadding={false}
        className="pb-[60px] min-h-[1000px]"
      >
        <BusinessPageHeaderTablet data={data} />
        <BusinessPageTabs />
      </LayoutContentContainer>
    </LoaderPortal>
  );
};

export default WithResponsive(BusinessPageMobile, BusinessPageTablet);
