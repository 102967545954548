import { PageNotFound } from "shared/components/shared/core/Error";
import useBaseSelector from "shared/hooks/useBaseSelector";

export default (Component, { store, loader }) =>
  (props) => {
    const { error, loading } = useBaseSelector(store);

    if (loading) {
      return <>{loader ?? <></>}</>;
    }

    if (error) {
      return <PageNotFound />;
    }

    return <Component {...props} />;
  };
