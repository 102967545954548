import useBaseSelector from "shared/hooks/useBaseSelector";
import Notification from "./Notification";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getNotificationsAsync,
  setNotificationInit,
  setNotificationsViewsAsync,
} from "shared/redux/notification.slice";
import { paginationLimit } from "shared/constants/shared.constant";
import PullToRefresh from "react-simple-pull-to-refresh";
import { ApplicationContext } from "context";
import BaseButton from "../shared/base/BaseButton";
import { Icon } from "@iconify/react";

export default ({ route }) => {
  const {
    data: { data: notifcations },
    hasMore,
  } = useBaseSelector(`notification.${route}`);

  const variant = useContext(ApplicationContext);

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    setViews();
  }, []);

  const setViews = () => {
    dispatch(
      setNotificationsViewsAsync({
        query: {
          variant,
        },
      })
    );
  };

  const load = (params) => {
    const reset = params?.reset === true;

    const fetchPage = reset ? 0 : page;

    dispatch(
      getNotificationsAsync({
        payload: {
          query: {
            offset: fetchPage * paginationLimit,
            limit: paginationLimit,
            variant,
          },
        },
        options: {
          onSuccess: () => {
            setPage(fetchPage + 1);
          },
          reducerOptions: {
            reset,
          },
        },
      })
    );
  };
  return (
    <PullToRefresh
      onRefresh={async () => await Promise.resolve(load({ reset: true }))}
    >
      {_.map(notifcations, (data, index) => (
        <Notification data={data} className="w-full" key={index} />
      ))}
      {hasMore && (
        <div className="mt-4 px-8">
          <BaseButton.Contained
            primary
            icon="hugeicons:add-02"
            onClick={load}
            className="w-full flex flex-row items-center justify-center rounded-full py-2"
          >
            Voir plus
          </BaseButton.Contained>
        </div>
      )}
    </PullToRefresh>
  );
};
