import api from "shared/utils/api.utils";

const routes = {
  overview: "/business-module/analytics/overview?variant=business",
  salesOverview: "/business-module/analytics/sales/overview?variant=business",
  salesPeriodic: "/business-module/analytics/sales/periodic?variant=business",
};

export const getBusinessOverviewService = async () => {
  return await api.get(routes.overview);
};

export const getBusinessSalesOverviewService = async () => {
  return await api.get(routes.salesOverview);
};

export const getBusinessSalesPeriodicService = async (params) => {
  return await api.get(routes.salesPeriodic, { params });
};
