import { ExploreBusinessList } from "public/components/explore/ExploreBusiness";
import BaseButton from "shared/components/shared/base/BaseButton";
import {
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getFavoritesAsync, setUserInit } from "shared/redux/user.slice";
import AuthBottomSheetPortal from "shared/components/user/AuthBottomSheetPortal";

const UserFavoriteTab = () => {
  const {
    data: { data: businessList },
  } = useSelector((state) => state.user.favorite);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavoritesAsync());
    return () => dispatch(setUserInit("favorite"));
  }, []);

  return (
    <LayoutContentContainer>
      <TitledContent title="Your favorites">
        <ExploreBusinessList data={businessList} />
      </TitledContent>
    </LayoutContentContainer>
  );
};

export default () => (
  <AuthBottomSheetPortal>
    <UserFavoriteTab />
  </AuthBottomSheetPortal>
);
