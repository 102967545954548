import { useSelector } from "react-redux";
import BoltMenu from "shared/components/order/menu/BoltMenu";
import CategoryMenu from "shared/components/order/menu/CategoryMenu";

export default () => {
  const { data: business } = useSelector((state) => state.business.item);

  return (
    <div className="w-full h-auto px-4">
      <BoltMenu business={business} variant="cart" />
    </div>
  );
};
