import api from "shared/utils/api.utils";

const routes = {
  home: "/business-module/business/home",
  list: "/business-module/business",
  bySlug: (slug) => "/business-module/business/slug/" + slug,
  get: (id) => "/business-module/business/" + id,
  create: "/business-module/business",
  users: "/business-module/business/user?variant=business",
  userDelete: (id) => `/business-module/business/user/${id}?variant=business`,
  usersSuggestions:
    "/business-module/business/user/join/suggestions?variant=business",
  usersPut: (id) =>
    "/business-module/business/user/" + id + "?variant=business",
  waitingUsers: "/business-module/business/user/waiting?variant=business",
  tables: (id) => "/business-module/business/" + id + "/table",
  foodCreate: "/business-module/business/food/bulk?variant=business",
  foodPatch: (id) =>
    "/business-module/business/food/" + id + "?variant=business",
};

export const getBusinessHomeService = async (params) => {
  return await api.get(routes.home, { params });
};

export const getBusinessListService = async (payload) => {
  return await api.get(routes.list, { params: payload?.query });
};

export const getBusinessBySlugService = async (slug) => {
  return await api.get(routes.bySlug(slug));
};

export const getBusinessService = async (id) => {
  return await api.get(routes.get(id));
};

export const createBusinessService = async (payload) => {
  return await api.post(routes.create, payload);
};

export const updateBusinessService = async (payload) => {
  return await api.patch(routes.get(payload.id), payload.body);
};

export const getBusinessUsersService = async (params) => {
  return await api.get(routes.users, { params });
};

export const deleteBusinessUserService = async (id) => {
  return await api.delete(routes.userDelete(id));
};

export const getBusinessUsersSuggestionsService = async (params) => {
  return await api.get(routes.usersSuggestions, { params });
};

export const createBusinessUsersService = async (payload) => {
  return await api.post(routes.users, payload);
};

export const updateBusinessUsersService = async (params) => {
  return await api.put(routes.usersPut(params.id), params.body);
};

export const getWaitingBusinessUsersService = async (params) => {
  return await api.get(routes.waitingUsers, { params });
};

export const getBusinessTablesService = async (payload) => {
  return await api.get(routes.tables(payload?.id), { params: payload?.query });
};

export const createBusinessFoodService = async (payload) => {
  return await api.post(routes.foodCreate, payload);
};

export const updateBusinessFoodService = async (payload) => {
  return await api.patch(routes.foodPatch(payload.id), payload.body);
};

export const deleteBusinessFoodService = async (id) => {
  return await api.delete(routes.foodPatch(id));
};