import { useEffect, useState } from "react";
import { Order } from "shared/components/order/Order";
import BottomSheetOrderOptionsBusiness from "./BottomSheetOrderOptionsBusiness";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import BaseButton from "shared/components/shared/base/BaseButton";
import BottomSheetOrderStatusOptionsBusiness from "./BottomSheetOrderStatusOptionsBusiness";
import LoaderPortal from "shared/components/shared/utils/LoaderPortal";
import useBaseSelector from "shared/hooks/useBaseSelector";
import BaseSkeleton from "shared/components/shared/base/BaseSkeleton";

export default ({
  onLoadMoreClick,
  filterStatus = "all",
  reducerPath = "order.list",
}) => {
  const {
    data: { data },
    hasMore,
  } = useBaseSelector(reducerPath);

  const [orders, setOrders] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [currentOrder, setCurrentOrder] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (filterStatus === "all") {
      setOrders(data);
    } else {
      setOrders(_.filter(data, (it) => it.status === filterStatus));
    }
  }, [data]);

  const onMoreClick = (order) => {
    setCurrentOrder(order);
    setIsModalOpen(true);
  };

  const onBusinessClick = (order) => {
    navigate("/");
  };

  const onStatusClick = (order) => {
    setCurrentOrder(order);
    setIsStatusModalOpen(true);
  };

  return (
    <LoaderPortal
      loader={
        <>
          {_.range(3).map((__, index) => (
            <BaseSkeleton
              className="w-full max-w-[450px] h-[180px] rounded-2xl mb-2"
              key={index}
            />
          ))}
        </>
      }
      reducerPath={reducerPath}
      isList
    >
      <div className="w-full max-w-[450px] h-auto">
        {_.map(orders, (data, index) => (
          <div className="w-full mb-6" key={index}>
            <Order
              data={data}
              className="w-full"
              onMoreClick={() => onMoreClick(data)}
              onStatusClick={() => onStatusClick(data)}
              variant="business"
            />
          </div>
        ))}
        {hasMore && (
          <BaseButton.Contained
            primary
            icon="hugeicons:add-02"
            iconSize={18}
            onClick={onLoadMoreClick}
            className="w-full flex flex-row items-center justify-center rounded-full py-2"
          >
            Voir plus
          </BaseButton.Contained>
        )}
        <BottomSheetOrderOptionsBusiness
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          order={currentOrder}
        />
        <BottomSheetOrderStatusOptionsBusiness
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          order={currentOrder}
          selected={currentOrder?.status}
        />
      </div>
    </LoaderPortal>
  );
};
