import { useGoogleLogin } from "@react-oauth/google";
import BaseButton from "../base/BaseButton";
import { useDispatch } from "react-redux";
import { loginGoogleUserAsync } from "shared/redux/user.slice";
import { useContext, useState } from "react";
import { AuthModalContext } from "context";

export default () => {
  const [__, setIsAuthBottomSheetOpen] = useContext(AuthModalContext);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onGoogleLoginSuccess = async (tokenResponse) => {
    try {
      setLoading(true);
      await dispatch(
        loginGoogleUserAsync({
          body: tokenResponse,
          query: {
            variant: "internal",
          },
        })
      ).unwrap();
      setIsAuthBottomSheetOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
  });

  return (
    <BaseButton.Contained
      secondary
      className="w-full rounded-full _shadowInput"
      onClick={handleLogin}
      loading={loading}
      icon="hugeicons:google"
      iconSize={28}
    >
      Se connecter avec Google
    </BaseButton.Contained>
  );
};
