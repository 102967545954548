import _ from "lodash";
import { useEffect, useState } from "react";
import classnames from "classnames";
import BaseButton from "shared/components/shared/base/BaseButton";
import { TitledContent } from "shared/components/shared/core/Container";
import { getBusinessTablesService } from "shared/services/business.service";

export default ({ title, business, onConfirm, defaultSelected }) => {
  const [loading, setLoading] = useState(false);
  const [businessTables, setBusinessTables] = useState([]);

  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setLoading(true);
      console.log("gonnaload");

      const { data } = await getBusinessTablesService({ id: business?.id });
      setBusinessTables(
        _.filter(
          data,
          (item) =>
            !item?.isReserved || (item.isReserved && item.id === selected?.id)
        )
      );
      console.log("gonnaload");
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onItemClick = (item) => {
    if (item.isReserved && item.id !== defaultSelected?.id) {
      return;
    }
    if (item?.order?.length > 0) {
      return;
    }
    setSelected(item);
  };

  return (
    <div className="w-full h-auto min-h-full bg-background-main px-4 pt-4 pb-[100px]">
      <TitledContent
        title={title}
        titleClassName="w-full h-auto text-center mb-8"
      >
        {businessTables?.length > 0 ? (
          <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-5 gap-x-2 gap-y-4">
            {_.map(businessTables, (item, index) => (
              <div
                className={classnames(
                  "w-full h-[100px] flex flex-col items-center justify-center rounded-2xl hover:bg-primary-main cursor-pointer",
                  {
                    _glassResponsivePerf:
                      item?.isReserved === false && item.id !== selected?.id,
                    "bg-primary-main": item?.id === selected?.id,
                  }
                )}
                onClick={() => onItemClick(item)}
                key={index}
              >
                <div className="text-white text-xl">{item?.table}</div>
                {item?.isReserved && item?.id === defaultSelected?.id ? (
                  <span className="text-md">{"( Votre table )"}</span>
                ) : (
                  item?.isReserved && (
                    <span className="text-md">{"( Réservé )"}</span>
                  )
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full text-center">Pas de tables disponible</div>
        )}
      </TitledContent>
      <div className="w-full h-auto bg-background-main flex justify-center absolute bottom-0 left-0 px-4 py-4">
        <BaseButton.Contained
          primary
          icon="hugeicons:checkmark-circle-01"
          onClick={() => onConfirm(selected)}
          className="w-full h-auto max-w-[350px] flex flex-row items-center justify-center rounded-full py-2"
          disabled={!selected}
        >
          Confirmer
        </BaseButton.Contained>
      </div>
    </div>
  );
};
