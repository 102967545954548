import _ from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import { tailwindTheme } from "shared/constants/theme.constant";

export const description = "A multiple bar chart";

// const chartData = [
//   { month: "January", desktop: 186, mobile: 80 },
//   { month: "February", desktop: 305, mobile: 200 },
//   { month: "March", desktop: 237, mobile: 120 },
//   { month: "April", desktop: 73, mobile: 190 },
//   { month: "May", desktop: 209, mobile: 130 },
//   { month: "June", desktop: 214, mobile: 140 },
//   { month: "July", desktop: 186, mobile: 80 },
//   { month: "August", desktop: 305, mobile: 200 },
//   { month: "September", desktop: 237, mobile: 120 },
//   { month: "October", desktop: 73, mobile: 190 },
//   { month: "November", desktop: 209, mobile: 130 },
//   { month: "December", desktop: 214, mobile: 140 },
// ];

const Label = (props) => {
  const { x, y, value } = props;

  return (
    <text
      x={x}
      y={y}
      dx={"2%"}
      dy={"-1%"}
      fontSize="15"
      fontWeight="bold"
      fill={"white"}
      textAnchor="left"
    >
      {value}
    </text>
  );
};

export default ({ chartData, bars, xAccessDataKey }) => {
  return (
    <div className="w-full h-[400px]">
      <ResponsiveContainer>
        <BarChart accessibilityLayer data={chartData}>
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis
            dataKey={xAccessDataKey}
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          {_.map(bars, (bar, index) => (
            <Bar key={index} {...bar} radius={4} label={<Label />} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
