import { getDuration } from "shared/utils/app.utils";
import _ from "lodash";
import { tailwindTheme } from "shared/constants/theme.constant";
import { useEffect, useState } from "react";
import OrderCommandNumber from "./shared/OrderCommandNumber";
import OrderStatusText from "./shared/OrderStatusText";
import OrderFoodItem from "./shared/OrderFoodItem";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import BaseImage from "../shared/base/BaseImage";
import BaseButton from "../shared/base/BaseButton";
import { orderStatusList } from "shared/constants/order.constant";
import { useDispatch } from "react-redux";
import {
  patchOrderAsync,
  syncOrdersStatusChange,
} from "shared/redux/order.slice";

export const Order = ({
  data,
  onMoreClick,
  onStatusClick = () => {},
  onBusinessClick,
  variant = "public",
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();

  const handleOnBusinessClick = () => {
    if (onBusinessClick) {
      onBusinessClick(data);
    } else {
      navigate(`/${data?.business?.slug}`);
    }
  };

  return (
    <div className="w-full h-auto rounded-xl _glassResponsivePerf px-4 py-4">
      <OrderHeader
        data={data}
        onShrinkClick={() => setCollapsed((prev) => !prev)}
        onBusinessClick={handleOnBusinessClick}
        onMoreClick={onMoreClick}
        onStatusClick={onStatusClick}
        variant={variant}
        collapsed={collapsed}
      />
      {collapsed ? (
        <CollapsedOrder data={data} variant={variant} />
      ) : (
        <ShrunkOrder data={data} variant={variant} />
      )}
    </div>
  );
};

const OrderHeader = ({
  data,
  onBusinessClick,
  onShrinkClick,
  onStatusClick,
  onMoreClick,
  collapsed,
  variant,
}) => {
  return (
    <div className="w-full h-[42px] flex flex-row items-center justify-between border-b-[1px] border-custom-border-main pb-4 mb-4">
      <div className="grow pr-6">
        {variant === "business" ? (
          <OrderActionButton data={data} />
        ) : (
          <div className="flex items-center justify-start">
            <BaseImage
              src={data?.business?.avatar}
              className="w-[35px] h-[35px] border-[1px] border-background-light-0 rounded-full mr-2"
            />
            <div className="text-lg" onClick={onBusinessClick}>
              {data?.business?.name}
            </div>
          </div>
        )}
      </div>
      <div className="w-[100px] flex flex-row items-center justify-between">
        <Icon
          icon={collapsed ? "hugeicons:arrow-up-03" : "hugeicons:arrow-down-03"}
          fontSize={24}
          color={tailwindTheme.colors.custom.icon.main}
          onClick={onShrinkClick}
          className="cursor-pointer mr-4"
        />
        {variant === "business" && (
          <Icon
            icon="hugeicons:time-schedule"
            fontSize={24}
            color={tailwindTheme.colors.custom.icon.main}
            onClick={onStatusClick}
            className="cursor-pointer mr-4"
          />
        )}
        <Icon
          icon="hugeicons:more-horizontal-circle-02"
          fontSize={24}
          color={tailwindTheme.colors.custom.icon.main}
          onClick={onMoreClick}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

const CollapsedOrder = ({ data }) => {
  const navigate = useNavigate();

  const onViewDetailsClick = () => {
    navigate(`/order/${data?.id}`);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between w-full h-auto mb-8">
        <OrderCommandNumber data={data} />
        <div
          className="text-primary-main cursor-pointer"
          onClick={onViewDetailsClick}
        >
          Plus de détails
        </div>
      </div>
      {_.map(data?.selections, (item, index) => (
        <OrderFoodItem data={item} key={index} />
      ))}

      <div className="w-full h-auto flex flex-row items-center justify-between">
        <div>{getDuration(data?.createdAt)}</div>
        <OrderStatusText data={data} />
      </div>
    </>
  );
};

const ShrunkOrder = ({ data }) => {
  return (
    <>
      <div className="w-full h-[42px] flex flex-row items-center justify-between pt-4">
        <div>{getDuration(data?.createdAt)}</div>
        <OrderStatusText data={data} />
      </div>
    </>
  );
};

const OrderActionButton = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [toNextLabel, setToNextLabel] = useState();
  const [status, setStatus] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const index = _.findIndex(
      orderStatusList,
      (it) => it.value === data?.status
    );

    if (index < orderStatusList?.length - 1) {
      setStatus(orderStatusList[index + 1]);
      setToNextLabel(orderStatusList[index].toNextLabel);
    } else {
      setStatus();
      setToNextLabel();
    }
  }, [data?.status]);

  const onClick = async () => {
    const newOrder = { ...data, status: status.value };

    try {
      setLoading(true);
      await dispatch(
        patchOrderAsync({
          id: data?.id,
          body: {
            status: newOrder.status,
          },
        })
      ).unwrap();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (!status) {
    return <></>;
  }

  return (
    <BaseButton.Contained
      secondary
      className="rounded-2xl w-full px-2 py-2"
      icon={status.icon}
      onClick={onClick}
      loading={loading}
    >
      {toNextLabel}
    </BaseButton.Contained>
  );
};

{
  /* <div className="w-full h-[1px] bg-custom-border-main" /> */
}
