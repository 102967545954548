import BaseButton from "shared/components/shared/base/BaseButton";
import BaseInput from "shared/components/shared/base/BaseInput";
import { LayoutContentContainer } from "shared/components/shared/core/Container";
import HeaderBack from "shared/components/shared/core/HeaderBack";

export default () => {
  return (
    <LayoutContentContainer>
      <HeaderBack title="Edit profile" />
      <div className="w-full h-[400px] flex flex-col items-center justify-center">
        <div className="w-full h-[100px] flex flex-col items-center justify-between">
          <BaseInput placeholder="Enter new name" />
          <BaseButton.Contained primary className="w-full h-auto rounded-md">
            Save
          </BaseButton.Contained>
        </div>
      </div>
    </LayoutContentContainer>
  );
};
