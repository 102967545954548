import { WithResponsiveRightContentContainer } from "business/components/shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import {
  LayoutContainer,
  LayoutContentContainer,
} from "shared/components/shared/core/Container";
import { ErrorMessagePage } from "shared/components/shared/core/Error";

const BusinessManageTamplates = () => {
  return (
    <LayoutContentContainer>
      <ErrorMessagePage message="En cours de construction" />
    </LayoutContentContainer>
  );
};

const AuthorizedBusinessManageTamplates = () => <BusinessManageTamplates />;

export default WithResponsiveRightContentContainer(
  AuthorizedBusinessManageTamplates,
  {
    breadCrumbItems: [
      tabsBreadcrumbs.business,
      tabsBreadcrumbs.businessManageTemplate,
    ],
  }
);
