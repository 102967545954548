import {
  TitledContentSkeleton,
  TitledViewAllContent,
} from "shared/components/shared/core/Container";
import HorizontalScroll, {
  HorizontalScrollWithChildren,
} from "shared/components/shared/core/HorizontalScroll";
import { BusinessCard, BusinessCardSkeleton } from "./shared/Business";
import { useSelector } from "react-redux";
import _ from "lodash";
import withGuard from "shared/components/shared/utils/withGuard";

const SlidesLoader = () => (
  <TitledContentSkeleton className="px-4">
    <HorizontalScroll items={_.range(5)} component={BusinessCardSkeleton} />
  </TitledContentSkeleton>
);

const FetchedBusinessRestaurantSlides = () => {
  const { data } = useSelector((state) => state.business.home);

  if (!data?.restaurants) {
    return <></>;
  }

  return (
    <TitledViewAllContent
      title="Restaurants"
      viewAllNavigatePath="/explore?filter=restaurants"
      className="mb-6 pl-4"
      headerClassName="pr-4"
    >
      <HorizontalScrollWithChildren>
        {_.map(data?.restaurants, (item, index) => (
          <BusinessCard data={item} key={index} className="mr-2" />
        ))}
      </HorizontalScrollWithChildren>
    </TitledViewAllContent>
  );
};

const FetchedBusinessCoffeeShopsSlides = () => {
  const { data } = useSelector((state) => state.business.home);

  if (!data?.coffeeShops) {
    return <></>;
  }

  return (
    <TitledViewAllContent
      title="Coffee shops"
      viewAllNavigatePath="/explore?filter=coffeeShops"
      className="mb-6 pl-4"
      headerClassName="pr-4"
    >
      <HorizontalScrollWithChildren>
        {_.map(data?.coffeeShops, (item, index) => (
          <BusinessCard data={item} key={index} className="mr-2" />
        ))}
      </HorizontalScrollWithChildren>
    </TitledViewAllContent>
  );
};

const FetchedBusinessBarsSlides = () => {
  const { data } = useSelector((state) => state.business.home);

  if (!data?.bars) {
    return <></>;
  }

  return (
    <TitledViewAllContent
      title="Bars"
      viewAllNavigatePath="/explore?filter=bars"
      className="mb-6 pl-4"
      headerClassName="pr-4"
    >
      <HorizontalScrollWithChildren>
        {_.map(data?.bars, (item, index) => (
          <BusinessCard data={item} key={index} className="mr-2" />
        ))}
      </HorizontalScrollWithChildren>
    </TitledViewAllContent>
  );
};

export const BusinessRestaurantSlides = withGuard(
  FetchedBusinessRestaurantSlides,
  {
    store: "business.home",
    loader: <SlidesLoader />,
  }
);

export const BusinessCoffeeShopsSlides = withGuard(
  FetchedBusinessCoffeeShopsSlides,
  {
    store: "business.home",
    loader: <SlidesLoader />,
  }
);

export const BusinessBarsSlides = withGuard(FetchedBusinessBarsSlides, {
  store: "business.home",
  loader: <SlidesLoader />,
});
