import { useCallback, useEffect, useMemo, useState } from "react";

export default () => {
  const [y, setY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(
    "you have not scrolled yet"
  );

  const handleNavigation = useCallback(
    (e) => {
      if (y > window.scrollY) {
        setScrollDirection("up");
      } else if (y < window.scrollY) {
        setScrollDirection("down");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return useMemo(() => ({ y, scrollDirection }), [y, scrollDirection]);
};
