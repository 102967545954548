import classnames from "classnames";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  createOrderAsync,
  mergeOrderNodeData,
  setOrderInit,
  setOrderNodeFieldData,
} from "shared/redux/order.slice";
import { useNavigate } from "react-router-dom";
import useBaseSelector from "shared/hooks/useBaseSelector";
import BusinessCart from "shared/components/order/cart/BusinessCart";
import { useEffect } from "react";

export default () => {
  const business = useBaseSelector("business.item.data");
  const businessTable = useBaseSelector("order.cart.businessTable");
  const selections = useBaseSelector("order.cart.selections");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      mergeOrderNodeData({
        node: "cart",
        data: {
          businessTable,
          business,
        },
      })
    );
  }, []);

  const onSubmit = () => {
    const payload = {
      businessId: business?.id,
      businessTableId: businessTable?.id,
      selections: selections.map((it) => ({
        units: it.units,
        businessFoodId: it.id,
        excludeIngredientsIds: _.map(it?.food?.excludeIngredients, "id"),
        addSupplementsIds: _.map(it?.food?.addSupplements, "id"),
      })),
    };

    dispatch(
      createOrderAsync({
        payload: {
          body: payload,
          query: {
            variant: "business",
          },
        },
        options: {
          onSuccess: (data) => {
            navigate(`/`);
            dispatch(setOrderInit("cart"));
          },
        },
      })
    );
  };

  return (
    <BusinessCart
      onSubmit={onSubmit}
      variant="cart"
      isAddButtonVisible={false}
      data={{
        business,
        businessTable,
        selections,
      }}
    />
  );
};
