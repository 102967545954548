import { createSlice } from "@reduxjs/toolkit";
import {
  createBusinessService,
  getBusinessBySlugService,
  getBusinessService,
  getBusinessListService,
  getBusinessHomeService,
  getBusinessUsersService,
  getWaitingBusinessUsersService,
  createBusinessUsersService,
  updateBusinessUsersService,
  getBusinessTablesService,
  createBusinessFoodService,
  updateBusinessFoodService,
  deleteBusinessFoodService,
  updateBusinessService,
} from "shared/services/business.service";
import reduxUtils from "./utils/redux.utils";
import {
  createNodeThunkMiddleware,
  createThunkMiddleware,
} from "./utils/thunk.utils";
import {
  deleteBusinessFoodCaseHandler,
  updateBusinessFoodCaseHandler,
} from "./reducerHandlers/businessReducerHandler";

const managementActions = reduxUtils.createManagementActions("/business");

const nodeThunkMiddleware = createNodeThunkMiddleware(managementActions);

export const getBusinessHomeAsync = nodeThunkMiddleware(
  "/business/home",
  getBusinessHomeService,
  "home"
);

export const getBusinessListAsync = nodeThunkMiddleware(
  "/business/list",
  getBusinessListService,
  "list"
);

export const updateBusinessAsync = nodeThunkMiddleware(
  "/business/update",
  updateBusinessService,
  "form"
);

export const getBusinessBySlugAsync = nodeThunkMiddleware(
  "/business/item/bySlug",
  getBusinessBySlugService,
  "item"
);

export const getBusinessAsync = nodeThunkMiddleware(
  "/business/item",
  getBusinessService,
  "item"
);

export const getBusinessUsersAsync = nodeThunkMiddleware(
  "/business/user/list",
  getBusinessUsersService,
  "users"
);

export const getBusinessTablesAsync = createThunkMiddleware(
  "/business/table/list",
  getBusinessTablesService
);

export const getWaitingBusinessUsersAsync = nodeThunkMiddleware(
  "/business/user/waiting",
  getWaitingBusinessUsersService,
  "waitingUsers"
);

export const createBusinessAsync = nodeThunkMiddleware(
  "/business/form",
  createBusinessService,
  "form"
);

export const createBusinessUserAsync = nodeThunkMiddleware(
  "/business/user/create",
  createBusinessUsersService,
  "form"
);

export const updateBusinessUserAsync = nodeThunkMiddleware(
  "/business/user/update",
  updateBusinessUsersService,
  "form"
);

export const createBusinessFoodAsync = nodeThunkMiddleware(
  "/business/food/create",
  createBusinessFoodService,
  "form"
);

export const updateBusinessFoodAsync = nodeThunkMiddleware(
  "/business/food/update",
  updateBusinessFoodService,
  "form"
);

export const deleteBusinessFoodAsync = nodeThunkMiddleware(
  "/business/food/delete",
  deleteBusinessFoodService,
  "form"
);

const initialState = {
  home: {
    loading: false,
    error: undefined,
    data: undefined,
  },
  list: {
    hasMore: true,
    loading: false,
    data: {
      total: 0,
      data: [],
    },
  },
  item: {
    loading: false,
    error: false,
    data: undefined,
    businessTable: undefined,
  },
  users: {
    loading: false,
    data: undefined,
  },
  waitingUsers: {
    loading: false,
    data: undefined,
  },
  form: {
    loading: false,
    error: false,
    data: undefined,
  },
};

const slice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reduxUtils.tookit
      .buildManagementCases(builder, managementActions, initialState)
      .addCase(
        getBusinessHomeAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("home.data")
      )
      .addCase(
        getBusinessListAsync.fulfilled,
        reduxUtils.hof.onRootState.list.wrappedListHandler("list")
      )
      .addCase(
        getBusinessBySlugAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("item.data")
      )
      .addCase(
        getBusinessAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("item.data")
      )
      .addCase(
        createBusinessAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("form.data")
      )
      .addCase(updateBusinessFoodAsync.fulfilled, updateBusinessFoodCaseHandler)
      .addCase(deleteBusinessFoodAsync.fulfilled, deleteBusinessFoodCaseHandler)
      .addCase(
        getBusinessUsersAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("users.data")
      )
      .addCase(
        getWaitingBusinessUsersAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk(
          "waitingUsers.data"
        )
      );
  },
});

export const setBusinessNodeFieldData = managementActions.setNodeFieldData;
export const mergeBusinessNodeData = managementActions.mergeNodeData;
export const setBusinessInit = managementActions.setInit;

export const businessReducer = slice.reducer;
