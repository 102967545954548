import classnames from "classnames";
import { tailwindTheme } from "shared/constants/theme.constant";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify";
import FullHeartIcon from "../icons/FullHeartIcon";

export const Tag = ({
  data,
  onClick,
  className,
  selected: controlledSelected,
  changeOnClick = true,
  defaultIcon,
  selectedIcon,
}) => {
  const [selected, setSelected] = useState(controlledSelected);

  const onTagClick = () => {
    if (changeOnClick) {
      setSelected((prev) => !prev);
    }
    onClick?.(data);
  };

  useEffect(() => {
    setSelected(controlledSelected);
  }, [controlledSelected]);

  return (
    <div
      onClick={onTagClick}
      className={classnames(
        "w-auto rounded-full border-custom-tag-defaultBorder border-[1px] cursor-pointer px-8 py-3",
        className,
        {
          "bg-custom-tag-selectedBackground border-none": selected,
          "bg-custom-tag-defaultBackground": !selected,
        }
      )}
    >
      {defaultIcon ? (
        <div className="flex flex-row items-center justify-center">
          <div className="mr-1">{selected ? selectedIcon : defaultIcon}</div>
          <div className="font-normal whitespace-nowrap text-custom-tag-defaultText text-sm">
            {data?.label}
          </div>
        </div>
      ) : (
        <div
          className={classnames(
            "font-normal text-center whitespace-nowrap text-custom-tag-selectedText text-sm",
            {}
          )}
        >
          {data?.label}
        </div>
      )}
    </div>
  );
};

export const HeartTag = ({
  selected: controlledSelected,
  onClick,
  changeOnClick = true,
  className,
}) => {
  const [selected, setSelected] = useState(controlledSelected);

  const onTagClick = () => {
    if (changeOnClick) {
      setSelected((prev) => !prev);
    }
    onClick?.();
  };

  useEffect(() => {
    setSelected(controlledSelected);
  }, [controlledSelected]);

  return (
    <div onClick={onTagClick} className={className}>
      {selected ? (
        <FullHeartIcon
          fontSize={26}
          color={tailwindTheme.colors.custom.tag.selectedBackground}
        />
      ) : (
        <Icon
          icon="hugeicons:favourite"
          fontSize={26}
          color="white"
          fill="currentColor"
          stroke="currentColor"
        />
      )}
    </div>
  );
};
