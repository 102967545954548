export const businessUserRoleViewMap = {
  waiter: "Serveur",
  admin: "Admin",
  owner: "Propriétaire",
  cashier: "Caissier",
  chef: "Chef",
};

export const businessUserRolesOptions = [
  {
    icon: "hugeicons:user-shield-01",
    label: businessUserRoleViewMap["admin"],
    value: "admin",
  },
  {
    icon: "hugeicons:user-sharing",
    label: businessUserRoleViewMap["waiter"],
    value: "waiter",
  },
  {
    icon: "hugeicons:cashier",
    label: businessUserRoleViewMap["cashier"],
    value: "cashier",
  },
  {
    icon: "hugeicons:chef-hat",
    label: businessUserRoleViewMap["chef"],
    value: "chef",
  },
];

export const businessUserRoles = {
  waiter: "waiter",
  admin: "admin",
  owner: "owner",
  cashier: "cashier",
  chef: "chef",
};

export const businessTypesViewMap = {
  restaurant: "Restaurant",
  coffeeShop: "Café",
  mixed: "Restaurant Café",
  bar: "Bar",
};

export const businessTypesOptions = [
  {
    label: businessTypesViewMap.restaurant,
    value: "restaurant",
  },
  {
    label: businessTypesViewMap.coffeeShop,
    value: "coffeeShop",
  },
  {
    label: businessTypesViewMap.mixed,
    value: "mixed",
  },
  {
    label: businessTypesViewMap.bar,
    value: "bar",
  },
];
