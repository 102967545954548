import BusinessMenuTab from "public/components/business/BusinessMenuTab";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import useBaseSelector from "../useBaseSelector";
import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";
import OrdersTabPublic from "public/components/order/OrdersTabPublic";
import BusinessCartTabPublic from "public/components/order/cart/BusinessCartTabPublic";

export default () => {
  const selections = useBaseSelector("order.cart.selections");

  const [size, setSize] = useState();

  useEffect(() => {
    setSize(_.sumBy(selections, "units"));
  }, [selections]);

  return useMemo(
    () => [
      {
        label: "Menu",
        value: "menu",
        component: <BusinessMenuTab />,
        defaultIcon: (
          <Icon
            fontSize={22}
            icon="hugeicons:menu-restaurant"
            color={tailwindTheme.colors.custom.tab.default}
          />
        ),
        selectedIcon: (
          <Icon
            fontSize={22}
            icon="hugeicons:menu-restaurant"
            color={tailwindTheme.colors.custom.tab.selected}
          />
        ),
      },
      {
        label: `Panier`,
        value: "cart",
        component: <BusinessCartTabPublic />,
        badgeNumber: size,
        defaultIcon: (
          <Icon
            fontSize={22}
            icon="hugeicons:shopping-basket-01"
            color={tailwindTheme.colors.custom.tab.default}
          />
        ),
        selectedIcon: (
          <Icon
            fontSize={22}
            icon="hugeicons:shopping-basket-01"
            color={tailwindTheme.colors.custom.tab.selected}
          />
        ),
      },
      {
        label: "Commandes",
        value: "orders",
        component: <OrdersTabPublic />,
        defaultIcon: (
          <Icon
            fontSize={22}
            icon="hugeicons:border-full"
            color={tailwindTheme.colors.custom.tab.default}
          />
        ),
        selectedIcon: (
          <Icon
            fontSize={22}
            icon="hugeicons:border-full"
            color={tailwindTheme.colors.custom.tab.selected}
          />
        ),
      },
    ],
    [size]
  );
};
