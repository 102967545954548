import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

const nodeThunkMiddleware = (action, asyncHandler, params = {}) =>
  createAsyncThunk(action, async (payload, thunkAPI) => {
    const { setLoading, setError, setIsLoaded } = params;
    let { node } = params;

    const extras = payload?.options ?? undefined;

    const apiPayload = extras ? payload?.payload : payload;

    if (_.isFunction(node)) {
      node = node(payload);
    }

    try {
      if (setLoading) {
        thunkAPI.dispatch(setLoading({ node, value: true }));
      }
      const data = (await asyncHandler(apiPayload, thunkAPI))?.data;

      if (payload?.options?.onSuccessSync) {
        payload?.options?.onSuccessSync(data);
      }

      if (payload?.options?.onSuccess) {
        new Promise((resolve) => {
          payload?.options?.onSuccess(data);
          resolve();
        });
      }

      if (setIsLoaded) {
        thunkAPI.dispatch(setIsLoaded({ node, value: true }));
      }

      return { data };
    } catch (error) {
      const errorData = error?.response?.data ?? error;
      if (setError) {
        thunkAPI.dispatch(setError({ node, value: errorData }));
      }

      if (payload?.options?.onError) {
        payload?.options?.onError(errorData);
      }

      console.log("thunk error", error);

      return thunkAPI.rejectWithValue(errorData);
    } finally {
      setTimeout(() => {
        if (setLoading) {
          thunkAPI.dispatch(setLoading({ node, value: false }));
        }
      }, 100);

      if (payload?.options?.finally) {
        payload?.options?.finally();
      }
    }
  });

const thunkMiddleware = (action, asyncHandler) =>
  createAsyncThunk(action, async (payload, thunkAPI) => {
    const extras = payload?.options;

    const apiPayload = extras ? payload?.payload : payload;

    try {
      if (payload?.options?.onLoading) {
        payload?.options?.onLoading(true);
      }
      const data = (await asyncHandler(apiPayload, thunkAPI))?.data;

      if (payload?.options?.onSuccessSync) {
        payload?.options?.onSuccessSync(data);
      }

      if (payload?.options?.onSuccess) {
        new Promise((resolve) => {
          payload?.options?.onSuccess(data);
          resolve();
        });
      }

      return { data };
    } catch (error) {
      if (payload?.options?.onError) {
        payload?.options?.onError();
      }

      return thunkAPI.rejectWithValue(error);
    } finally {
      if (payload?.options?.onLoading) {
        payload?.options?.onLoading(false);
      }
      if (payload?.options?.finally) {
        payload?.options?.finally();
      }
    }
  });

export const createNodeThunkMiddleware = ({
  setLoading,
  setError,
  setIsLoaded,
}) => {
  return (action, asyncHandler, node) =>
    nodeThunkMiddleware(action, asyncHandler, {
      setLoading,
      setError,
      setIsLoaded,
      node,
    });
};
export const createThunkMiddleware = (action, asyncHandler) =>
  thunkMiddleware(action, asyncHandler);
