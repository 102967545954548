import {
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import OrdersStatusList from "./OrdersStatusList";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";

const OrdersChefTabBusiness = () => {
  return (
    <LayoutContentContainer className="w-full h-auto flex flex-row items-start justify-between">
      <TitledContent className="w-full h-auto pr-2" title="En attente">
        <OrdersStatusList status="waiting" />
      </TitledContent>
      <TitledContent className="w-full h-auto pl-2 pr-2" title="En cours">
        <OrdersStatusList status="inProgress" />
      </TitledContent>
      <TitledContent className="w-full h-auto pl-2" title="Prét">
        <OrdersStatusList status="ready" />
      </TitledContent>
    </LayoutContentContainer>
  );
};

export default () => (
  <RightContentTabletContainer
    breadCrumbItems={[tabsBreadcrumbs.orderChef]}
    firstContentMaxWidth={false}
  >
    <OrdersChefTabBusiness />
  </RightContentTabletContainer>
);
