import { PageNotFound } from "shared/components/shared/core/Error";
import BusinessPage from "public/components/business/BusinessPage";
import ExploreTab from "public/components/explore/ExploreTab";
import FavoritesTab from "public/components/favorite/FavoritesTab";
import HomeTab from "public/components/home/HomeTab";
import NotificationPage from "shared/components/notification/NotificationPage";
import UserProfileTab from "shared/components/user/UserProfileTab";
import UserProfileEdition from "shared/components/user/UserProfileEdition";
import AuthPublic from "shared/components/user/AuthPublic";
import { Navigate } from "react-router-dom";
import PublicOrdersTab from "public/components/order/OrdersTabPublic";
import OrderEditPage from "shared/components/order/shared/OrderEditPage";
import AboutPage from "shared/components/shared/core/AboutPage";
import OrderPagePublic from "public/components/order/OrderPagePublic";
import BusinessCartTabPublic from "public/components/order/cart/BusinessCartTabPublic";
import UserEmailVerifyPage from "shared/components/shared/core/UserEmailVerifyPage";

export default [
  {
    path: "/",
    element: <HomeTab />,
    exact: true,
  },
  {
    path: "/explore",
    element: <ExploreTab />,
    exact: true,
  },
  {
    path: "/favorite",
    element: <FavoritesTab />,
    exact: true,
  },
  {
    path: "/cart",
    element: <BusinessCartTabPublic />,
    exact: true,
  },
  {
    path: "/notification",
    element: <Navigate to="/notification/main" replace />,
    exact: true,
  },
  {
    path: "/notification/main",
    element: <NotificationPage />,
    exact: true,
  },
  {
    path: "/notification/system",
    element: <NotificationPage />,
    exact: true,
  },
  {
    path: "/order",
    element: <PublicOrdersTab />,
    exact: true,
  },
  {
    path: "/order/:id/edit",
    element: <OrderEditPage />,
    exact: true,
  },
  {
    path: "/order/:id",
    element: <OrderPagePublic />,
    exact: true,
  },
  {
    path: "/me",
    element: <UserProfileTab />,
    exact: true,
  },
  {
    path: "/me/auth",
    element: <AuthPublic />,
    exact: true,
  },
  {
    path: "/me/edit",
    element: <UserProfileEdition />,
    exact: true,
  },
  {
    path: "/:businessSlug/:table?",
    element: <BusinessPage />,
    exact: true,
  },
  {
    path: "/about",
    element: <AboutPage />,
    exact: true,
  },
  {
    path: "/verify-email/:token",
    element: <UserEmailVerifyPage />,
    exact: true,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
