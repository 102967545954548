import { createAction } from "redux-actions";
import _ from "lodash";
import moment from "moment";

const setIsLoaded = (state, action) => {
  state[action?.payload?.node].isLoaded = action.payload.value;
};

const setLoading = (state, action) => {
  state[action?.payload?.node].loading = action.payload.value;
};

const setError = (state, action) => {
  state[action?.payload?.node].error = action.payload.value;
};

const setInit = (init) => (state, action) => {
  state[action.payload] = init[action.payload];
};

const setNodeData = (state, action) => {
  state[action.payload?.node] = action.payload.data;
};

const buildDefaultReducers = (init) => ({
  setLoading,
  setError,
  setInit: setInit(init),
});

const createManagementActions = (action) => ({
  setIsLoaded: createAction(`${action}/setIsLoaded`),
  setLoading: createAction(`${action}/setLoading`),
  setError: createAction(`${action}/setError`),
  setInit: createAction(`${action}/setInit`),
  setNodeFieldData: createAction(`${action}/setNodeFieldData`),
  setNodeData: createAction(`${action}/setNodeData`),
  mergeNodeData: createAction(`${action}/mergeNodeFieldData`),
  updateListWithPath: createAction(`${action}/updateList`),
});

//all list apis should have count & total and data should be in field data
const wrappedListHandler = (node, filter) => (state, action) => {
  
  const reset = action?.meta?.arg?.options?.reducerOptions?.reset === true;

  let response = action?.payload?.data;

  if (filter) {
    response = response?.filter(filter);
  }

  let hasMore = state[node]?.hasMore || true;
  let data;

  if (reset) {
    hasMore = true;
    data = response;
  } else {
    data = {
      total: response?.total,
      count: response?.count,
      data: [...state[node]?.data?.data, ...response?.data],
    };
  }

  if (response?.data?.length === 0) {
    hasMore = false;
  }

  //loading is handled automatically by thunk middleware
  state[node].hasMore = hasMore;
  state[node].data = data;
};

const listServices = {
  findItemAndUpdate: (list, item) => {
    const index = list?.findIndex((it) => it.id === item.id);
    if (index > -1) {
      list?.splice(index, 1, item);
      return true;
    }
    return false;
  },
  popItemIfExists: (list, item) => {
    const index = list?.findIndex((it) => it.id === item.id);
    if (index > -1) {
      list?.splice(index, 1);
      return true;
    }
    return false;
  },
  pushItemIfNotExists: (list, item) => {
    const index = list?.findIndex((it) => it.id === item.id);
    if (index > -1) {
      return false;
    }

    list?.push(item);
    return true;
  },
  unshiftItemIfNotExists: (list, item) => {
    const index = list?.findIndex((it) => it.id === item.id);
    if (index > -1) {
      return false;
    }

    list?.unshift(item);
    return true;
  },
};

const settersServices = {
  setWithPathFromThunk: (state, statePath, payload) => {
    _.set(state, statePath, payload.data);
  },
  setNodeFieldDataFromThunk: (state, node, payload) => {
    _.set(state, `${node}.data`, payload.data);
  },
};

const onRootStateFn = {
  setNodeData: (state, action) => {
    const { node, data } = action.payload;
    state[node] = data;
  },
  mergeNodeData: (state, action) => {
    const { node, data } = action.payload;
    _.keys(data).forEach((key) => {
      state[node][key] = data[key];
    });
  },
  setNodeFieldData: (state, action) => {
    const { node, data } = action.payload;
    state[node].data = data;
  },
  updateListWithPath: (state, action) => {
    const { path, operation, data, sort, upsert } = action.payload;
    let list = _.get(state, path);

    const handler = _.get(
      {
        create: listServices.pushItemIfNotExists,
        update: listServices.findItemAndUpdate,
        delete: listServices.popItemIfExists,
      },
      operation
    );

    const success = handler(list, data);

    if (!success && operation === "update" && upsert === true) {
      listServices.pushItemIfNotExists(list, data);
    }

    if (sort) {
      sort.field = sort?.field || "createdAt";
      sort.type = sort?.type || "asc";

      list = _.orderBy(
        list,
        (it) => moment(it[sort?.field]).toDate(),
        sort?.type
      );
    }

    _.set(state, path, list);
  },
};

const onRootStateSetterHOF = {
  setFieldDataWithValue: (node, value) => (state, action) => {
    state[node].data = value;
  },
  setWithPath: (statePath, dataPath) => (state, action) => {
    if (dataPath) {
      action.payload = _.get(action.payload, dataPath);
    }
    _.set(state, statePath, action.payload);
  },
  setWithPathWithValue: (statePath, value) => (state, action) => {
    _.set(state, statePath, value);
  },
  setWithPathFromThunk: (statePath) => (state, action) => {
    _.set(state, statePath, action.payload?.data);
  },
  setFieldData: (node) => (state, action) => {
    state[node].data = action.payload;
  },
  setFieldDataFromThunk: (node) => (state, action) => {
    state[node].data = action.payload.data;
  },
  merge: (node) => (state, action) => {
    _.keys(action.payload).forEach((key) => {
      state[node][key] = action.payload[key];
    });
  },
};

const listItemHandler = {
  pushListItemIfNotExistsWithPathFromThunk: (statePath) => (state, action) => {
    const list = _.get(state, statePath);
    const index = list?.findIndex((it) => it?.id === action.payload?.data?.id);
    if (index > -1) {
      list.push(action.payload?.data);
      return true;
    }
    return false;
  },
  findItemAndUpdateWithPathFromThunk: (statePath) => (state, action) => {
    const list = _.get(state, statePath);
    const index = list?.findIndex((it) => it?.id === action.payload?.data?.id);
    if (index > -1) {
      list?.splice(index, 1, action.payload.data);
      return true;
    }
    return false;
  },
};

const buildManagementCases = (builder, managementActions, initialState) => {
  return builder
    .addCase(managementActions.setIsLoaded.toString(), setIsLoaded)
    .addCase(managementActions.setLoading.toString(), setLoading)
    .addCase(managementActions.setError.toString(), setError)
    .addCase(managementActions.setInit.toString(), setInit(initialState))
    .addCase(
      managementActions.setNodeFieldData.toString(),
      onRootStateFn.setNodeFieldData
    )
    .addCase(
      managementActions.setNodeData.toString(),
      onRootStateFn.setNodeData
    )
    .addCase(
      managementActions.mergeNodeData.toString(),
      onRootStateFn.mergeNodeData
    )
    .addCase(
      managementActions.updateListWithPath.toString(),
      onRootStateFn.updateListWithPath
    );
};

export default {
  setIsLoaded,
  setLoading,
  setError,
  setInit,
  setNodeData,
  createManagementActions,
  buildDefaultReducers,
  services: {
    list: listServices,
    setters: settersServices,
  },
  fn: {
    onRootState: onRootStateFn,
  },
  hof: {
    onRootState: {
      setter: onRootStateSetterHOF,
      list: {
        wrappedListHandler,
      },
      listItem: listItemHandler,
    },
    fromApi: {
      listItemHandler,
      wrappedListHandler,
    },
  },
  tookit: {
    buildManagementCases,
  },
};
