import BaseCard from "shared/components/shared/base/BaseCard";
import { tailwindTheme } from "shared/constants/theme.constant";
import BottomSheetListSelection from "../core/BottomSheetListSelection";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import _ from "lodash";

export default ({
  list,
  value,
  searchKeys = [],
  placeholder,
  onChange,
  leftIcon,
  rightIcon = "hugeicons:arrow-down-01",
  className,
  bottomSheetProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searched, setSearched] = useState(list);
  const [search, setSearch] = useState("");

  const [item, setItem] = useState(value);

  useEffect(() => {
    setSearched(list);
  }, [list]);

  useEffect(() => {
    setItem(value);
  }, [value]);

  const onSelect = (value) => {
    setItem(value);
    onChange?.(value);
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setSearched(
      _.filter(list, (it) => {
        for (const key of searchKeys) {
          if (it?.[key]?.indexOf(value) > -1) {
            return true;
          }
        }
        return false;
      })
    );
  };

  return (
    <>
      <div
        className={classNames(
          "w-full h-auto flex flex-row items-center justify-between rounded-full _glassResponsivePerf cursor-pointer px-4 py-2",
          className
        )}
        onClick={() => setIsOpen(true)}
      >
        <div className="flex flex-row items-center justify-start">
          <div className="mr-2">
            {leftIcon && (
              <Icon
                fontSize={24}
                icon={leftIcon}
                color={tailwindTheme.colors.custom.icon.main}
              />
            )}
          </div>
          {item ? (
            <div className="text-lg">{item?.label}</div>
          ) : (
            <div className="text-lg text-custom-text-sub">{placeholder}</div>
          )}
        </div>
        <div>
          {rightIcon && (
            <Icon
              fontSize={24}
              icon={rightIcon}
              color={tailwindTheme.colors.custom.icon.main}
            />
          )}
        </div>
      </div>
      <BottomSheetListSelection
        list={searched}
        searchable={true}
        onSearch={onSearch}
        search={search}
        onItemClick={onSelect}
        bottomSheetProps={{
          isOpen,
          onClose: () => setIsOpen(false),
          ...bottomSheetProps,
        }}
      />
    </>
  );
};
