import { LayoutContentContainer } from "shared/components/shared/core/Container";
import BusinessCartTabContentBusiness from "./BusinessCartTabContentBusiness";
import useBaseSelector from "shared/hooks/useBaseSelector";
import { ErrorMessagePage } from "shared/components/shared/core/Error";

const BusinessCartTabBusinessMobile = () => {
  const selections = useBaseSelector("order.cart.selections");

  if (selections?.length === 0) {
    return <ErrorMessagePage message="Panier vide" />;
  }

  return (
    <LayoutContentContainer>
      <BusinessCartTabContentBusiness />
    </LayoutContentContainer>
  );
};

export default BusinessCartTabBusinessMobile;
