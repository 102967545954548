import classnames from "classnames";

export default ({ data, className }) => {
  return (
    <div
      className={classnames(
        "flex flex-col justify-center w-auto h-auto p-2 absolute -top-2 -right-2 bg-primary-main rounded-full",
        className
      )}
    >
      <div className="flex flex-row items-center justify-start text-sm font-normal">
       <div className="mr-1">{data}</div>
        <span className="">TND</span>
      </div>
    </div>
  );
};
