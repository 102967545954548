import BaseButton from "shared/components/shared/base/BaseButton";
import { TitledContent } from "shared/components/shared/core/Container";
import _ from "lodash";
import { useState } from "react";
import BaseImage from "shared/components/shared/base/BaseImage";
import { tailwindTheme } from "shared/constants/theme.constant";
import { Icon } from "@iconify/react/dist/iconify";

export default ({
  title,
  data,
  onConfirm,
  selectedRightIcon,
  selectedRightIconSize = 18,
  defaultSelected = [],
}) => {
  const [selected, setSelected] = useState(defaultSelected);

  const onItemClick = (item) => {
    setSelected((prev) => {
      const update = [...prev];

      const existsIndex = _.findIndex(update, { id: item?.id });

      if (existsIndex > -1) {
        update.splice(existsIndex, 1);
      } else {
        update.push(item);
      }

      return update;
    });
  };
  return (
    <div className="w-full h-full min-h-full bg-background-main px-2 pt-4 pb-16">
      <TitledContent
        title={title}
        titleClassName="w-full h-auto text-center mb-8"
      >
        {_.map(data, (item, index) => (
          <div
            className=" w-full h-20 _glassResponsivePerf flex flex-row items-center justify-between pr-4 rounded-2xl _card-p-none mb-4"
            onClick={() => onItemClick(item)}
            key={index}
          >
            <div className="relative w-20 h-full mr-4 overflow-hidden">
              <BaseImage
                src={item?.image}
                alt={item?.name}
                className="rounded-l-2xl"
              />
            </div>
            <div className="flex-1 text-white">{item?.name}</div>
            <div className="w-4">
              {_.findIndex(selected, { id: item.id }) > -1 && (
                <Icon
                  icon={selectedRightIcon}
                  fontSize={selectedRightIconSize}
                  color={tailwindTheme.colors.custom.icon.main}
                />
              )}
            </div>
          </div>
        ))}
      </TitledContent>
      {data?.length > 0 && (
        <div className="w-full h-auto bg-background-main flex justify-center absolute bottom-0 left-0 px-4 py-4">
          <BaseButton.Contained
            primary
            icon="hugeicons:checkmark-circle-01"
            onClick={() => onConfirm(selected)}
            className="w-full h-auto max-w-[450px] flex flex-row items-center justify-center rounded-full py-2"
          >
            Confirmer
          </BaseButton.Contained>
        </div>
      )}
    </div>
  );
};
