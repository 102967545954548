import classnames from "classnames";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  handleOnCartItemAdd,
  handleOnCartItemDelete,
  handleOnCartItemExpand,
  handleOnCartItemMinus,
  handleUpdateCartSelectionExtras,
} from "shared/utils/cart.utils";
import BusinessCartContent from "shared/components/order/cart/BusinessCartContent";
import { mergeOrderNodeData } from "shared/redux/order.slice";

export default ({
  onSubmit,
  data,
  variant,
  isAddButtonVisible = false,
  className,
}) => {
  const dispatch = useDispatch();

  const onItemAddClick = (index) => {
    handleOnCartItemAdd(variant, data?.selections, index, dispatch);
  };

  const onItemMinusClick = (index) => {
    handleOnCartItemMinus(variant, data?.selections, index, dispatch);
  };

  const onItemExpandClick = (index) => {
    handleOnCartItemExpand(variant, data?.selections, index, dispatch);
  };

  const onItemDeleteClick = (index) => {
    handleOnCartItemDelete(variant, data?.selections, index, dispatch);
  };

  const onConfirmIngredients = (index, selected) => {
    handleUpdateCartSelectionExtras(
      variant,
      "food.excludeIngredients",
      data?.selections,
      index,
      selected,
      dispatch
    );
  };

  const onConfirmSupplements = (index, selected) => {
    handleUpdateCartSelectionExtras(
      variant,
      "food.addSupplements",
      data?.selections,
      index,
      selected,
      dispatch
    );
  };

  const onConfirmTable = (selected) => {
    dispatch(
      mergeOrderNodeData({
        node: variant,
        data: {
          businessTable: selected,
        },
      })
    );
  };

  return (
    <BusinessCartContent
      data={data}
      onItemAddClick={onItemAddClick}
      onItemMinusClick={onItemMinusClick}
      onItemExpandClick={onItemExpandClick}
      onItemDeleteClick={onItemDeleteClick}
      onConfirmIngredients={onConfirmIngredients}
      onConfirmSupplements={onConfirmSupplements}
      onConfirmTable={onConfirmTable}
      onSubmit={onSubmit}
      variant={variant}
      isAddButtonVisible={isAddButtonVisible}
      className={className}
    />
  );
};
