import {
  HeaderBackContainer,
  LayoutContentContainer,
} from "shared/components/shared/core/Container";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";
import Notifications from "./Notifications";
import NotificationHeader from "./shared/NotificationHeader";
import WithResponsive from "../shared/utils/WithResponsive";
import { RightContentTabletContainer } from "business/components/shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";

const Mobile = () => {
  const [currentRoute, setCurrentRoute] = useState();

  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(_.chain(location.pathname).split("/").nth(2).value());
  }, [location.pathname]);

  return (
    <HeaderBackContainer
      headerBackProps={{
        title: "Notifications",
        backLink: "/",
        marginBottom: false
      }}
      layoutContainerProps={{
        horizontalPadding: false,
        headerPadding: false,
      }}
    >
      <NotificationHeader route={currentRoute} />
      {currentRoute && <Notifications route={currentRoute} />}
    </HeaderBackContainer>
  );
};

const Tablet = () => {
  const [currentRoute, setCurrentRoute] = useState();

  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(_.chain(location.pathname).split("/").nth(2).value());
  }, [location.pathname]);

  return (
    <LayoutContentContainer>
      <NotificationHeader route={currentRoute} />
      {currentRoute && <Notifications route={currentRoute} />}
    </LayoutContentContainer>
  );
};

export default WithResponsive(Mobile, () => (
  <RightContentTabletContainer breadCrumbItems={[tabsBreadcrumbs.notification]}>
    <Tablet />
  </RightContentTabletContainer>
));
