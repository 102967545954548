import { Icon } from "@iconify/react";
import { BusinessOrderHeader } from "public/components/business/shared/BusinessHeader";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({ data, onMoreClick, noBusinessClick = false }) => {
  return (
    <>
      <BusinessOrderHeader data={data} noBusinessClick={noBusinessClick} />
      <Icon
        icon="hugeicons:more-horizontal-circle-02"
        fontSize={24}
        color={tailwindTheme.colors.custom.icon.main}
        onClick={onMoreClick}
        className="absolute top-4 right-4"
      />
    </>
  );
};
