import { IoIosClose } from "react-icons/io";
import Sheet from "react-modal-sheet";
import { tailwindTheme } from "shared/constants/theme.constant";
import BaseButton from "./BaseButton";
import classnames from "classnames";
import { useEffect, useState } from "react";

const BaseBottomSheet = ({
  isOpen,
  onClose,
  content,
  title,
  containerClassName,
  ...props
}) => {
  return (
    <Sheet isOpen={isOpen} onClose={onClose} {...props}>
      <Sheet.Container
        className={classnames(
          "lg:max-w-[450px] self-center lg:ml-[75px]",
          containerClassName
        )}
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Sheet.Header className="_glassResponsivePerf border-none rounded-t-3xl" />
        <Sheet.Content className="bg-background-main relative">
          <Sheet.Scroller>
            <div className="w-full">
              <IoIosClose
                size={30}
                color={tailwindTheme.colors.custom.icon.main}
                className="absolute left-6 -top-[34px]"
                onClick={onClose}
              />
              {title && (
                <div className="w-full h-[50px] flex flex-col items-center justify-center border-b-[1px] border-custom-border-main">
                  <div className="w-auto h-auto text-lg">{title}</div>
                </div>
              )}
              {content}
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export const BaseConfirmBottomSheet = ({
  component: Component,
  formInit,
  onConfirm,
  onClose,
  confirmButtonDisabled,
  contentClassName,
  ...props
}) => {
  const [form, setForm] = useState(formInit ?? {});

  useEffect(() => {
    setForm(formInit);
  }, [formInit]);

  const onConfirmClick = () => {
    onConfirm?.(form);
    onClose?.();
  };

  return (
    <BaseBottomSheet
      {...props}
      onClose={onClose}
      content={
        <div
          className={classnames(
            "w-full h-auto min-h-full bg-background-main px-4 pt-4",
            contentClassName
          )}
        >
          <Component form={form} setForm={setForm} />
          <div className="w-full h-auto bg-background-main flex items-center justify-center absolute bottom-0 left-0 px-4 py-2 z-20">
            <BaseButton.Contained
              primary
              icon="hugeicons:checkmark-circle-01"
              onClick={onConfirmClick}
              className="w-full h-auto max-w-[400px] self-center flex flex-row items-center justify-center rounded-full py-2"
              disabled={confirmButtonDisabled}
            >
              Confirmer
            </BaseButton.Contained>
          </div>
        </div>
      }
    />
  );
};

export default BaseBottomSheet;
