import _, { forEach } from "lodash";

export const updateBusinessFoodCaseHandler = (state, action) => {
  const data = action.payload.data;

  let item = _.chain(state.item.data.menu)
    .map("children")
    .flatten()
    .find((it) => it.id === data?.id)
    .set("info", data.info)
    .set("price", data.price)
    .set("image", data.image)
    .set("images", data.images)
    .value();
};

export const deleteBusinessFoodCaseHandler = (state, action) => {
  const id = action.meta.arg;

  console.log(id);

  for (const category of state.item.data.menu) {
    _.remove(category.children, (it) => it.id === id);
  }
};
