import { SearchContext } from "context";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSharedNodeData } from "shared/redux/shared.slice";

export default () => {
  const [search, setSearch] = useContext(SearchContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setSharedNodeData({
        node: "tabs",
        data: {
          showSearch: true,
        },
      })
    );

    return () => {
      dispatch(
        setSharedNodeData({
          node: "tabs",
          data: {
            showSearch: false,
          },
        })
      );
      setSearch("");
    };
  }, []);
};
