import PulseLoader from "react-spinners/PulseLoader";
import classnames from "classnames";
import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";

const Contained = ({
  primary,
  secondary,
  children,
  className,
  labelClassName,
  disabled,
  loading = false,
  icon,
  iconSize = 18,
  iconColor,
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={classnames(
        "min-h-[40px] flex flex-row items-center justify-center border-none",
        {
          "bg-gray-800": disabled,
          "bg-primary-main": !disabled && primary,
          "bg-secondary-main": !disabled && secondary,
        },
        className
      )}
    >
      {loading ? (
        <PulseLoader
          color={primary ? "white" : secondary ? "black" : "white"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="mr-2"
        />
      ) : (
        icon && (
          <Icon
            fontSize={iconSize}
            icon={icon}
            color={iconColor ?? primary ? "white" : "black"}
            className="mr-2"
          />
        )
      )}
      <span
        className={classnames(
          "text-lg",
          {
            "text-white": primary,
            "text-black": secondary,
          },
          labelClassName
        )}
      >
        {children}
      </span>
    </button>
  );
};

const Text = ({
  primary,
  secondary,
  underline,
  children,
  className,
  labelClassName,
  disabled,
  loading = false,
  icon,
  iconSize = 18,
  iconColor,
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={classnames(
        "min-h-[40px] flex flex-row items-center justify-start bg-transparent",
        {
          "border-b-[1px]": underline,
          "border-primary-main": underline && primary,
          "border-secondary-main": underline && secondary,
        },
        className
      )}
    >
      {loading ? (
        <PulseLoader
          color={
            primary
              ? tailwindTheme.colors.primary?.main
              : secondary
              ? tailwindTheme.colors.secondary?.main
              : "white"
          }
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="mr-2"
        />
      ) : (
        icon && (
          <Icon
            fontSize={iconSize}
            icon={icon}
            color={
              iconColor ?? primary
                ? tailwindTheme.colors.primary?.main
                : tailwindTheme.colors.secondary?.main
            }
            className="mr-2"
          />
        )
      )}
      <span
        className={classnames(
          "text-lg h-auto",
          {
            "text-primary-main": !disabled && primary,
            "text-secondary-main": !disabled && secondary,
            "text-gray-800": disabled,
          },
          labelClassName
        )}
      >
        {children}
      </span>
    </button>
  );
};

const BaseButton = {
  Contained,
  Text,
};

export default BaseButton;
