import axios from "axios";
import { api_url } from "shared/config/api.config";

const api = axios.create({
  baseURL: api_url,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  return config;
});

export default api;
