import { useEffect, useState } from "react";
import BaseBottomSheet from "../base/BaseBottomSheet";
import { TitledContent } from "./Container";
import _ from "lodash";
import { tailwindTheme } from "shared/constants/theme.constant";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import BaseInput from "../base/BaseInput";

export default ({
  searchable,
  onSearch,
  search,
  list,
  title,
  onItemClick,
  dynamicListHeight = true,
  selected = null,
  bottomSheetProps,
}) => {
  const [isOpen, setIsOpen] = useState(bottomSheetProps.isOpen ?? false);

  useEffect(() => {
    setIsOpen(bottomSheetProps.isOpen);
  }, [bottomSheetProps?.isOpen]);

  const onClose = () => {
    setIsOpen(false);
    bottomSheetProps?.onClose?.();
  };

  const handleOnItemClick = (item) => {
    onClose();
    onItemClick(item);
  };

  return (
    <BaseBottomSheet
      {...bottomSheetProps}
      isOpen={isOpen}
      onClose={onClose}
      {...(dynamicListHeight &&
        !searchable && {
          snapPoints: [Math.max(50 + 80 * list?.length + title ? 50 : 0, 400)],
        })}
      content={
        <ModalList
          searchable={searchable}
          onSearch={onSearch}
          search={search}
          list={list}
          title={title}
          onItemClick={handleOnItemClick}
          selected={selected}
        />
      }
    />
  );
};

const ModalList = ({
  list,
  searchable,
  onSearch,
  search,
  selected,
  onItemClick,
}) => {
  return (
    <div className="w-full h-auto pb-[50px]">
      {searchable && (
        <div className="w-full my-4 px-4">
          <BaseInput
            placeholder="Rechercher"
            className="rounded-lg"
            value={search}
            onChange={onSearch}
          />
        </div>
      )}
      {_.map(list, (item, index) => (
        <ListItem
          data={item}
          key={index}
          onClick={onItemClick}
          selected={selected === item?.value}
        />
      ))}
    </div>
  );
};

const ListItem = ({ data, selected, onClick }) => {
  return (
    <div
      className={classNames(
        "w-full h-[50px] flex flex-row items-center justify-start border-b-[1px] border-custom-border-main pl-[30px] cursor-pointer",
        {
          "hover:bg-primary-main": !selected,
          "bg-primary-main": selected,
        }
      )}
      onClick={() => onClick(data)}
    >
      {data?.icon && (
        <Icon
          icon={data?.icon}
          fontSize={22}
          color={tailwindTheme.colors.custom.icon.main}
          className="mr-2"
        />
      )}
      <div className="text-lg">{data.label}</div>
    </div>
  );
};
