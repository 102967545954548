import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import HorizontalScroll from "shared/components/shared/core/HorizontalScroll";
import { Tag } from "shared/components/shared/core/Tags";
import { notificationTabs } from "shared/constants/notification.constant";

export default ({ route }) => {
  const navigate = useNavigate();

  const onTabClick = (tab) => {
    if (tab.route != route) {
      navigate(`/notification/${tab.route}`);
    }
  };
  return (
    <HorizontalScroll
    items={notificationTabs}
      className="w-full  pr-[40px] pl-8 py-4"
      component={({ data: tab }) => (
        <Tag
          data={tab}
          onClick={onTabClick}
          selected={route === tab.route}
          className="rounded-full"
          changeOnClick={false}
        />
      )}
    />
  );
};
