import {
  HeaderBackContainer,
  LayoutContainer,
  LayoutContentContainer,
  TitledContent,
} from "shared/components/shared/core/Container";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrdersAsync, setOrderInit } from "shared/redux/order.slice";
import { paginationLimit } from "shared/constants/shared.constant";
import PullToRefresh from "react-simple-pull-to-refresh";
import OrdersBusiness from "./OrdersBusiness";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import { HorizontalScrollWithChildren } from "shared/components/shared/core/HorizontalScroll";
import classNames from "classnames";
import _ from "lodash";
import { Tag } from "shared/components/shared/core/Tags";
import { orderStatusList } from "shared/constants/order.constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";
import WithResponsive from "shared/components/shared/utils/WithResponsive";

const OrdersHistory = () => {
  const [page, setPage] = useState(0);

  const [currentFilter, setCurrentFilter] = useState(filter[0]);

  const [searchParams, __] = useSearchParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const newFilterValue = searchParams.get("status");

    if (!newFilterValue) {
      navigate("/order/history?status=all");
      return;
    }

    const newFilter = _.find(filter, (it) => it.value === newFilterValue);

    if (!newFilter) {
      navigate("/order/history?status=all");
      return;
    }

    setCurrentFilter(newFilter);

    load({
      reset: true,
      status: newFilter.value,
    });
  }, [searchParams]);

  const load = async (params) => {
    const reset = params?.reset === true;

    const fetchPage = reset ? 0 : page;

    let query = {
      offset: fetchPage * paginationLimit,
      limit: paginationLimit,
      orderType: "desc",
      variant: "business",
    };

    if (params?.status !== "all") {
      query.status = [params?.status];
    }

    dispatch(
      getOrdersAsync({
        payload: {
          query,
        },
        options: {
          onSuccess: () => setPage((prev) => (reset ? 1 : ++prev)),
          reducerOptions: {
            reset,
          },
        },
      })
    );
  };

  const onTagClick = (tag) => {
    navigate(`/order/history?status=${tag.value}`);
  };

  return (
    <TitledContent title="Vos commandes">
      <HorizontalScrollWithChildren
        className={classNames("w-full pr-[40px] mb-4")}
      >
        {_.map(filter, (item, index) => (
          <div className="w-auto h-auto mr-2" key={index}>
            <Tag
              data={item}
              onClick={onTagClick}
              selected={currentFilter?.value === item.value}
              className="rounded-full"
              defaultIcon={item?.defaultIcon}
              selectedIcon={item?.selectedIcon}
              changeOnClick={false}
            />
          </div>
        ))}
      </HorizontalScrollWithChildren>
      <PullToRefresh
        onRefresh={async () =>
          await load({ reset: true, status: currentFilter.value })
        }
      >
        <OrdersBusiness
        filterStatus={currentFilter.value}
          onLoadMoreClick={() =>
            load({
              status: currentFilter.value,
            })
          }
        />
      </PullToRefresh>
    </TitledContent>
  );
};

const filter = [
  {
    label: "Tout",
    value: "all",
    defaultIcon: (
      <Icon
        icon="hugeicons:all-bookmark"
        color={tailwindTheme.colors.custom.icon.main}
        fontSize={18}
      />
    ),
    selectedIcon: (
      <Icon
        icon="hugeicons:all-bookmark"
        color={tailwindTheme.colors.custom.icon.main}
        fontSize={18}
      />
    ),
  },
  ...orderStatusList.map((it) => ({
    ...it,
    defaultIcon: (
      <Icon
        icon={it.icon}
        color={tailwindTheme.colors.custom.icon.main}
        fontSize={18}
      />
    ),
    selectedIcon: (
      <Icon
        icon={it.icon}
        color={tailwindTheme.colors.custom.icon.main}
        fontSize={18}
      />
    ),
  })),
];

const OrdersHistoryMobile = () => {
  return (
    <HeaderBackContainer
      headerBackProps={{
        backLink: "/order",
      }}
    >
      <OrdersHistory />
    </HeaderBackContainer>
  );
};

const OrdersHistoryTablet = () => (
  <LayoutContentContainer>
    <OrdersHistory />
  </LayoutContentContainer>
);

export default WithResponsive(OrdersHistoryMobile, () => (
  <RightContentTabletContainer
    breadCrumbItems={[tabsBreadcrumbs.order, tabsBreadcrumbs.orderHistory]}
    firstContentMaxWidth={false}
  >
    <OrdersHistoryTablet />
  </RightContentTabletContainer>
));
