import classnames from "classnames";

export default ({ className, rows = 4, ...props }) => (
  <textarea
    type="text"
    className={classnames(
      "w-full h-auto text-lg rounded-full border-custom-border-main _glassResponsivePerf outline-none _shadowInput py-4 px-4",
      className
    )}
    rows={rows}
    {...props}
  />
);
