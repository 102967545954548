import { HorizontalScrollWithChildren } from "shared/components/shared/core/HorizontalScroll";
import { Tag } from "shared/components/shared/core/Tags";
import _ from "lodash";

export const ExploreFilter = ({ onChange, filter, currentFilter }) => {
  return (
    <HorizontalScrollWithChildren className="mb-4">
      {_.map(filter, (it, index) => (
        <Tag
          key={index}
          selected={currentFilter.value === it.value}
          className="mr-2"
          onClick={onChange}
          data={it}
          changeOnClick={false}
        />
      ))}
    </HorizontalScrollWithChildren>
  );
};
