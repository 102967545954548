import { useSelector } from "react-redux";
import UserHeader from "./UserProfileHeader";

export default () => {
  const { data } = useSelector((state) => state.user.me);

  return (
    <div className="w-full h-auto mb-8">
      <div className="w-full h-[250px] _glassResponsivePerf _businessProfileClip -z-10" />
      <div className="w-auto h-auto flex flex-row items-center justify-start -mt-12 ml-2">
        <UserHeader data={data} />
      </div>
    </div>
  );
};
