export const getAccessToken = () => {
  return localStorage.getItem("x-access-token");
};

export const setAccessToken = (value) => {
  return localStorage.setItem("x-access-token", value);
};

export const deleteAccessToken = () => {
  return localStorage.setItem("x-access-token", null);
};

export const getAnonymousId = () => {
  return localStorage.getItem("uuid");
};

export const setAnonymousId = (value) => {
  return localStorage.setItem("uuid", value);
};

export const deleteAnonymousId = () => {
  return localStorage.setItem("uuid", null);
};

export const getStoredItem = (key) => {
  return localStorage.getItem(key);
};

export const setStoredItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const deleteStoredItem = (key) => {
  return localStorage.setItem(key, null);
};
