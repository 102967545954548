import { LayoutContainer } from "./Container";

export const PageNotFound = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="text-xl font-light">Page Not Found</div>
    </div>
  );
};

export const DesktopUnavailable = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <div className="text-xl font-light">
        Version Desktop en cours de construction
      </div>
    </div>
  );
};

export const UnderConstruction = () => {
  return (
    <LayoutContainer>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="text-xl font-light">Under construction</div>
      </div>
    </LayoutContainer>
  );
};

export const ErrorMessagePage = ({ message }) => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="text-xl font-light">{message}</div>
    </div>
  );
};
