import _ from "lodash";
import useBaseSelector from "shared/hooks/useBaseSelector";
import { ErrorMessagePage } from "../core/Error";

export default ({
  children,
  loader,
  reducerPath,
  isError,
  renderEmpty,
  errorMessage = defaultErrorMessage,
  emptyMessage = defaultEmptyMessage,
  isList = false,
}) => {
  const { loading, data, error } = useBaseSelector(reducerPath);

  if (loading) {
    return <>{loader}</>;
  }

  if (error || !data || isError) {
    return <ErrorMessagePage message={errorMessage} />;
  }

  if (isList && data?.data?.length === 0) {
    return <ErrorMessagePage message={emptyMessage} />;
  }

  if (renderEmpty) {
    return <></>;
  }

  return <>{children}</>;
};

const defaultErrorMessage = "Error while fetching this page";
const defaultEmptyMessage = "Pas d'élements";
