import _ from "lodash";
import { getDuration } from "shared/utils/app.utils";
import { BorderedBlock } from "../shared/core/Container";
import OrderCommandNumber from "./shared/OrderCommandNumber";
import OrderFoodItemDetails from "./shared/OrderFoodItemDetails";
import OrderStatusText from "./shared/OrderStatusText";

export default ({ data }) => {
  return (
    <div className="w-full h-auto _glassResponsivePerf">
      <div className="w-full h-auto py-4">
        <div className="flex flex-row items-center justify-between w-full h-auto px-4 mb-8">
          <OrderCommandNumber data={data} />
          <div>{`ID: ${data?.id}`}</div>
        </div>

        <div className="px-4">
          {_.map(data?.selections, (item, index) => (
            <OrderFoodItemDetails data={item} key={index} />
          ))}
        </div>
        <BorderedBlock>
          {data?.reservation && (
            <div className="w-full flex flex-row items-center justify-between text-lg mb-2">
              <div>Table</div>
              <div>{data?.reservation?.businessTable?.table}</div>
            </div>
          )}
          <div className="w-full flex flex-row items-center justify-between">
            <div className="text-lg">Total</div>
            <div className="flex flex-row items-end justify-between">
              <span className="text-lg">{data?.totalPrice}</span>
              <span className="text-md ml-1">TND</span>
            </div>
          </div>
        </BorderedBlock>

        <div className="w-full h-auto flex flex-row items-center justify-between px-4">
          <div>{getDuration(data?.createdAt)}</div>
          <OrderStatusText data={data} />
        </div>
      </div>
    </div>
  );
};
