import classnames from "classnames";
import _ from "lodash";

export default ({ supplements, className }) => {
  if (supplements?.length > 0) {
    return (
      <div className={classnames("w-full mb-3", className)}>
        Supplements
        <span className="font-semibold ml-1">
          {_.map(supplements, "name").join(", ")}
        </span>
      </div>
    );
  }

  return <></>;
};
