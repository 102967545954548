import _ from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BottomSheetListSelection from "shared/components/shared/core/BottomSheetListSelection";
import { tailwindTheme } from "shared/constants/theme.constant";
import { deleteOrderAsync } from "shared/redux/order.slice";

export default ({ order, options, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onItemClick = (item) => {
    switch (item.value) {
      case "edit":
        navigate(`/order/${order.id}/edit`);
        break;
      case "delete":
        dispatch(deleteOrderAsync(order.id));
        break;
      default:
        break;
    }
  };

  return (
    <BottomSheetListSelection
      list={list}
      onItemClick={onItemClick}
      bottomSheetProps={props}
    />
  );
};

const list = [
  {
    icon: "hugeicons:pencil-edit-02",
    label: "Modifier",
    value: "edit",
  },
  {
    icon: "hugeicons:delete-02",
    label: "Supprimer",
    value: "delete",
  },
];
