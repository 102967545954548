import { LayoutContentContainer } from "shared/components/shared/core/Container";
import BusinessProfileBusiness from "./BusinessProfileBusiness";
import { WithResponsiveRightContentContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";

const BusinessProfileTab = () => {
  return (
    <LayoutContentContainer horizontalPadding={false}>
      <BusinessProfileBusiness />
    </LayoutContentContainer>
  );
};

export default WithResponsiveRightContentContainer(BusinessProfileTab, {
  breadCrumbItems: [tabsBreadcrumbs.business],
});
