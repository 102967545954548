import api from "shared/utils/api.utils";

const routes = {
  list: "/user-module/notification",
  setViews: "/user-module/notification/set-views",
};

export const getNotificationsService = async (params) => {
  return await api.get(routes.list, { params: params?.query });
};

export const setNotificationsViewsService = async (params) => {
  return await api.post(routes.setViews, {}, { params: params?.query });
};
