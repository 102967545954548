import { tailwindTheme } from "shared/constants/theme.constant";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

export default ({ data }) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (data?.link) {
      navigate(data?.link);
    } else {
      data?.onClick();
    }
  };

  return (
    <div
      onClick={onClick}
      className="w-full min-h-[50px] flex flex-row items-center justify-between cursor-pointer hover:bg-primary-main hover:rounded-xl hover:border-none border-b-[1px] border-custom-border-main px-2 mb-2"
    >
      <div className="grow h-full flex flex-row items-center justify-start">
        {data?.leftIcon && (
          <Icon
            fontSize={24}
            color={tailwindTheme.colors.custom.icon.main}
            icon={data?.leftIcon}
          />
        )}
        <div className="ml-2">{data?.label}</div>
      </div>
      <div>
        {(data?.showRightIcon ?? true) && (
          <Icon
            fontSize={24}
            color={tailwindTheme.colors.custom.icon.main}
            icon="hugeicons:circle-arrow-up-right"
          />
        )}
      </div>
    </div>
  );
};
