import api from "shared/utils/api.utils";

const fileUploadRoute = "/common-module/file/upload";

export const uploadFileService = async (file) => {
  try {
    const formData = new FormData();

    formData.append("file", file);

    const response = await api.post(fileUploadRoute, formData);

    return response.data;
  } catch (error) {}
};
