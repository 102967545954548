import _ from "lodash";
import { PageNotFound } from "shared/components/shared/core/Error";
import useMe from "shared/hooks/user/useMe";

export default ({ children, roles, execeptRoles }) => {
  const user = useMe();

  if (execeptRoles) {
    if (_.includes(execeptRoles, user.relatedBusiness?.role)) {
      return <PageNotFound />;
    }

    return <>{children}</>;
  }

  if (!_.includes(roles, user.relatedBusiness?.role)) {
    return <PageNotFound />;
  }

  return <>{children}</>;
};
