import { Icon } from "@iconify/react";
import classnames from "classnames";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({
  className,
  inputClassName,
  rightIcon,
  leftIcon,
  onRightIconClick,
  tooltip,
  ...props
}) => (
  <div
    className={classnames(
      "w-full h-auto flex flex-row items-center justify-between w-full h-auto text-lg rounded-full border-custom-border-main _glassResponsivePerf _shadowInput py-4 px-4",
      {
        "-tooltip -tooltip-left -tooltip-white -tooltip-primary": tooltip,
      },
      className
    )}
    data-tip={tooltip}
  >
    <div className="grow flex flex-row items-center justify-start">
      {leftIcon && (
        <div className="min-w-[25px] cursor-pointer mr-2">
          <Icon
            icon={leftIcon}
            color={tailwindTheme.colors.custom.icon.main}
            fontSize={24}
          />
        </div>
      )}
      <input
        type="text"
        className={classnames(
          "w-full h-auto bg-transparent outline-none text-lg",
          inputClassName
        )}
        {...props}
      />
    </div>
    <div className="min-w-[25px] cursor-pointer" onClick={onRightIconClick}>
      {rightIcon && (
        <Icon
          icon={rightIcon}
          color={tailwindTheme.colors.custom.icon.main}
          fontSize={24}
        />
      )}
    </div>
  </div>
);
