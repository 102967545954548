import _ from "lodash";
import SettingListItem from "./SettingListItem";

export default ({ settings, className }) => {
  return (
    <div className={className}>
      {_.map(settings, (it, index) => (
        <SettingListItem data={it} key={index} />
      ))}
    </div>
  );
};
