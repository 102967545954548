import BusinessHeader from "public/components/business/shared/BusinessHeader";
import BusinessSettingsBusiness from "./shared/BusinessSettingsBusiness";
import useBusiness from "business/hooks/useBusiness";

export default () => {
  const business = useBusiness();

  return (
    <>
      <BusinessHeader data={business} />
      <div className="px-4 lg:px-0">
        <BusinessSettingsBusiness />
      </div>
    </>
  );
};
