import _ from "lodash";
import classnames from "classnames";
import { useState } from "react";
import { tailwindTheme } from "shared/constants/theme.constant";
import { Icon } from "@iconify/react";
import BaseImage from "shared/components/shared/base/BaseImage";
import { useDispatch } from "react-redux";
import useBaseSelector from "shared/hooks/useBaseSelector";
import {
  handleOnDeleteCartItem,
  handleOnNewCartItem,
} from "shared/utils/cart.utils";
import BaseButton from "shared/components/shared/base/BaseButton";
import MenuPrice from "./MenuPrice";

export default ({ business, className, variant }) => {
  const currentBusiness = useBaseSelector(`order.${variant}.business`);
  const selections = useBaseSelector(`order.${variant}.selections`);

  const [category, setCategory] = useState();
  const [gridViewType, setGridViewType] = useState("root");

  const dispatch = useDispatch();

  const exists = (item) => _.find(selections, (el) => el.id === item.id);

  const onAddClick = (item) => {
    handleOnNewCartItem(variant, selections, item, dispatch);
  };

  const onMinusClick = (item) => {
    handleOnDeleteCartItem(variant, selections, item, dispatch);
  };

  const onCategoryClick = (value) => {
    setCategory(value);
    setGridViewType("items");
  };

  const onBackClick = () => {
    setCategory();
    setGridViewType("root");
  };

  return (
    <HeaderContainer
      gridViewType={gridViewType}
      onBackClick={onBackClick}
      category={category}
    >
      {gridViewType === "root" ? (
        <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-2">
          {_.map(business?.menu, (item, index) => (
            <BoltCategoryMenuItem
              data={item}
              key={index}
              onClick={onCategoryClick}
            />
          ))}
        </div>
      ) : (
        <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-2">
          {_.map(category?.children, (item, index) => (
            <BoltMenuItem
              key={index}
              data={item}
              onAddClick={onAddClick}
              onRemoveClick={onMinusClick}
              exists={exists(item)}
            />
          ))}
        </div>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = ({ children, gridViewType, category, onBackClick }) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full">
        {gridViewType === "items" && (
          <div className="w-full h-auto flex flex-row items-center justify-start mb-4">
            <Icon
              icon="hugeicons:link-backward"
              fontSize={28}
              color={tailwindTheme.colors.custom.icon.main}
              onClick={onBackClick}
              className="mr-2"
            />
            <div className="flex-1 text-lg lg:text-2xl font-semibold">
              {category?.name}
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

const BoltCategoryMenuItem = ({ data, onClick }) => {
  return (
    <div
      className="w-full xl:max-w-[200px] h-auto px-2 py-2 rounded-xl relative cursor-pointer _glassResponsivePerf"
      onClick={() => onClick(data)}
    >
      <div className="w-full h-full flex flex-col justify-start items-start">
        <BaseImage
          src={data?.image}
          alt={data?.name}
          className="w-full h-[100px] lg:h-[150px] rounded-xl mb-3"
        />
        <div className="text-white text-lg">{data?.name}</div>
      </div>
    </div>
  );
};

const BoltMenuItem = ({ data, onAddClick, onRemoveClick, exists }) => {
  return (
    <div className="w-full xl:max-w-[200px] h-auto px-2 py-2 rounded-xl relative cursor-pointer _glassResponsivePerf border-[1px] border-transparent hover:border-primary-main relative">
      <div className="w-full h-full flex flex-col justify-start items-start">
        <BaseImage
          src={data?.image}
          alt={data?.name}
          className="w-full h-[100px] lg:h-[150px] rounded-xl mb-3"
        />
        <MenuPrice data={data?.price} />
        <div className="w-full h-[90px] flex flex-col items-start justify-between">
          <div className="text-white text-lg">{data?.food?.name}</div>
          {exists ? (
            <BaseButton.Contained
              secondary
              icon="hugeicons:delete-01"
              className="w-full rounded-xl"
              onClick={() => onRemoveClick(data)}
            >
              Supprimer
            </BaseButton.Contained>
          ) : (
            <BaseButton.Contained
              primary
              icon="hugeicons:plus-sign-circle"
              className="w-full rounded-xl"
              onClick={() => onAddClick(data)}
            >
              Ajouter
            </BaseButton.Contained>
          )}
        </div>
      </div>
    </div>
  );
};
