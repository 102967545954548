import { SponsoredFood } from "../food/Foods";
import BaseCarousel, {
  CarouselSkeleton,
} from "shared/components/shared/core/BaseCarousel";
import LoaderPortal from "shared/components/shared/utils/LoaderPortal";
import useBaseSelector from "shared/hooks/useBaseSelector";

export default () => {
  const { data } = useBaseSelector("business.home");

  return (
    <LoaderPortal
      reducerPath="business.home"
      loader={<CarouselSkeleton />}
      renderEmpty={!data?.deals}
    >
      <BaseCarousel
        component={SponsoredFood}
        data={data?.deals}
        showIndicators
      />
    </LoaderPortal>
  );
};
