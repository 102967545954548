import classnames from "classnames";

export default ({ children, className, onClick, ...props }) => {
  return (
    <div
      onClick={() => onClick?.()}
      className={classnames(
        "w-full h-[60px] _glassResponsivePerf rounded-md cursor-pointer px-4",
        className
      )}
      {...props}
    >
      <>{children}</>
    </div>
  );
};
