import classnames from "classnames";
import _ from "lodash";

export default ({ ingredients, className }) => {
  return (
    <div className={classnames("w-full mb-3", className)}>
      {ingredients?.length > 0 ? (
        <>
          Sans
          <span className="font-semibold ml-1">
            {_.map(ingredients, "name").join(", ")}
          </span>
        </>
      ) : (
        <>
          Ingrédients
          <span className="font-semibold ml-1">
            Tout inclus
          </span>
        </>
      )}
    </div>
  );
};
