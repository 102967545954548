import { useDispatch } from "react-redux";
import BottomSheetListSelection from "shared/components/shared/core/BottomSheetListSelection";
import { getBusinessUsersAsync } from "shared/redux/business.slice";
import { deleteBusinessUserService } from "shared/services/business.service";

export default ({ data, options, ...props }) => {
  const dispatch = useDispatch();

  const onItemClick = (item) => {
    switch (item.value) {
      case "delete":
        deleteBusinessUser();
        break;
      default:
        break;
    }
  };

  const deleteBusinessUser = async () => {
    try {
      const response = await deleteBusinessUserService(data.id);
      dispatch(getBusinessUsersAsync());
    } catch (error) {}
  };

  return (
    <BottomSheetListSelection
      list={list}
      onItemClick={onItemClick}
      bottomSheetProps={props}
    />
  );
};

const list = [
  {
    icon: "hugeicons:delete-02",
    label: "Supprimer",
    value: "delete",
  },
];
