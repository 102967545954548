/** @type {import('tailwindcss').Config} */
const colors = require("tailwindcss/colors");

//# primary.main 090619

//#red: #BD141D
//#trendy: #004AAD

const mainThemePalette = {
  primary: {
    main: "var(--primary-main)",
    shades: {
      light: {
        0: "#111320",
        1: colors.gray[800],
        2: "#131853",
      },
    },
  },
  secondary: {
    main: "var(--secondary-main)",
    shades: {
      light: {
        0: colors.red[600],
      },
    },
  },
  tertiery: {
    main: "var(--tertiery-main)",
    shades: {
      light: {
        0: "#ffcd5b",
        1: "#ffd880",
      },
    },
  },
  quaternary: {
    main: "var(--quaternary-main)",
    shades: {
      light: {
        0: "#ffcd5b",
        1: "#ffd880",
      },
    },
  },
};

module.exports = {
  content: ["./src/**/*.{html,js}"],
  theme: {
    extend: {
      transitionProperty: {
        height: "height",
      },
      fontSize: {
        xs: "0.6rem",
        sm: "0.7rem",
        base: "0.8rem",
        lg: "0.9rem",
        xl: "1.1rem",
        "2xl": "1.563rem",
        "3xl": "1.953rem",
        "4xl": "2.441rem",
        "5xl": "3.052rem",
      },
      colors: {
        background: {
          main: mainThemePalette.primary.main,
          light: mainThemePalette.primary.shades.light,
          glass: "rgba(26, 16, 39, 0.8)",
        },
        primary: {
          main: mainThemePalette.secondary.main,
          light: mainThemePalette.secondary.shades.light,
        },
        secondary: {
          main: mainThemePalette.tertiery.main,
          light: mainThemePalette.tertiery.shades.light,
        },
        tertiery: {
          main: mainThemePalette.quaternary.main,
          light: mainThemePalette.quaternary.shades.light,
        },
        custom: {
          text: {
            main: "white",
            sub: colors.gray[400],
          },
          input: {
            background: mainThemePalette.primary.shades.light[0],
          },
          icon: {
            main: "white",
            sub: colors.gray[400],
          },
          header: {
            background: mainThemePalette.primary.main,
            shadow: colors.gray[600],
          },
          tab: {
            background: mainThemePalette.primary.shades.light[0],
            default: colors.gray[400],
            selected: "white",
          },
          card: {
            background: mainThemePalette.primary.shades.light[0],
          },
          carousel: {
            indicators: {
              default: mainThemePalette.primary.shades.light[0],
              selected: mainThemePalette.tertiery.main,
            },
            shadow: mainThemePalette.primary.shades.light[2],
          },
          border: {
            main: colors.gray[800],
          },
          tag: {
            defaultBackground: "transparent",
            selectedBackground: mainThemePalette.secondary.main,
            selectedText: "white",
            defaultText: "white",
            defaultBorder: mainThemePalette.secondary.main,
          },
        },
        palette: {
          warning: "#FE5C52",
        },
      },
    },
  },
  plugins: [require("daisyui")],
  daisyui: {
    themes: ["synthwave"],
    base: false,
    prefix: "-", // prefix for daisyUI classnames (components, modifiers and responsive class names. Not colors)
  },
};
