import _ from "lodash";

export const validateBusinessRouteSlug = (slug) => {
  return slug;
};

export const findBusinessPageTabByValue = (tabs, value) => {
  return _.chain(tabs)
    .find((it) => it.value === value)
    .value();
};

export const isUserCloseToBusiness = async (businessCoords) => {
  return await new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(
            isUserCloseToBusinessByPosition(position.coords, businessCoords)
          );
        },
        (error) => {
          console.log('couldnt get position', error);
          resolve(false)
        }
      );
    }
  });
};

const isUserCloseToBusinessByPosition = (userCoords, businessCoords) => {
  console.log(userCoords);
  return true;
};
