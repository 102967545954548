import { Icon } from "@iconify/react";
import classnames from "classnames";
import { useEffect, useRef, useState } from "react";
import { tailwindTheme } from "shared/constants/theme.constant";
import { uploadFile, uploadFileService } from "shared/services/file.service";
import BaseImage from "../base/BaseImage";

export default ({ onFileChange, className, imageClassName, value }) => {
  const [image, setImage] = useState(value);

  const ref = useRef();

  useEffect(() => {
    setImage(value);
  }, [value]);

  const onUploadClick = () => {
    ref?.current?.click();
  };

  const uploadAsync = async (file) => {
    const fileUrl = await uploadFileService(file);

    if (fileUrl) {
      setImage(fileUrl);
      onFileChange?.(fileUrl);
    }
  };

  const onDeleteClick = () => {
    setImage();
    onFileChange();
  };

  const onChange = (e) => {
    const files = e.target.files;

    if (!files || files?.length === 0) {
      return;
    }

    const file = files[0];

    uploadAsync(file);

    return;

    const reader = new FileReader();

    reader.onloadend = function () {
      setImage(reader.result);
      onFileChange?.(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className={classnames("w-[100px] h-[100px] relative", className)}>
      {image ? (
        <BaseImage
          src={image}
          className={classnames("w-full h-full", imageClassName)}
        />
      ) : (
        <div
          className={classnames(
            "w-full h-full _glassResponsivePerf",
            imageClassName
          )}
        ></div>
      )}
      <input
        ref={ref}
        id="file-input"
        type="file"
        className="hidden"
        onChange={onChange}
      />
      <div className="flex flex-col items-center justify-start absolute -right-8 top-0">
        <Icon
          icon="hugeicons:pencil-edit-02"
          color={tailwindTheme.colors.custom.icon.main}
          fontSize={24}
          onClick={onUploadClick}
          className="mb-2"
        />
        <Icon
          icon="hugeicons:delete-02"
          fontSize={24}
          color={tailwindTheme.colors.palette.warning}
          onClick={onDeleteClick}
        />
      </div>
    </div>
  );
};
