import BaseImage from "shared/components/shared/base/BaseImage";

export default ({ data, onClick }) => {
  return (
    <div
      className="w-36 h-[180px] relative cursor-pointer"
      onClick={() => onClick(data)}
    >
      <div className="flex flex-row justify-center w-full absolute top-0 h-28 z-10">
        <div className="w-28 h-28 overflow-hidden relative drop-shadow-2xl rounded-full">
          <BaseImage
            src={data?.image}
            alt={data?.name}
            className="w-28 h-28 rounded-full mb-3"
          />
        </div>
      </div>
      <div className="w-full h-[140px] absolute top-[55px]">
        <div className="w-full h-full flex flex-col justify-end items-center rounded-2xl _glassResponsivePerf px-2 pb-4 pt-16 relative">
          <div className="text-white text-center text-lg ">{data?.name}</div>
        </div>
      </div>
    </div>
  );
};
