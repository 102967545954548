import RootRouter from "RootRouter";
import Helmet from "shared/components/shared/core/Helmet";
import MouseShadow from "shared/components/shared/core/MouseShadow";

export default () => {
  return (
    <div className="w-full h-screen text-sm lg:text-lg relative">
      <Helmet />
      <MouseShadow />
      <RootRouter />
    </div>
  );
};
