import { ToastContainer } from "react-toastify";

export default () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={true}
    newestOnTop={true}
    closeOnClick
    closeButton={false}
    toastClassName={() => "p-0"}
  />
);
