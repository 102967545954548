import _ from "lodash";
import { useEffect, useState } from "react";
import BaseButton from "shared/components/shared/base/BaseButton";
import { TitledContent } from "shared/components/shared/core/Container";
import CategoryMenu from "../menu/CategoryMenu";
import { getBusinessService } from "shared/services/business.service";

export default ({ business: loadedBusiness, onDone, variant }) => {
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setLoading(true);
      const { data } = await getBusinessService(loadedBusiness.id);
      setBusiness(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (!business) {
    return <></>;
  }

  return (
    <div className="w-full h-auto bg-background-main px-4 pt-4 pb-[100px]">
      <TitledContent
        title="Ajouter/Modifier votre selection"
        titleClassName="w-full text-center mb-8"
      >
        <CategoryMenu business={business} variant={variant} />
        <div className="w-full h-auto flex flex-row justify-center bg-background-main absolute bottom-0 left-0 z-20 px-4 py-4">
          <BaseButton.Contained
            primary
            icon="hugeicons:checkmark-circle-01"
            onClick={onDone}
            className="w-full h-auto max-w-[350px] flex flex-row items-center justify-center rounded-full py-2"
          >
            Términer
          </BaseButton.Contained>
        </div>
      </TitledContent>
    </div>
  );
};
