import { Helmet } from "react-helmet";

export default () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Swiftr | Food & Beverage</title>
      <meta name="description" content="Swiftr for Food Ecommerce" />
      <link
        rel="icon"
        type="image/png"
        href="swiftr.app.logo.png"
      />
    </Helmet>
  );
};
