export const notificationTabs = [
  {
    label: "Vous",
    route: "main",
  },
  {
    label: "Team Swiftr",
    route: "system",
  },
];
