import classnames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseImage from "shared/components/shared/base/BaseImage";

export const SponsoredFood = ({ data, className }) => {
  const navigate = useNavigate();
  return (
    <BaseImage
      src={data?.imageURL}
      className="w-full h-[180px] lg:h-[400px] rounded-lg"
      onClick={() => navigate(data?.redirectURL)}
    />
  );
};

export const FoodItemLegacy = ({ data }) => {
  return (
    <div className="w-screen h-auto rounded-lg flex flex-row items-start justify-start bg-background-light-1 px-2 py-2 mr-4">
      <BaseImage src={data?.image} className="w-24 h-24 rounded-full mr-2" />
      <div className="grow flex flex-col items-start justify-start">
        <div className=" text-sm font-light mb-1">{data?.name} </div>
        <div className="text-gray-200">{data?.name} </div>
      </div>
    </div>
  );
};

export const CategoryItem = ({ data }) => {
  const [selected, setSelected] = useState(false);
  return (
    <div
      type="button"
      className={classnames(
        "w-30 h-30 flex flex-col items-center justify-between rounded-xl px-2 py-2",
        {
          "bg-background-light-1": selected,
        }
      )}
      onClick={() => setSelected((prev) => !prev)}
    >
      <BaseImage src={data?.image} className="w-16 h-16 rounded-full mb-2" />
      <div className="">{data?.name}</div>
    </div>
  );
};

export const FoodItem = ({ data }) => {
  return (
    <div className="w-full h-auto flex flex-col items-center justify-betwen bg-background-light-1 rounded-xl relative mb-4">
      <BaseImage src={data?.image} className="w-full h-32 rounded-xl" />
      <div className="w-14 h-14 flex flex-col items-center justify-center font-light rounded-full bg-primary-main absolute top-2 right-2">
        <div className="flex flex-row items-center justify-center">
          <div className="text-md font-light mr-1">{data?.price}</div>
          <div className="text-xs">DT</div>
        </div>
      </div>
      <div className="w-full px-4 py-4">
        <div className="w-full text-sm font-light text-left mb-2">
          {data?.name}
        </div>
        <div className="w-full h-auto flex flex-row items-cneter justify-between">
          <div className="text-gray-400">{data?.business?.name}</div>
          <div className="text-gray-400">{data?.estimation}</div>
        </div>
      </div>
    </div>
  );
};
