import CategoryMenu from "shared/components/order/menu/CategoryMenu";
import MenuLoader from "shared/components/order/menu/MenuLoader";
import { LayoutContainer } from "shared/components/shared/core/Container";
import WithResponsive from "shared/components/shared/utils/WithResponsive";
import useBaseSelector from "shared/hooks/useBaseSelector";
import BusinessCartTabContentBusiness from "../order/cart/BusinessCartTabContentBusiness";
import { RightContentTabletContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import BoltMenu from "shared/components/order/menu/BoltMenu";

const Mobile = () => {
  const { data: business, loading } = useBaseSelector("business.item");

  if (loading) {
    return (
      <LayoutContainer>
        <MenuLoader />
      </LayoutContainer>
    );
  }

  return (
    <LayoutContainer>
      <BoltMenu business={business} variant="cart" />
    </LayoutContainer>
  );
};

const Tablet = () => {
  const { data: business, loading } = useBaseSelector("business.item");

  if (loading) {
    return <MenuLoader />;
  }

  return (
    <RightContentTabletContainer
      breadCrumbItems={[tabsBreadcrumbs.menu]}
      firstContentMaxWidth={false}
    >
      <BoltMenu business={business} variant="cart" />
      <BusinessCartTabContentBusiness />
    </RightContentTabletContainer>
  );
};

export default WithResponsive(Mobile, Tablet);
