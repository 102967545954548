import { Icon } from "@iconify/react";
import { MdAccountCircle } from "react-icons/md";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({ data }) => {
  return (
    <div className="w-auto h-auto flex flex-col items-center justify-start rounded-lg px-2">
      <div className="w-[80px] h-[80px] flex flex-col items-center justify-center bg-background-light-0 rounded-full border-[4px] border-custom-border-main hover:bg-primary-main hover:border-none cursor-pointer z-10 mb-4">
        <Icon
          fontSize={40}
          icon="hugeicons:user"
          color={tailwindTheme.colors.custom.icon.main}
        />
      </div>
      <div className="w-auto h-auto flex flex-col items-start justify-start">
        <div className="font-semibold text-lg mb-1">{data?.fullName}</div>
      </div>
    </div>
  );
};
