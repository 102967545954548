import { BusinessCard } from "public/components/business/shared/Business";
import _ from "lodash";

export const ExploreBusinessList = ({ data }) => {
  return (
    <div className="w-full h-auto">
      {_.map(data, (it, index) => (
        <div className="w-full mb-5" key={index}>
          <BusinessCard data={it} className="w-full" />
        </div>
      ))}
    </div>
  );
};
