import useIsMobile from "shared/hooks/useIsMobile";

export default (MobileComponent, DesktopComponent) => (props) => {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return <DesktopComponent {...props} />;
  }

  return <MobileComponent {...props} />;
};
