import { createSlice } from "@reduxjs/toolkit";
import reduxUtils from "./utils/redux.utils";
import { createNodeThunkMiddleware } from "./utils/thunk.utils";
import { getFoodListService } from "shared/services/food.service";

const managementActions = reduxUtils.createManagementActions("/food");

const nodeThunkMiddleware = createNodeThunkMiddleware(managementActions);

export const getFoodListAsync = nodeThunkMiddleware(
  "/food/list",
  getFoodListService,
  "list"
);

const initialState = {
  list: {
    loading: false,
    error: false,
    data: [],
  },
  form: {
    loading: false,
    error: false,
    data: undefined,
  },
};

const slice = createSlice({
  name: "food",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    reduxUtils.tookit
      .buildManagementCases(builder, managementActions, initialState)
      .addCase(
        getFoodListAsync.fulfilled,
        reduxUtils.hof.onRootState.setter.setWithPathFromThunk("list.data")
      );
  },
});

export const setFoodNodeData = managementActions.setNodeData;
export const setFoodNodeFieldData = managementActions.setNodeFieldData;
export const mergeFoodNodeData = managementActions.mergeNodeData;
export const setFoodInit = managementActions.setInit;

export const foodReducer = slice.reducer;
