import { tailwindTheme } from "shared/constants/theme.constant";
import _ from "lodash";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { orderStatusList } from "shared/constants/order.constant";

export default ({ data, showLabel = true }) => {
  const [statusOption, setStatusOption] = useState();

  useEffect(() => {
    setStatusOption(_.find(orderStatusList, (it) => it.value === data?.status));
  }, []);

  if (!statusOption) {
    return <></>;
  }

  return (
    <div className="flex flex-row items-center justify-start w-auto h-auto">
      <Icon
        icon={statusOption.icon}
        color={tailwindTheme.colors.custom.icon.main}
        fontSize={24}
        className="mr-2"
      />
      {showLabel && <div>{statusOption.label}</div>}
    </div>
  );
};
