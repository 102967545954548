import { tailwindTheme } from "shared/constants/theme.constant";
import { getDuration } from "shared/utils/app.utils";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Icon } from "@iconify/react/dist/iconify";

export default ({ data }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classnames(
        "w-full h-auto  border-b-[1px] border-custom-border-main cursor-pointer px-4 py-4",
        {
          _glassResponsivePerf: !data?.isViewed,
        }
      )}
      onClick={() => navigate(data?.redirectLink)}
    >
      <div className="w-full h-auto flex flex-row items-start justify-start">
        <div className="w-[50px] h-[50px] relative mr-5">
          <Icon
            icon="hugeicons:notification-02"
            fontSize={50}
            color={tailwindTheme.colors.custom.icon.main}
          />
          <Icon
            icon="hugeicons:border-full"
            fontSize={20}
            color={tailwindTheme.colors.custom.icon.main}
            className="absolute top-0 -right-3"
          />
        </div>
        <div className="grow">
          <div className="w-full h-auto flex flex-row items-center justify-between mb-2">
            <div className="font-semibold text-[16px]">{data?.title}</div>
          </div>

          <div className="text-custom-text-sub mb-2">{data?.message}</div>
          <div className="w-full text-custom-text-sub text-right">
            {getDuration(data?.createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};
