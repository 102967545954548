import { Icon } from "@iconify/react";
import classNames from "classnames";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({ data }) => {
  return (
    <div className="w-full h-[120px] lg:h-[180px] _glassResponsivePerf _hoverBorderPrimary relative flex flex-col items-start justify-between rounded-xl border-[1px] border-custom-border-main cursor-pointer px-4 py-4 lg:px-6 lg:py-6">
      <div className="text-lg lg:text-xl font-semibold">{data?.title}</div>

      <div className="w-full flex flex-col items-start justify-start">
        <div className="text-xl lg:text-2xl font-bold mb-1">{data?.value}</div>
        <div className="text-custom-text-sub">{data?.subValue}</div>
      </div>
      {data?.rightIcon && (
        <Icon
          icon={data?.rightIcon}
          fontSize={22}
          color={tailwindTheme.colors.custom.icon.default}
          className="absolute lg:top-6 lg:right-6 top-4 right-4"
        />
      )}
    </div>
  );
};

export const OverviewCardV2 = ({ data }) => {
  return (
    <div className="w-full h-[80px] flex flex-row items-center justify-start _glassResponsivePerf _borderThin _hoverBorderPrimary cursor-pointer px-2 py-2">
      <div
        style={{
          backgroundColor:
            data?.iconBgColor ?? tailwindTheme?.colors?.primary.main,
        }}
        className={classNames(
          "min-w-[50px] h-[50px] flex flex-col items-center justify-center rounded-full mr-2"
        )}
      >
        <Icon
          icon={data?.icon}
          fontSize={22}
          color={data?.iconColor ?? tailwindTheme.colors.custom.icon.default}
        />
      </div>
      <div className="grow-x flex flex-col items-start justify-center">
        <div className="text-custom-text-sub">{data?.title}</div>
        <div className="text-xl lg:text-2xl font-bold">{data?.value}</div>
      </div>
    </div>
  );
};
