import { CiFilter } from "react-icons/ci";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import BaseSkeleton from "../base/BaseSkeleton";
import useScrollTop from "shared/hooks/useScrollTop";
import HeaderBack from "./HeaderBack";
import { HeaderMobile } from "public/components/shared/core/Header";
import HorizontalScroll, {
  HorizontalScrollWithChildren,
} from "./HorizontalScroll";
import { useRef } from "react";
import { Icon } from "@iconify/react";
import { tailwindTheme } from "shared/constants/theme.constant";

export const TitledContent = ({
  title,
  className,
  titleClassName,
  children,
}) => {
  return (
    <div
      className={classnames(
        "w-auto h-auto flex flex-col items-start justify-start mb-4 lg:mb-6",
        className
      )}
    >
      <div
        className={classnames(
          "w-auto text-lg lg:text-xl mb-2 lg:mb-4",
          titleClassName
        )}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

export const TitledHorizontalScroll = ({
  title,
  className,
  titleClassName,
  horizontalScrollClassName,
  component,
  items,
}) => {
  const ref = useRef();

  const interval = useRef(null);

  const onMouseHold = (direction) => {
    if (interval.current) {
      return;
    }

    interval.current = setInterval(() => {
      if (direction === "left") {
        ref.current.scrollLeft -= 15;
      } else if (direction === "right") {
        ref.current.scrollLeft += 15;
      }
    }, 10);
  };

  const onMouseUp = () => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  };

  return (
    <div
      className={classnames(
        "w-auto h-auto flex flex-col items-start justify-start mb-4 lg:mb-6",
        className
      )}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div
          className={classnames(
            "w-auto text-lg lg:text-xl mb-2 lg:mb-4",
            titleClassName
          )}
        >
          {title}
        </div>
        <div className="flex flex-row items-center justify-start max-xl:hidden">
          <Icon
            icon="hugeicons:circle-arrow-left-01"
            color={tailwindTheme.colors.secondary.main}
            fontSize={36}
            className="cursor-pointer mr-2"
            onMouseDown={() => onMouseHold("left")}
            onMouseUp={onMouseUp}
          />
          <Icon
            icon="hugeicons:circle-arrow-right-01"
            color={tailwindTheme.colors.primary.main}
            fontSize={36}
            className="cursor-pointer"
            onMouseDown={() => onMouseHold("right")}
            onMouseUp={onMouseUp}
          />
        </div>
      </div>
      <HorizontalScroll
        className={horizontalScrollClassName}
        component={component}
        items={items}
        ref={ref}
      />
    </div>
  );
};

export const TitledHorizontalScrolWithChildren = ({
  title,
  className,
  titleClassName,
  horizontalScrollClassName,
  children,
}) => {
  const ref = useRef();

  const interval = useRef(null);

  const onMouseHold = (direction) => {
    if (interval.current) {
      return;
    }

    interval.current = setInterval(() => {
      if (direction === "left") {
        ref.current.scrollLeft -= 15;
      } else if (direction === "right") {
        ref.current.scrollLeft += 15;
      }
    }, 10);
  };

  const onMouseUp = () => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  };

  return (
    <div
      className={classnames(
        "w-auto h-auto flex flex-col items-start justify-start mb-4 lg:mb-6",
        className
      )}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div
          className={classnames(
            "w-auto text-lg lg:text-xl mb-2 lg:mb-4",
            titleClassName
          )}
        >
          {title}
        </div>
        <div className="flex flex-row items-center justify-start max-xl:hidden">
          <Icon
            icon="hugeicons:circle-arrow-left-01"
            color={tailwindTheme.colors.secondary.main}
            fontSize={36}
            className="cursor-pointer mr-2"
            onMouseDown={() => onMouseHold("left")}
            onMouseUp={onMouseUp}
          />
          <Icon
            icon="hugeicons:circle-arrow-right-01"
            color={tailwindTheme.colors.primary.main}
            fontSize={36}
            className="cursor-pointer"
            onMouseDown={() => onMouseHold("right")}
            onMouseUp={onMouseUp}
          />
        </div>
      </div>
      <HorizontalScrollWithChildren
        className={horizontalScrollClassName}
        ref={ref}
      >
        {children}
      </HorizontalScrollWithChildren>
    </div>
  );
};

export const TitledContentSkeleton = ({ children, className }) => {
  return (
    <div
      className={classnames(
        "w-auto h-auto flex flex-col items-start justify-start mb-4",
        className
      )}
    >
      <BaseSkeleton className="w-[120px] h-[20px] mb-2" />
      {children}
    </div>
  );
};

export const TitledViewAllContent = ({
  title,
  viewAllNavigatePath,
  children,
  className,
  headerClassName,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={classnames(
        "w-full h-auto flex flex-col items-start justify-start mb-4",
        className
      )}
    >
      <div
        className={classnames(
          "w-full h-auto flex flex-row items-center justify-between mb-4",
          headerClassName
        )}
      >
        <div className="w-auto text-lg lg:text-2xl font-light">{title}</div>
        <div
          type="button"
          onClick={() => navigate(viewAllNavigatePath)}
          className="xl:hidden"
        >
          <div className="w-auto text-primary-main font-bold">Voir tout</div>
        </div>
      </div>
      <div className="w-full h-auto">{children}</div>
      <div className="w-full flex flex-row items-center justify-between max-xl:hidden">
        <div className="w-full h-[2px] bg-background-light-0" />
        <div
          className="min-w-[150px] flex flex-row items-center justify-center cursor-pointer px-4"
          onClick={() => navigate(viewAllNavigatePath)}
        >
          <div className="text-primary-main font-bold mr-2">Voir tout</div>
          <Icon
            icon="hugeicons:arrow-right-01"
            fontSize={22}
            color={tailwindTheme.colors.primary.main}
          />
        </div>
        <div className="w-full h-[2px] bg-background-light-0" />
      </div>
    </div>
  );
};

export const TitledRightFilterContent = ({
  title,
  onFilterClick,
  children,
}) => {
  return (
    <div className="w-auto h-auto flex flex-col items-start justify-start mb-6">
      <div className="w-full h-auto flex flex-row items-center justify-between mb-4">
        <div className="w-auto h-auto lg:text-2xl">{title}</div>
        <div type="button" onClick={onFilterClick}>
          <CiFilter color="white" size={22} />
        </div>
      </div>
      {children}
    </div>
  );
};

export const LayoutContainer = ({
  children,
  contentClassName,
  headerPadding = true,
  horizontalPadding = true,
}) => {
  return (
    <HeaderContainer>
      <LayoutContentContainer
        className={contentClassName}
        headerPadding={headerPadding}
        horizontalPadding={horizontalPadding}
      >
        {children}
      </LayoutContentContainer>
    </HeaderContainer>
  );
};

export const BorderedBlock = ({ children, className }) => {
  return (
    <div
      className={classnames(
        "w-full h-auto border-y-[1px] border-custom-border-main text-md px-4 py-4 mb-4",
        className
      )}
    >
      {children}
    </div>
  );
};

export const HeaderContainer = ({ children }) => {
  return (
    <div className="w-full h-auto">
      <HeaderMobile />
      {children}
    </div>
  );
};

export const LayoutContentContainer = ({
  children,
  headerPadding = true,
  horizontalPadding = true,
  bottomPadding = true,
  className,
}) => {
  useScrollTop();

  return (
    <div
      className={classnames("w-full h-auto bg-transparent", className, {
        //horizontal & top padding is enabled with the tablet container for sizes>=md
        "max-md:px-4": horizontalPadding,
        "max-md:pt-6": headerPadding,
        "pb-[100px]": bottomPadding,
      })}
    >
      {children}
    </div>
  );
};

export const HeaderBackContainer = ({
  children,
  headerBackProps,
  layoutContainerProps,
}) => {
  return (
    <div className="w-full h-auto">
      <HeaderBack marginBottom={true} {...headerBackProps} />
      <LayoutContentContainer headerPadding={false} {...layoutContainerProps}>
        {children}
      </LayoutContentContainer>
    </div>
  );
};
