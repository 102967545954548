import classNames from "classnames";
import { useEffect, useState } from "react";
import WithResponsive from "../utils/WithResponsive";

const DesktopMouseShadow = () => {
  const [position, setPosition] = useState();

  useEffect(() => {
    const move = document.addEventListener("mousemove", onMouseUpdate, false);
    const update = document.addEventListener(
      "mouseenter",
      onMouseUpdate,
      false
    );

    return () => {
      document.removeEventListener("mousemove", move);
      document.removeEventListener("mouseenter", update);
    };
  }, []);

  const onMouseUpdate = (e) => {
    setPosition({
      x: e.pageX,
      y: e.pageY,
    });
  };

  return (
    <span
      className={classNames(`absolute w-[1px] h-[1px] _mouseShadow -z-20`, {
        none: !position,
      })}
      style={{
        top: position?.y,
        left: position?.x,
      }}
    />
  );
};

export default WithResponsive(() => <></>, DesktopMouseShadow);
