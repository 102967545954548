import { Icon } from "@iconify/react/dist/iconify";
import BaseCard from "shared/components/shared/base/BaseCard";
import { businessUserRoleViewMap } from "shared/constants/business.constant";
import { tailwindTheme } from "shared/constants/theme.constant";

export default ({ data, onRoleClick, onMoreClick, edit }) => {
  return (
    <BaseCard className="flex flex-row items-center justify-between py-4 mb-2 border-[1px] border-transparent hover:border-primary-main">
      <div className="w-auto h-auto flex flex-col items-start justify-start">
        <div className="text-md mb-1">{data?.user?.fullName}</div>
        <div className="text-sm text-custom-text-sub">
          {businessUserRoleViewMap[data?.role]}
        </div>
      </div>
      <div className="flex flex-row items-center justify-start">
        {edit && (
          <>
            <Icon
              icon="hugeicons:user-edit-01"
              fontSize={24}
              color={tailwindTheme.colors.custom.icon.main}
              onClick={() => onRoleClick(data)}
              className="mr-2"
            />
            <Icon
              icon="hugeicons:more-horizontal-circle-02"
              fontSize={24}
              color={tailwindTheme.colors.custom.icon.main}
              onClick={() => onMoreClick(data)}
            />
          </>
        )}
      </div>
    </BaseCard>
  );
};
