import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BottomSheetListSelection from "shared/components/shared/core/BottomSheetListSelection";
import Toast from "shared/components/shared/core/Toast";
import {
  deleteOrderService,
  patchOrderService,
} from "shared/services/order.service";

export default ({ order, options, ...props }) => {
  const navigate = useNavigate();

  const updateItem = async () => {
    try {
      const { data } = await patchOrderService({
        payload: {
          id: order.id,
          body: {
            status: "inProgress",
          },
          query: {
            variant: "business",
          },
        },
      });
      toast(
        <Toast
          type="success"
          title="Commande"
          message={`Commande ${order.uniqueId} accéptée`}
        />
      );
      options?.accept?.onSuccess(data);
    } catch (error) {
    } finally {
    }
  };

  const deleteItem = async () => {
    try {
      await deleteOrderService(order.id);
      toast(
        <Toast
          type="success"
          title="Commande"
          message={`Commande ${order.uniqueId} supprimé`}
        />
      );
      options?.delete?.onSuccess();
    } catch (error) {}
  };

  const onItemClick = (item) => {
    switch (item.value) {
      case "accept":
        updateItem();
        break;
      case "edit":
        navigate(`/order/${order.id}/edit`);
        break;
      case "delete":
        deleteItem();
        break;
      default:
        break;
    }
  };

  return (
    <BottomSheetListSelection
      list={list}
      onItemClick={onItemClick}
      bottomSheetProps={props}
    />
  );
};

const list = [
  {
    icon: "hugeicons:pencil-edit-02",
    label: "Modifier",
    value: "edit",
  },
  {
    icon: "hugeicons:delete-02",
    label: "Supprimer",
    value: "delete",
  },
];
