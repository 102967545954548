import MenuPrice from "shared/components/order/menu/MenuPrice";
import { Icon } from "@iconify/react";
import BaseImage from "shared/components/shared/base/BaseImage";

export default ({ data, onEditClick, onDeleteClick }) => {
  return (
    <div className="w-36 h-[180px] relative">
      <div className="flex flex-row justify-center w-full absolute top-0 h-28 z-10">
        <div className="w-28 h-28 overflow-hidden relative drop-shadow-2xl rounded-full">
          <BaseImage
            src={data?.image}
            alt={data?.food?.name}
            className="w-28 h-28 rounded-full mb-3"
          />
        </div>
        {data?.price && <MenuPrice data={data?.price} className="right-2" />}
      </div>
      <div className="w-full h-[140px] absolute top-[55px]">
        <div className="w-full h-full flex flex-col justify-start items-center rounded-2xl _glassResponsivePerf px-2 pb-4 pt-16 relative">
          <div
            className="w-auto h-auto p-2 absolute bottom-0 right-0 rounded-br-lg rounded-tl-lg bg-secondary-main"
            onClick={() => onEditClick(data)}
          >
            <Icon icon="hugeicons:pencil-edit-02" fontSize={12} color="black" />
          </div>
          <div
            className="w-auto h-auto p-2 absolute bottom-0 left-0 rounded-bl-lg rounded-tr-lg bg-palette-warning"
            onClick={() => onDeleteClick(data)}
          >
            <Icon icon="hugeicons:delete-02" fontSize={12} color="white" />
          </div>
          <div className="text-sm text-white text-center">
            {data?.food?.name}
          </div>
        </div>
      </div>
    </div>
  );
};
