import { ApplicationPeriodContext } from "context";
import { useEffect, useRef, useState } from "react";
import { getApplicationPeriod } from "shared/utils/app.utils";

export default ({ children }) => {
  const [currentPeriod, setCurrentPeriod] = useState();

  useEffect(() => {
    checkPeriod();
    const interval = setInterval(checkPeriod, [60000]);

    return () => clearInterval(interval);
  }, []);

  const checkPeriod = () => {
    const period = getApplicationPeriod();

    if (currentPeriod !== period) {
      Object.entries(themeMap[period]).forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });

      setCurrentPeriod(period);
    }
  };

  if (!currentPeriod) {
    return <></>;
  }

  return (
    <ApplicationPeriodContext.Provider value={currentPeriod}>
      {children}
    </ApplicationPeriodContext.Provider>
  );
};

const themeMap = {
  morning: {
    "--secondary-main": "var(--red-main)",
    "--secondary-light-0": "var(--red-light-0)",
  },
  afternoon: {
    "--secondary-main": "var(--red-main)",
    "--secondary-light-0": "var(--red-light-0)",
  },
  evening: {
    "--secondary-main": "var(--red-main)",
    "--secondary-light-0": "var(--red-light-0)",
  },
  night: {
    "--secondary-main": "var(--red-main)",
    "--secondary-light-0": "var(--red-light-0)",
  },
};
