import { useState, useEffect } from "react";
import Login from "shared/components/user/Login";
import Register from "shared/components/user/Register";
import classnames from "classnames";
import GoogleLogin from "../shared/core/GoogleLogin";
import { AppTitleSequence } from "public/components/shared/core/Header";

export const Auth = ({ className, ...props }) => {
  const [view, setView] = useState("register");

  return (
    <div
      className={classnames(
        "w-full h-full flex flex-col items-center justify-center",
        className
      )}
    >
      <GoogleLogin />
    </div>
  );
};

export const ManualAuth = ({ className, ...props }) => {
  const [view, setView] = useState("register");

  return (
    <div
      className={classnames(
        "w-full h-full flex flex-col items-center justify-end",
        className
      )}
    >
      {view === "login" && (
        <Login onChangeToRegisterClick={() => setView("register")} {...props} />
      )}
      {view === "register" && (
        <Register onChangeToLoginClick={() => setView("login")} {...props} />
      )}

      <span className="w-full">
        <div className="w-full h-[1px] bg-custom-border-main mb-4"></div>
        <GoogleLogin />
      </span>
    </div>
  );
};

export const MobileAuth = (props) => (
  <div className="w-full h-screen flex flex-col items-center justify-start px-4 py-[200px]">
    <AppTitleSequence />
    <Auth {...props} />
  </div>
);

export const MobileManualAuth = (props) => (
  <div className="w-full h-screen flex flex-col items-center justify-start px-4 py-[200px]">
    <ManualAuth {...props} />
  </div>
);
