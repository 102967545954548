import { RightContentTabletContainer } from "business/components/shared/SideContainer";
import WithResponsive from "../utils/WithResponsive";
import {
  HeaderBackContainer,
  LayoutContainer,
  LayoutContentContainer,
  TitledContent,
} from "./Container";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";

const AboutContent = () => {
  return (
    <>
      <TitledContent title="A Propos">
        <div>Version 1.0</div>
      </TitledContent>
    </>
  );
};

export default WithResponsive(
  () => (
    <HeaderBackContainer>
      <AboutContent />
    </HeaderBackContainer>
  ),
  () => (
    <RightContentTabletContainer breadCrumbItems={[tabsBreadcrumbs.about]}>
      <LayoutContentContainer>
        <AboutContent />
      </LayoutContentContainer>
    </RightContentTabletContainer>
  )
);
