import _ from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BottomSheetListSelection from "shared/components/shared/core/BottomSheetListSelection";
import Toast from "shared/components/shared/core/Toast";
import { orderStatusList } from "shared/constants/order.constant";
import { patchOrderAsync } from "shared/redux/order.slice";
import { patchOrderService } from "shared/services/order.service";

export default ({ order, options, selected, ...props }) => {
  const dispatch = useDispatch();

  const patchOrder = async (item) => {
    try {
      await dispatch(
        patchOrderAsync({
          id: order.id,
          body: {
            status: item.value,
          },
          query: {
            variant: "business",
          },
        })
      ).unwrap();

      toast(
        <Toast
          type="success"
          title="Commande"
          message={`Commande ${order.uniqueId} est en état ${it.label}`}
        />
      );
      options?.onSuccess(item);
    } catch (error) {
    } finally {
    }
  };

  return (
    <BottomSheetListSelection
      list={list}
      onItemClick={patchOrder}
      selected={selected}
      bottomSheetProps={props}
    />
  );
};

const list = orderStatusList;
