import classNames from "classnames";
import useBusiness from "business/hooks/useBusiness";
import { WithResponsiveRightContentContainer } from "../shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import _ from "lodash";
import { LayoutContentContainer } from "shared/components/shared/core/Container";
import { useEffect, useState } from "react";
import { getBusinessTablesService } from "shared/services/business.service";

const BusinessTablesTabMobile = () => {
  const business = useBusiness();

  const [loading, setLoading] = useState(false);
  const [businessTables, setBusinessTables] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setLoading(true);
      const { data } = await getBusinessTablesService({ id: business.id });
      setBusinessTables(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onItemClick = () => {};

  return (
    <LayoutContentContainer>
      {businessTables?.length > 0 ? (
        <div className="w-full h-auto grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5  gap-x-2 gap-y-4">
          {_.map(businessTables, (item, index) => (
            <div
              className={classNames(
                "w-full h-[100px] flex flex-col items-center justify-center rounded-2xl hover:bg-primary-main hover:border-none cursor-pointer",
                {
                  "_glassResponsivePerf": item?.isReserved === false,
                }
              )}
              onClick={() => onItemClick(item)}
              key={index}
            >
              <div className="text-white text-xl">{item?.table}</div>
              {item?.isReserved && (
                <span className="text-md">{"( Réservé )"}</span>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full text-center">Pas de tables</div>
      )}
    </LayoutContentContainer>
  );
};

export default WithResponsiveRightContentContainer(BusinessTablesTabMobile, {
  firstContentMaxWidth: false,
  breadCrumbItems: [tabsBreadcrumbs.tables],
});
