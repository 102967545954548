import { LayoutContentContainer } from "shared/components/shared/core/Container";
import { WithResponsiveRightContentContainer } from "business/components/shared/SideContainer";
import { tabsBreadcrumbs } from "business/constants/tabsBusiness.constant";
import AuthBottomSheetPortal from "./AuthBottomSheetPortal";
import UserProfile from "./UserProfile";

const UserProfileContent = () => {
  return (
    <LayoutContentContainer horizontalPadding={false} headerPadding={false}>
      <UserProfile />
    </LayoutContentContainer>
  );
};

const UserProfileResponsive = WithResponsiveRightContentContainer(
  UserProfileContent,
  {
    breadCrumbItems: [tabsBreadcrumbs.me],
  }
);

export default () => (
  <AuthBottomSheetPortal>
    <UserProfileResponsive />
  </AuthBottomSheetPortal>
);
