import { LayoutContentContainer } from "shared/components/shared/core/Container";
import { ExploreBusinessList } from "./ExploreBusiness";
import { ExploreFilter } from "./Explore";
import { useDispatch } from "react-redux";
import {
  getBusinessListAsync,
  setBusinessInit,
} from "shared/redux/business.slice";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import BaseButton from "shared/components/shared/base/BaseButton";
import useBaseSelector from "shared/hooks/useBaseSelector";
import { paginationLimit } from "shared/constants/shared.constant";
import LoaderPortal from "shared/components/shared/utils/LoaderPortal";
import { RowLoader } from "shared/components/shared/core/Loader";
import { SearchContext } from "context";
import useTabSearch from "shared/hooks/useTabSearch";

export default () => {
  const {
    hasMore,
    loading,
    data: { count, data: businessList },
  } = useBaseSelector("business.list");

  const [page, setPage] = useState(0);

  const [currentSearch, setCurrentSearch] = useContext(SearchContext);
  const [currentFilter, setCurrentFilter] = useState(filter[0]);

  const [searchParams, __] = useSearchParams();

  useTabSearch();

  const navigation = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const newFilterValue = searchParams.get("filter");
    const newSearchValue = searchParams.get("search");

    if (!newFilterValue) {
      navigation(getSearchParamsURL({ value: "all" }, newSearchValue));
      return;
    }

    const newFilter = _.find(filter, (it) => it.value === newFilterValue);

    if (!newFilter) {
      navigation(getSearchParamsURL({ value: "all" }, newSearchValue));
      return;
    }

    setCurrentSearch(newSearchValue ?? "");
    setCurrentFilter(newFilter);

    load({
      reset: true,
      currentSearch: newSearchValue ?? "",
      currentFilter: newFilter,
    });
  }, [searchParams]);

  useEffect(() => {
    load({
      reset: true,
      currentSearch,
    });
  }, [currentSearch]);

  useEffect(() => {
    return () => {
      dispatch(setBusinessInit("list"));
    };
  }, []);

  const load = (params) => {
    const reset = params?.reset === true;

    let query = getQueryCondition(
      params?.currentFilter ?? currentFilter,
      params?.currentSearch ?? currentSearch
    );

    query.limit = paginationLimit;
    query.offset = reset ? 0 : page * paginationLimit;

    dispatch(
      getBusinessListAsync({
        payload: {
          query,
        },
        options: {
          onSuccess: () => setPage((prev) => (reset ? 1 : ++prev)),
          reducerOptions: {
            reset,
          },
        },
      })
    );
  };

  const onFilterChange = (newFilter) => {
    navigation(getSearchParamsURL(newFilter, currentSearch));
  };

  return (
    <LayoutContentContainer
      className="pt-[85px] pb-[170px]"
      horizontalPadding={false}
    >
      <div className="px-4">
        <ExploreFilter
          filter={filter}
          onChange={onFilterChange}
          currentFilter={currentFilter}
        />
        <ExploreBusinessList data={businessList} />
      </div>
      <LoaderPortal
        loader={
          <LayoutContentContainer horizontalPadding={true} headerPadding={true}>
            {_.range(3).map((__, index) => (
              <RowLoader
                className="w-full h-[180px] rounded-3xl mb-2"
                key={index}
              />
            ))}
          </LayoutContentContainer>
        }
        reducerPath="business.list"
        isList
      >
        {hasMore && (
          <div className="w-full px-8">
            <BaseButton.Contained
              primary
              icon="hugeicons:add-02"
              onClick={() => load()}
              className="w-full flex flex-row items-center justify-center rounded-full py-2"
            >
              Voir plus
            </BaseButton.Contained>
          </div>
        )}
      </LoaderPortal>
    </LayoutContentContainer>
  );
};


const filter = [
  {
    label: "Tout",
    value: "all",
  },
  {
    label: "Restaurants",
    value: "restaurants",
    query: "restaurant",
  },
  {
    label: "Café",
    value: "coffeeShops",
    query: "coffeeShop",
  },
  {
    label: "Bar",
    value: "bars",
    query: "bar",
  },
  {
    label: "Restaurant/Café",
    value: "mixed",
    query: "mixed",
  },
];

const getQueryCondition = (filter, search) => {
  let query =
    filter.value === "all"
      ? {}
      : {
          filter: `type||$eq||${filter.query}`,
        };

  if (!_.isEmpty(search)) {
    const searchCondition = `name||$contL||${search}`;
    query.filter = query?.filter
      ? [query.filter, searchCondition]
      : searchCondition;
  }

  return query;
};

const getSearchParamsURL = (filter, search) => {
  let queryUrl = `/explore?filter=${filter.value}`;
  if (!_.isEmpty(search)) {
    queryUrl = `${queryUrl}&search=${search}`;
  }

  return queryUrl;
};
