import _ from "lodash";
import classnames from "classnames";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import BaseSkeleton from "../base/BaseSkeleton";

export default ({
  component: Component,
  data = [],
  showIndicators = false,
  carouselClassName,
  ...props
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  return (
    <div className="w-full h-auto rounded-lg mb-4">
      <Carousel
        infiniteLoop
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        onChange={setCarouselIndex}
        className={classnames(
          "w-full h-[180px] lg:h-[400px] rounded-lg mb-4",
          carouselClassName
        )}
        autoPlay
        interval={3000}
        centerMode
        showThumbs={false}
        {...props}
      >
        {_.map(data, (item, index) => (
          <div key={index} className="mr-2">
            <Component data={item} />
          </div>
        ))}
      </Carousel>
      {showIndicators && (
        <div className="w-full h-auto flex flex-row items-center justify-center">
          {_.range(data.length).map((it, index) => (
            <div
              key={index}
              className={classnames("w-2 h-2 rounded-full mr-2", {
                "bg-custom-carousel-indicators-selected":
                  index === carouselIndex,
                "bg-custom-carousel-indicators-default":
                  index !== carouselIndex,
              })}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export const CarouselSkeleton = () => {
  return <BaseSkeleton className="w-full h-[180px] mb-4" />;
};
