import { useEffect, useState } from "react";
import BarChart from "../shared/charts/BarChart";
import { getBusinessSalesPeriodicService } from "shared/services/analytics.service";
import { HorizontalScrollWithChildren } from "shared/components/shared/core/HorizontalScroll";

export const BusinessAnalyticsChart = () => {
  const [xAccessDataKey, setXAccessDataKey] = useState("month");
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      setLoading(true);
      const { data: analytics } = await getBusinessSalesPeriodicService({
        period: "month",
      });
      setChartData(buildChartData(analytics));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <HorizontalScrollWithChildren>
      <BarChart
        chartData={chartData}
        bars={bars}
        xAccessDataKey={xAccessDataKey}
      />
    </HorizontalScrollWithChildren>
  );
};

const chartData = [
  { month: "January", sales: 186, mobile: 80 },
  { month: "February", sales: 305, mobile: 200 },
  { month: "March", sales: 237, mobile: 120 },
  { month: "April", sales: 73, mobile: 190 },
  { month: "May", sales: 209, mobile: 130 },
  { month: "June", sales: 214, mobile: 140 },
  { month: "July", sales: 186, mobile: 80 },
  { month: "August", sales: 305, mobile: 200 },
  { month: "September", sales: 237, mobile: 120 },
  { month: "October", sales: 73, mobile: 190 },
  { month: "November", sales: 209, mobile: 130 },
  { month: "December", sales: 214, mobile: 140 },
];

const bars = [
  {
    dataKey: "sales",
    fill: "blue",
  },
];

const buildChartData = (analytics) => {
  return [
    { month: "July", sales: 186, mobile: 80 },
    { month: "August", sales: 305, mobile: 200 },
    { month: "September", sales: 237, mobile: 120 },
    { month: "October", sales: 73, mobile: 190 },
    { month: "November", sales: 209, mobile: 130 },
    { month: "December", sales: 214, mobile: 140 },
  ];
};
