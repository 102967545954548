import _ from "lodash";
import {
  mergeOrderNodeData,
  setOrderNodeFieldData,
} from "shared/redux/order.slice";

export const createNewCartItem = (item) => {
  return {
    ...item,
    units: 1,
  };
};

export const createDuplicatedExpandCartItem = (item) => {
  return _.chain(item)
    .omit(["food.excludeIngredients", "food.addSupplements"])
    .set("units", 1)
    .value();
};

export const handleOnNewCartItem = (node, list, item, dispatch) => {
  let update = _.cloneDeep(list);

  update.push(createNewCartItem(item));

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleOnDeleteCartItem = (node, list, item, dispatch) => {
  let update = _.cloneDeep(list);

  const index = _.findIndex(list, (it) => it.id === item.id);

  if (index > -1) {
    update.splice(index, 1);
  }

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleOnCartItemAdd = (node, list, index, dispatch) => {
  let update = _.cloneDeep(list);

  if (index > -1) {
    update[index].units++;
  }

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleOnCartItemMinus = (node, list, index, dispatch) => {
  let update = _.cloneDeep(list);

  if (update[index].units <= 1) {
    update.splice(index, 1);
  } else {
    update[index].units--;
  }

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleOnCartItemExpand = (node, list, index, dispatch) => {
  let update = _.cloneDeep(list);

  if (index > -1) {
    const item = update[index];
    if (item.units > 1) {
      update[index].units--;
      update.splice(index + 1, 0, createDuplicatedExpandCartItem(item));
    }
  }

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleOnCartItemDelete = (node, list, index, dispatch) => {
  let update = _.cloneDeep(list);

  update.splice(index, 1);

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleOnSelectionItemAdd = (node, list, item, dispatch, reset) => {
  if (reset) {
    return dispatch(
      mergeOrderNodeData({
        node,
        data: {
          business: reset?.business,
          selections: [createNewCartItem(item)],
        },
      })
    );
  }

  let update = _.cloneDeep(list);

  const existsIndex = _.findLastIndex(list, { id: item?.id });

  if (existsIndex > -1) {
    update[existsIndex].units++;
  } else {
    update.push(createNewCartItem(item));
  }
  // if (existsIndex > -1) {
  //   update.splice(existsIndex + 1, 0, createNewCartItem(item));
  // } else {
  //   update.push(createNewCartItem(item));
  // }

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleOnSelectionItemMinus = (node, list, item, dispatch) => {
  let update = _.cloneDeep(list);

  const index = _.findLastIndex(list, { id: item?.id });

  if (update[index].units > 1) {
    update[index].units--;
  } else {
    update.splice(index, 1);
  }

  let data = { selections: update };

  dispatch(
    mergeOrderNodeData({
      node,
      data,
    })
  );
};

export const handleUpdateCartSelectionExtras = (
  node,
  field,
  list,
  index,
  selected,
  dispatch
) => {
  let update = _.cloneDeep(list);

  if (index > -1) {
    _.set(update, `${index}.${field}`, selected);
  }

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};

export const handleUpdateCartExtras = (node, field, selected, dispatch) => {
  let update = {};
  update[field] = selected;

  dispatch(
    mergeOrderNodeData({
      node,
      data: { selections: update },
    })
  );
};
