import {
  LayoutContainer,
  LayoutContentContainer,
} from "shared/components/shared/core/Container";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBusinessHomeAsync } from "shared/redux/business.slice";
import {
  BusinessBarsSlides,
  BusinessCoffeeShopsSlides,
  BusinessRestaurantSlides,
} from "public/components/business/BusinessSlides";
import HomeSponsoring from "./HomeSponsoring";
import HomeCategories from "./HomeCategories";
import WithResponsive from "shared/components/shared/utils/WithResponsive";

const HomeMobile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessHomeAsync());
  }, [dispatch]);

  return (
    <LayoutContainer horizontalPadding={false}>
      <HomeSponsoring />
      <BusinessRestaurantSlides />
      <BusinessCoffeeShopsSlides />
      <BusinessBarsSlides />
    </LayoutContainer>
  );
};

const HomeTablet = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessHomeAsync());
  }, [dispatch]);

  return (
    <LayoutContentContainer horizontalPadding={false}>
      <BusinessRestaurantSlides />
      <BusinessCoffeeShopsSlides />
      <BusinessBarsSlides />
    </LayoutContentContainer>
  );
};

export default WithResponsive(HomeMobile, HomeTablet);
