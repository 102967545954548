import BottomSheetListSelection from "shared/components/shared/core/BottomSheetListSelection";
import { businessUserRolesOptions } from "shared/constants/business.constant";

export default ({ onItemClick, options, ...props }) => {
  return (
    <BottomSheetListSelection
      list={businessUserRolesOptions}
      onItemClick={onItemClick}
      bottomSheetProps={props}
    />
  );
};
