import OrderExludeIngredientsText from "./OrderExludeIngredientsText";
import OrderFoodImage from "./OrderFoodImage";
import OrderSupplementsText from "./OrderSupplementsText";

export default ({ data }) => {
  return (
    <div className="w-full min-h-[80px] flex flex-row items-start justify-start mb-6">
      <OrderFoodImage
        data={{
          image: data?.businessFood?.image,
          name: data?.businessFood?.food?.name,
          units: data?.units,
        }}
      />
      <div className="flex flex-col items-start justify-around h-auto flex-1 ml-4">
        <div className="text-lg mb-3">{data?.businessFood?.food?.name}</div>
        <OrderExludeIngredientsText
          ingredients={data?.excludeIngredients}
        />
        <OrderSupplementsText supplements={data?.addSupplements} />
      </div>
    </div>
  );
};
